<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
    <div class="header accent h-60 p-24" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="h1">{{ titulo }}</div>
        </div>
    </div>

    <div class="content p-24">
        <div class="h2 titleListado">{{ subtitulo }}</div>

        <div class="filter-header">
            <div class="filter-header-input">
                <input type="text" placeholder="Buscar..." (keyup)="updateFilter($event)"/>
            </div>

            <div class="filter-header-items" *ngIf="isAdministrative">
                <button type="submit" color="accent" class="mat-raised-button mat-button-base mat-accent btnJurispol mr-20"
                    (click)="gotoAdd()">
                    <span class="mat-button-wrapper"> Añadir área </span>
                </button>

                <button class="mat-raised-button mat-button-base mat-accent btnJurispol" (click)="changeEnabled()">
                    <span *ngIf="areEnabled">Deshabilitados</span>
                    <span *ngIf="! areEnabled">Habilitados</span>
                </button>
            </div>
        </div>

        <ngx-datatable class="material"
            [rows]="rows"
            [loadingIndicator]="loadingIndicator"
            [columnMode]="'force'"
            [headerHeight]="48"
            [footerHeight]="56"
            [rowHeight]="'auto'"
            [reorderable]="true"
            [limit]="10"
        >
            <ngx-datatable-column name="Área" prop="nombre"></ngx-datatable-column>
            <ngx-datatable-column name="Tipo" prop="tipo"></ngx-datatable-column>
            <ngx-datatable-column name="Número de respuestas" prop="n_respuestas"
            ></ngx-datatable-column>
            <ngx-datatable-column name="Principal" prop="principal">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <ng-container *ngIf="value == 1; else noComun">
                        <mat-icon color="accent">check_circle_outline</mat-icon>
                    </ng-container>
                    <ng-template #noComun>
                        <mat-icon color="warn">highlight_off</mat-icon>
                    </ng-template>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="isAdministrative"
                                  name="Acciones"
                                  [canAutoResize]="false"
                                  [sortable]="false"
                                  prop="id">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                    <div class="action">
                        <div class="action__container">
                            <ul class="action__list">
                                <li class="action__item">
                                    <span class="action_icon blue-A100-fg" (click)="update(value)">
                                        <mat-icon>create</mat-icon>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
