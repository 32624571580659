
export const Dictionary = {
    localhost: {
        title: 'Jurispol',
        subtitle: 'Jurispol',
        titleLogin: '',
        iconoLogin: 'assets/images/jurispol/logo_login_2.png',
        iconoLoginMobile: 'assets/images/jurispol/logo_login_mobile.png',
        recuperaPasswLinkLogin: 'https://tienda.jurispol.com/online/customer/account/forgotpassword/',
        recuperaPasswTextLogin: '¿Has olvidado la contraseña?',
        iconoSidebar: 'assets/images/jurispol/logo_login_2.png',
        titleSidebar: 'JURISPOL',
        claseSidebar: 'logo',
        titleTest: 'Test',
        titleTestProgramados: 'Realizar test programados por Jurispol',
        titleTestAutotest: 'Genera un test rápido de preguntas',
        titleTestRealizados: 'Test temario realizados',
        titleExamen: 'Examen',
        titleExamenProgramados: 'Realizar exámenes programados por Jurispol',
        titleExamenAutotest: 'Genera un examen rápido de preguntas',
        titleExamenRealizados: 'Exámenes realizados',
        titleSimulacro: 'Simulacro',
        titleSimulacroProgramados: 'Realizar simulacros programados por Jurispol',
        titleSimulacroAutotest: 'Genera un simulacro rápido de preguntas',
        titleSimulacroRealizados: 'Simulacros realizados',
        titleAsignarRecursoCurso : 'Asignar recurso a curso',
        titleAsignarRecursoCursoAsignados : 'Recursos asignados al curso',
        titleAsignarRecursoCursoBiblioteca : 'Biblioteca de recursos',
        titleAsignarTestCurso : 'Asignar test a curso',
        titleAsignarTestCursoAsignados : 'Test asignados al curso',
        titleAsignarTestCursoBiblioteca : 'Biblioteca de test',
        titleAsignarTestConRecursoCurso : 'Asignar test con recurso a curso',
        titleAsignarTestConRecursoCursoAsignados : 'Test con recurso asignados al curso',
        titleAsignarTestConRecursoCursoBiblioteca : 'Biblioteca de test con recurso',
        btnCerrarRevisarTest: '../../../normal',
    },
    jurispol: {
        title: 'Jurispol',
        subtitle: 'Jurispol',
        titleLogin: '',
        iconoLogin: 'assets/images/jurispol/logo_login_2.png',
        iconoLoginMobile: 'assets/images/jurispol/logo_login_mobile.png',
        recuperaPasswLinkLogin: 'https://tienda.jurispol.com/online/customer/account/forgotpassword/',
        recuperaPasswTextLogin: '¿Has olvidado la contraseña?',
        iconoSidebar: 'assets/images/jurispol/logo_login_2.png',
        titleSidebar: 'JURISPOL',
        claseSidebar: 'logo',
        titleTest: 'Test',
        titleTestProgramados: 'Realizar test programados por Jurispol',
        titleTestAutotest: 'Genera un test rápido de preguntas',
        titleTestRealizados: 'Test temario realizados',
        titleExamen: 'Examen',
        titleExamenProgramados: 'Realizar exámenes programados por Jurispol',
        titleExamenAutotest: 'Genera un examen rápido de preguntas',
        titleExamenRealizados: 'Exámenes realizados',
        titleSimulacro: 'Simulacro',
        titleSimulacroProgramados: 'Realizar simulacros programados por Jurispol',
        titleSimulacroAutotest: 'Genera un simulacro rápido de preguntas',
        titleSimulacroRealizados: 'Simulacros realizados',
        titleAsignarRecursoCurso : 'Asignar recurso a curso',
        titleAsignarRecursoCursoAsignados : 'Recursos asignados al curso',
        titleAsignarRecursoCursoBiblioteca : 'Biblioteca de recursos',
        titleAsignarTestCurso : 'Asignar test a curso',
        titleAsignarTestCursoAsignados : 'Test asignados al curso',
        titleAsignarTestCursoBiblioteca : 'Biblioteca de test',
        titleAsignarTestConRecursoCurso : 'Asignar test con recurso a curso',
        titleAsignarTestConRecursoCursoAsignados : 'Test con recurso asignados al curso',
        titleAsignarTestConRecursoCursoBiblioteca : 'Biblioteca de test con recurso',
        btnCerrarRevisarTest: '../../../normal',
    },
    jurispol_pro: {
        title: 'Jurispol PRO',
        subtitle: 'Jurispol PRO',
        titleLogin: 'LOGIN JURISPOL PRO',
        iconoLogin: 'assets/images/jupol/jurispolProLogoLogin_2.png',
        iconoLoginMobile: 'assets/images/jupol/jurispolProLogoLogin_2.png',
        recuperaPasswLinkLogin: 'https://tienda.jurispol.com/online/customer/account/forgotpassword/',
        recuperaPasswTextLogin: 'Recuperar contraseña',
        iconoSidebar: 'assets/images/jupol/jurispolProLogoLogin_2.png',
        titleSidebar: '',
        claseSidebar: 'logoJupol',
        titleTest: 'Test',
        titleTestProgramados: 'Realizar test programados por Jurispol Pro',
        titleTestAutotest: 'Genera un test rápido de preguntas',
        titleTestRealizados: 'Test temario realizados',
        titleExamen: 'Examen',
        titleExamenProgramados: 'Realizar test programados por Jurispol Pro',
        titleExamenAutotest: 'Genera un test rápido de preguntas',
        titleExamenRealizados: 'Test realizados',
        titleSimulacro: 'Simulacro',
        titleSimulacroProgramados: 'Realizar simulacros programados por Jurispol Pro',
        titleSimulacroAutotest: 'Genera un simulacro rápido de preguntas',
        titleSimulacroRealizados: 'Simulacros realizados',
        titleAsignarRecursoCurso : 'Asignar recurso a curso',
        titleAsignarRecursoCursoAsignados : 'Recursos asignados al curso',
        titleAsignarRecursoCursoBiblioteca : 'Biblioteca de recursos',
        titleAsignarTestCurso : 'Asignar test a curso',
        titleAsignarTestCursoAsignados : 'Test asignados al curso',
        titleAsignarTestCursoBiblioteca : 'Biblioteca de test',
        titleAsignarTestConRecursoCurso : 'Asignar test con recurso a curso',
        titleAsignarTestConRecursoCursoAsignados : 'Test con recurso asignados al curso',
        titleAsignarTestConRecursoCursoBiblioteca : 'Biblioteca de test con recurso',
        btnCerrarRevisarTest: '../../../examen',
    },
};

// export const dictionary = Dictionary[global.domain];
