import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "numOfUnits",
})
export class NumOfUnitsPipe implements PipeTransform {
    transform(value: any): number {
        console.log(value)
        if (value) {
            const ext = value.split("|");
            return ext.length;
        }
        return undefined;

    }
}
