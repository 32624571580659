import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from '../../../../../bases-services/base.service';
import Swal from 'sweetalert2';
import { ErrorsHandler } from '../../../../../helpers/ErrorsHandler';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import {AuthService} from '../../../../auth/auth.service';

@Component({
    selector: 'app-list-area',
    templateUrl: './list_area.component.html',
    styleUrls: ['../../../../scss/tables.scss'],
})
export class ListAreaComponent implements OnInit, OnDestroy {
    titulo = 'Listado de áreas';
    subtitulo = 'Consulta el listado';
    loadingIndicator: boolean;
    isAdministrative: boolean;
    rows: any[];
    temp: any[];
    columnsWithSearch: string[];
    @ViewChild(DatatableComponent) table: DatatableComponent;
    errors: ErrorsHandler;
    private _unsubscribeAll: Subject<any>;
    areEnabled: boolean;

    constructor(
        private base: BaseService,
        private router: Router,
        private route: ActivatedRoute,
        private auth: AuthService
    ) {
        this.loadingIndicator = true;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        const userData = this.auth.getUserData();
        const roles = userData.id_roles;

        this.isAdministrative = roles.some((elem) => ['RSS', 'RSM', 'RSP'].includes(elem));

        this.areEnabled = true;
        this.backendCall();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    changeEnabled(): void {
        this.areEnabled = ! this.areEnabled;
        this.backendCall();
    }

    gotoAdd(): void {
        this.router.navigate(['add'], { relativeTo: this.route });
    }

    update(id: string): void {
        if (id) {
            this.router.navigate(['edit/' + id], { relativeTo: this.route });
        }
    }

    updateFilter(event, flag?: boolean): void {
        let filter = '';
        if (flag) {
            filter = event.toLowerCase();
        } else {
            filter = event.target.value.toLowerCase();
        }

        this.rows = this.temp.filter((item) => {
            for (let i = 0; i < this.columnsWithSearch.length; i++) {
                const colValue = item[this.columnsWithSearch[i]];
                if (
                    !filter ||
                    (!!colValue &&
                        colValue.toString().toLowerCase().indexOf(filter) !==
                            -1)
                ) {
                    return true;
                }
            }
        });

        this.table.offset = 0;
    }

    private backendCall(): void {
        const habilitado = this.areEnabled ? '1' : '0';
        this.base.list(habilitado, 'area/list').subscribe((item) => {
            this.errors = new ErrorsHandler(item.code, item.message, 'get', 'temas');
            if (!this.errors.setError()) {
                this.rows = item.result;
                this.temp = [...this.rows];
                if (item.result.length > 0) {
                    this.columnsWithSearch = Object.keys(this.rows[0]);
                }
                this.loadingIndicator = false;
            } else {
                Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
            }
        });
    }
}
