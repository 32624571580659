import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: "totalHorasArea",
})
export class TotalHorasAreaPipe implements PipeTransform {
    constructor() {}

    transform(value: any, path?: string): unknown {
       
        let horas = 0;
        if (value) {
            value.horario.forEach(element => {
                horas = parseInt(element.horas) + horas;
            });
        }
     return horas;
    }
}
