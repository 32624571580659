import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { Global } from '../../../../global';
import { BaseService } from '../../../../bases-services/base.service';

@Component({
    selector: 'app-multi-checkbox',
    templateUrl: './multi-checkbox.component.html'
})
export class MultiCheckboxComponent implements OnInit {
    valoresSeleccionados: any;
    global = Global;
    isJupol: boolean;
    @Input() array: any;
    @Input() tipo: string;
    @Input() hasConvocatoria: boolean;
    @Output() valuesEmmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() finishedCall: EventEmitter<any> = new EventEmitter<any>();

    @ViewChildren('multicheckbox') multicheckbox: QueryList<MatCheckbox>;

    constructor (private base: BaseService) {
        this.tipo = null;
        this.array = [];
        this.valoresSeleccionados = [];
        this.hasConvocatoria = false;
        this.isJupol = this.global.domain === 'jurispol_pro';
    }

    ngOnInit (): void {
        if (this.tipo) {
            switch (this.tipo) {
            case 'testOpcion':
                this.array = [
                    { id: 'test_normal', name: 'Test programables' }
                ];
                break;
            case 'clase':
                this.array = [
                    { id: 'clase', name: 'Clases' }
                ];
                break;
            case 'materialApoyo':
                this.array = [
                    { id: 'examen', name: 'Documento 2ªvuelta' },
                    { id: 'documento', name: 'Documento' },
                    { id: 'informe', name: 'Recurso informe' },
                    { id: 'embed', name: 'Vídeo' },
                    { id: 'clase_grabada', name: 'Vídeo 2ªvuelta' },
                    { id: 'mp4', name: 'Video Mp4' },
                    { id: 'podcast', name: 'Podcast' }
                ];
                break;
            case 'menu':
                this.base.get('menu/options' + (this.isJupol ? '/jupol' : '')).subscribe(menu => {
                    this.array = menu;
                    const subs = this.multicheckbox.changes.subscribe(mx =>{
                        this.finishedCall.emit(this.array);
                        subs.unsubscribe();
                    });

                });
                break;
            case 'checkDiploma':
                this.array = [
                    { id: 'diploma', name: 'Diploma' }
                ];
                break;
            }
        }
    }

    valoresIniciales (valores: any[], disabled: boolean = false, blockdisabled:boolean = false): void {
        this.valoresSeleccionados = [];
        this.array.forEach((elem, index) => {
                this.multicheckbox.toArray()[index].checked = false;

                valores.forEach(valor => {
                    if (valor === elem.id) {
                        this.valoresSeleccionados.push(valor);
                        this.multicheckbox.toArray()[index].checked = true;
                    }
                });
                if (blockdisabled) {
                    this.multicheckbox.toArray()[index].disabled = disabled;
                }
        });
    }

    setCheckBoxValuesByKey (isChecked: boolean, checkedValue: string): void {
        if (isChecked) {
            this.valoresSeleccionados.push(checkedValue);
        } else {
            this.valoresSeleccionados = this.valoresSeleccionados.filter(v => v !== checkedValue);
        }

        this.valuesEmmit.emit([this.valoresSeleccionados, this.tipo]);
    }
}
