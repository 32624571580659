<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="navbar-toggle-button" *ngIf="!getRightNavbar() && !getHiddenNavbar()" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!getHiddenNavbar() && !getRightNavbar()" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/jurispol/logo.svg" />
        </div>
      </div>
      <div *ngIf="isUser" class="d-block d-sm-none w-150px ellipsis">
        <span class="mr-12 fs-4 text-black-65">{{getTitleScope()}}</span>
      </div>

    </div>

    <div *ngIf="isUser" class="d-none d-sm-block">
      <span class="pe-5 fs-3 text-black-65">{{getTitleScope()}}</span>
    </div>

    <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

      <button mat-button [matMenuTriggerFor]="beforeMenu" class="btnCampanaNotificaciones">
        <mat-icon *ngIf="notifications == 0">notifications_none</mat-icon>
        <span *ngIf="notifications > 0">
          <mat-icon>notifications_none</mat-icon>
          <span class="alertCampanaNotificaciones">{{ notifications }}</span>
        </span>
      </button>

      <mat-menu #beforeMenu="matMenu" xPosition="before" [classList]="'notification-panel'">
        <div class="container w-lg-400px w-100">
          <div class="row">
            <div class="col-12 p-0">
              <div class="bg-notifications pt-20 pb-20">
                <h2 class="fs-2 ps-3 pe-3 fw-bold">Últimos 5 avisos</h2>
              </div>
              <hr>
              <ng-container *ngFor="let notification of notificationsItems; let i = index">
                <div class="pointer ps-3 pe-3 pointer pt-3 pb-3" [class.newNotification]="notification.is_new == 1" (click)="goToAnnouncements()">
                  <div class="fs-5 fst-italic">{{notification.category}}</div>
                  <div class="fs-4 fw-bold ellipsis"> {{notification.title}}</div>
                  <div class="fs-5 text-black-50 ellipsis" >{{stripHtml(notification.text)}}</div>
                </div>
                <hr>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="offset-lg-2 col-lg-8 col-12 offset-0">
              <button mat-raised-button color="accent" class="mat-raised-button btnJurispol text-transform-none submit-button mt-20 mb-20 w-100
                                    primary-bg-color fs-3 h-50px pb-7px" (click)="goToAnnouncements()">
                <span class="fs-3">Leer todos los avisos</span>
              </button>
            </div>
          </div>
        </div>


      </mat-menu>

      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="data">

          <span class="username fs-4 mr-12 text-transform-none fw-lighter" fxHide fxShow.gt-sm>{{ data.nombre }}</span>
          <ng-container *ngIf="getDataImg()">
            <img class="avatar mr-0 mr-sm-16" [src]="getDataImg() | urlPath: 'usuarios/'" />
          </ng-container>
        </div>
      </button>

      <div class="toolbar-separator"></div>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

        <button mat-menu-item class="" (click)="auth.logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Cerrar sesión</span>
        </button>
      </mat-menu>

      <!--     <div class="toolbar-separator"></div>

         <fuse-search-bar></fuse-search-bar> -->
    </div>
  </div>
</mat-toolbar>
