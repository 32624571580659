import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dragdrop",
})
export class dragdropPipe implements PipeTransform {
  transform(value: any): string {
 
    if (typeof  value === 'string') {
      const ext = value.split(".");
      const document = ["pdf", "docx", "plain"];
      const video = ["mp4", "mov", "wav", "avi", "mpeg"];
      const audio = ["mp3"];
      const image = ["jpg", "tiff", "png", "jpeg", "gif"];
      switch (true) {
        case document.includes(ext[ext.length - 1]):
          return "assets/images/noPics/ARCHIVO.png";
        case video.includes(ext[ext.length - 1]):
          return "assets/images/noPics/VIDEO.png";
        case audio.includes(ext[ext.length - 1]):
          return "assets/images/noPics/SONIDO.png";
        case image.includes(ext[ext.length - 1]):
          return "assets/images/noPics/IMAGEN.png";
        default:
          return "assets/images/noPics/ARCHIVO.png";
      }
    }
  }
}
