import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'barAcertadas'
})
export class BarArcertadasPipe implements PipeTransform {

    transform(value: any, rows: any): number {
        let counter = 0;
        if (value.type) {
            switch (value.type) {
                case 1:
                    rows.forEach(item => {
                        if (item.correcta === item.marcada && !item.riesgo) {
                            counter++;
                        }
                    });
                    break;

                case 2:
                    rows.forEach(item => {
                        if (item.correcta === item.marcada && item.riesgo) {
                            counter++;
                        }
                    });
                    break;

                case 3:
                    rows.forEach(item => {
                        if (item.correcta !== item.marcada && item.riesgo) {
                            counter++;
                        }
                    });
                    break;

                case 4:
                    rows.forEach(item => {
                        if (item.correcta !== item.marcada && !item.riesgo) {
                            counter++;
                        }
                    });
                    break;
                case 5:
                    rows.forEach(item => {
                        if (!item.marcada) {
                            counter++;
                        }
                    });
                    break;

                default:
                    return 0;
            }
        }
        return counter;
    }
}
