import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AddTemaComponent} from './add/add_tema.component';
import {ListTemaComponent} from './list/list_tema.component';
import {RoleGuard} from '../../../auth/guards/role.guard';

const routes: Routes = [
    {
        path: '',
        component: ListTemaComponent
    },
    {
        path: 'add',
        canActivate: [RoleGuard],
        canLoad: [RoleGuard],
        data: { allowedRoles: ['RSP', 'RSM', 'RSS'] },
        component: AddTemaComponent
    },
    {
        path: 'edit/:id',
        canActivate: [RoleGuard],
        canLoad: [RoleGuard],
        data: { allowedRoles: ['RSP', 'RSM', 'RSS'] },
        component: AddTemaComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TemasRoutingModule {
}
