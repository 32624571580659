import { NumOfUnitsPipe } from '../numOfUnits.pipe';
import { TotalHorasAreaPipe } from '../totalHorasArea.pipe';
import { MostrarRenovarPipe } from '../mostrarRenovar.pipe';
import { ABCPipe } from '../ABC.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlPathPipe } from '../url-path.pipe';
import { TimeFormatPipe } from '../timeFormat.pipe';
import { DateIndexPipe } from '../dateIndex.pipe';
import { InternationalDatePipe } from '../internationalDate.pipe';
import { SanitizeUrlPipe } from '../sanitizeUrl.pipe';
import { TemaPadrePipe } from '../tema-padre.pipe';
import { FileSelectorPipe } from '../fileSelector.pipe';
import { CheckedResponsePipe } from '../checkedResponse.pipe';
import { IfCheckPipe } from '../ifCheck.pipe';
import { CursoActivoPipe } from '../cursoActivo.pipe';
import { NotasPipe } from '../notas.pipe';
import { BarArcertadasPipe } from '../barArcertadas.pipe';
import { SegundosMinutoPipe } from '../segundosMinutos.pipe';
import { ToOrSendPipe } from '../toOrSend.pipe';
import { MarcarRolePipe } from '../marcarRole.pipe';
import { MarcarRespuestaCorrectaPipe } from '../marcarRespuestaCorrecta.pipe';
import { MarcarRespuestaMarcadaPipe } from '../marcarRespuestaMarcada.pipe';
import { RespuestaCorrectaPipe } from '../respuestaMarcada.pipe';
import { DificultadPipe } from '../dificultad.pipe';
import { PipeSeparatorPipe } from '../pipe-separator.pipe';
import { QuitarHtmlPipe } from '../quitarHtml.pipe';
import { PonerHtmlPipe } from '../ponerHtml.pipe';
import { FilenameByUrlPipe } from '../FilenameByUrl.pipe';
import { TypeFileActivity } from '../typeFileActivity.pipe.component';
import { SafeHtmlPipe } from '../safeHtml.pipe';
import { TimeToISOPipe } from '../timeISO.pipe';

@NgModule({
    declarations: [
        UrlPathPipe,
        TimeFormatPipe,
        DateIndexPipe,
        InternationalDatePipe,
        SanitizeUrlPipe,
        TemaPadrePipe,
        FileSelectorPipe,
        CheckedResponsePipe,
        IfCheckPipe,
        CursoActivoPipe,
        NotasPipe,
        BarArcertadasPipe,
        SegundosMinutoPipe,
        ToOrSendPipe,
        MarcarRolePipe,
        MarcarRespuestaCorrectaPipe,
        MarcarRespuestaMarcadaPipe,
        RespuestaCorrectaPipe,
        DificultadPipe,
        PipeSeparatorPipe,
        QuitarHtmlPipe,
        PonerHtmlPipe,
        FilenameByUrlPipe,
        ABCPipe,
        MostrarRenovarPipe,
        TypeFileActivity,
        TotalHorasAreaPipe,
        SafeHtmlPipe,
        TimeToISOPipe,
        NumOfUnitsPipe
    ],
    imports: [CommonModule],
    exports: [
        UrlPathPipe,
        TimeFormatPipe,
        DateIndexPipe,
        InternationalDatePipe,
        SanitizeUrlPipe,
        TemaPadrePipe,
        FileSelectorPipe,
        CheckedResponsePipe,
        IfCheckPipe,
        CursoActivoPipe,
        NotasPipe,
        BarArcertadasPipe,
        SegundosMinutoPipe,
        ToOrSendPipe,
        MarcarRolePipe,
        MarcarRespuestaCorrectaPipe,
        MarcarRespuestaMarcadaPipe,
        RespuestaCorrectaPipe,
        DificultadPipe,
        PipeSeparatorPipe,
        QuitarHtmlPipe,
        PonerHtmlPipe,
        FilenameByUrlPipe,
        ABCPipe,
        MostrarRenovarPipe,
        TypeFileActivity,
        TotalHorasAreaPipe,
        SafeHtmlPipe,
        TimeToISOPipe,
        NumOfUnitsPipe
    ]
})
export class PipesModule { }
