import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {FuseSharedModule} from '@fuse/shared.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { HtmlComponentsModule } from 'app/main/components/HtmlComponents/html-components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginCursosAdminRoutingModule } from './login-cursos-admin.routing.module';
import { LoginCursosAdminListComponent } from './list/login-cursos-admin-list.component';
import { loginCursosAdminAddComponent } from './add/login-cursos-admin-add.component';
import {PipesModule} from "../../../../pipes/pipes/pipes.module";
import {DragDropModule} from "../../../components/dragdrop/dragdrop.module";
import {MatTableModule} from "@angular/material/table";
import {MatExpansionModule} from "@angular/material/expansion";

@NgModule({
  declarations: [
    LoginCursosAdminListComponent,
    loginCursosAdminAddComponent
  ],
  imports: [
    CommonModule,
    LoginCursosAdminRoutingModule,
    NgxDatatableModule,
    HtmlComponentsModule,
    MatIconModule,
    PipesModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FuseSharedModule,
    DragDropModule,
    MatTableModule,
    MatExpansionModule
  ]
})
export class LoginCursosAdminModule { }
