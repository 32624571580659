import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-select-multi',
    templateUrl: './multi-select.component.html'

})
export class MultiSelectComponent {
    @Input() array: any = [];
    @Input() error: string;
    @Input() name: string;
    @Input() value: string;
    @Input() header: string;
    @Output() setData: EventEmitter<any> = new EventEmitter<any>();

    constructor () {

    }

    setDatas (event): void {
        this.setData.emit(event);
    }
}
