import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../../../../bases-services/base.service';
import { ErrorsHandler } from '../../../../../helpers/ErrorsHandler';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MultiFileUploadService } from '../../../../../bases-services/multi-file-upload.service';
import Swal from 'sweetalert2';
import { Global } from '../../../../../global';

@Component({
    selector: 'login-cursos-admin-add',
    templateUrl: './login-cursos-admin-add.component.html',
    styleUrls: ['./login-cursos-admin-add.scss']
})
export class loginCursosAdminAddComponent implements OnInit, OnDestroy {
  titulo = 'Añadir selección de curso';
  subtitulo = 'Imagen de selección de curso';
  form: FormGroup;
  filesFromServer;
  filesToServer;
  publicidadId;
  errors;
  global = Global;

  // eslint-disable-next-line no-useless-constructor
  constructor (
      private base: BaseService,
      private router: Router,
      private _formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private multiFileUploadService: MultiFileUploadService,
      public datepipe: DatePipe
  ) {

  }

  ngOnInit (): void {
      this.form = this._formBuilder.group({
          site: [],
          image: [],
          url: ['', Validators.required],
          start_date: ['', Validators.required],
          start_time: ['', Validators.required],
          end_date: ['', Validators.required],
          end_time: ['', Validators.required]
      });

      this.route.paramMap.subscribe((params: ParamMap) => {
          this.publicidadId = params.get('id');
          if (this.publicidadId) {
              this.titulo = 'Editar selección de curso';
              this.subtitulo = 'Imagen de selección de curso #' + this.publicidadId;
              this.startEdition();
          }
      });
  }

  private startEdition (): void {
      if (this.publicidadId) {
          this.base.list(this.publicidadId, 'publicidad').subscribe((publicidad) => {
              this.errors = new ErrorsHandler(publicidad.code, publicidad.message, 'get', 'publicidad');
              if (!this.errors.setError()) {
                  const publicidadData = publicidad.result;
                  const endDate = this.datepipe.transform(publicidadData.end_date.split(' ')[0], 'yyyy-MM-dd');
                  const endTime = publicidadData.end_date.split(' ')[1];

                  const startDate = this.datepipe.transform(publicidadData.start_date.split(' ')[0], 'yyyy-MM-dd');
                  const startTime = publicidadData.start_date.split(' ')[1];

                  if (publicidadData.image) {
                      this.filesFromServer = [publicidadData.image];
                  }

                  this.form.patchValue({
                      site: publicidadData.site,
                      end_date: endDate,
                      end_time: endTime,
                      start_time: startTime,
                      image: publicidadData.image,
                      url: publicidadData.url,
                      start_date: startDate
                  });
              } else {
                  Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
              }
          });
      }
  }

  ngOnDestroy (): void {
  }

  onSubmit (form: FormGroup): void {
      if (form.valid) {
          if (!this.publicidadId) {
              this.form.patchValue({
                  site: this.global.domain === 'jurispol_pro' ? 'jurispol_pro' : 'jurispol'
              });
          }
          const ruta = this.publicidadId ? 'publicidad/edit/' + this.publicidadId : 'publicidad/add';
          const action = this.publicidadId ? 'update' : 'submit';

          if (!this.comoprobarFechas()) {
              Swal.fire('Atención', 'La fecha de inicio del curso ha de anterior a la fecha fin. ' +
            'El fin de acceso ha de ser posterior a la fecha fin.', 'warning');
              return;
          }

          if ((!(this.filesFromServer) && !(this.filesToServer)) ||
          (this.filesToServer === undefined && this.filesFromServer.length === 0) ||
              (this.filesToServer !== undefined && this.filesToServer.length === 0 && this.filesFromServer.length === 0)) {
              Swal.fire('Atención', 'Por favor, seleccione una imagen', 'warning');
              return;
          }

          if (this.filesToServer &&
              this.filesToServer[0] &&
              !this.filesToServer[0].nombreArchivo.includes('.png') &&
              !this.filesToServer[0].nombreArchivo.includes('.jpeg') &&
              !this.filesToServer[0].nombreArchivo.includes('.jpg')) {
              Swal.fire('Atención', 'El formato del fichero debe ser PNG, JPEG o JPG', 'warning');
              return;
          }

          this.base.add(form.value, ruta).subscribe((item) => {
              this.errors = new ErrorsHandler(item.code, item.message, action, 'curso');
              if (!this.errors.setError()) {
                  Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                  if (this.filesToServer) {
                      this.multiFileUploadService.uploadMultiEdit(item.id, this.filesToServer, 'publicidad/add').then((res) => {
                          this.goBack();
                      });
                  } else {
                      this.goBack();
                  }
              } else {
                  Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
              }
          });
      }
  }

  // this events functions return values to parent component
  setArchivoResolucion (event): void {
      this.filesToServer = event;
      this.form.patchValue({
          image: event.nombreArchivo
      });
  }

  setArchivoFromServer (event): void {
      this.filesFromServer = event;
      this.form.patchValue({
          image: event.nombreArchivo
      });
  }

  private comoprobarFechas (): boolean {
      const startDate = new Date(this.form.value.start_date + ' ' + this.form.value.start_time);
      const endDate = new Date(this.form.value.end_date + ' ' + this.form.value.end_time);

      return startDate < endDate;
  }

  goBack (): void {
      const ruta = this.publicidadId ? '../..' : '..';
      this.router.navigate([ruta], { relativeTo: this.route });
  }
}
