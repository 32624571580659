import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'cursoActivo'
})
export class CursoActivoPipe implements PipeTransform {

    transform(value: any): boolean {
        const response = false;

        if (value) {
            const hoy = new Date();
            const fin = new Date(value);

            if (fin > hoy) {
                return true;
            }
        }
        return response;
    }
}
