import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'notas'
})
export class NotasPipe implements PipeTransform {

    transform(value: number): boolean {
        if (value >= 5) {
            return true;
        } else {
            return false;
        }
    }
}
