<div  class="mb-24">
  <button type="button" (click)="abrirExplorador()"
          class="mat-raised-button mat-button-base mat-accent btnJurispolCancelar text-transform-none w-100 mat-button">Abrir explorador</button>
  <button *ngIf="archivos?.length > 0 || filesFromServer?.length > 0"
    type="button" (click)="limpiarArchivos()"
    class="mat-raised-button mat-button-base mat-accent btnJurispolCancelar text-transform-none w-100 mt-3 mat-button">Quitar archivos</button>
</div>

<div *ngIf="archivos?.length > 0 || filesFromServer?.length > 0" class="col-md-8">
  <div class="files-main mb-24">
    <ng-container *ngIf="archivos.length > 0 && typeString(archivos)">
      <div *ngFor="let archivo of archivos" class="file-container">
        <img [src]="archivo.nombreArchivo | dragdrop" />
        <p style="overflow: hidden;">{{ archivo.nombreArchivo }}</p>
        <div class="file-clear">
          <span>{{ archivo.archivo.size / 1024 / 1024 | number: ".2-2" }} MB</span>
          <span class="clear-btn" (click)="clearFile(archivo.nombreArchivo, false)">X</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="filesFromServer.length > 0">
      <ng-container *ngFor="let archivo of filesFromServer">
        <div class="file-container" >
          <img [src]="archivo | dragdrop" />
          <p style="overflow: hidden;">{{ archivo }}</p>
          <div class="file-clear-server">
            <span class="clear-btn" (click)="clearFile(archivo, true)">X</span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
