<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <div class="header accent p-24 h-60" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="h1">{{titulo}}</div>
        </div>
    </div>

    <div class="content p-24">
        <div class="mb-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-md="row">

            <form class="mat-elevation-z4" fxLayout="column" fxLayoutAlign="start"
                  fxFlex="1 0 auto" name="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">

                <div class="h2 mb-24">{{subtitulo}}</div>

                <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-12">
                        <mat-label class="mb-24">Sede</mat-label>
                        <input matInput formControlName="nombre" required>
                        <mat-icon matSuffix class="secondary-text">edit</mat-icon>
                        <mat-error *ngIf="form.get('nombre').hasError('required')">La sede es un campo obligatorio.</mat-error>
                        <mat-error *ngIf="!form.get('nombre').hasError('minLength')">El formato no es correcto, debe tener tres caracteres y solo letras.</mat-error>
                    </mat-form-field>
                </div>

                <div fxlayout="row" fxlayoutalign="center center" fxFlex="1 0 auto" class="form__btn">
                    <button type="button" mat-button (click)="goBack()"
                            class="mat-raised-button mat-button-base mat-accent btnJurispolCancelar mr-24">
                        <span class="mat-button-wrapper"> Cancelar </span>
                        <div class="mat-ripple mat-button-ripple"></div>
                        <div class="mat-button-focus-overlay"></div>
                    </button>
                    <button type="submit" color="accent"
                            class="mat-raised-button mat-button-base mat-accent btnJurispol">
                        <span class="mat-button-wrapper"> Guardar </span>
                        <div class="mat-ripple mat-button-ripple"></div>
                        <div class="mat-button-focus-overlay"></div>
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>


