import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {AuthService} from '../auth.service';
import {Injectable} from '@angular/core';
import { rutasSegunIdRole } from '../../../helpers/Helpers';
@Injectable({
    providedIn: 'root'
})

export class RoleGuard implements CanActivate, CanLoad {
    rutasSegunIdRole = rutasSegunIdRole;
    constructor(private router: Router, private auth: AuthService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const allowedRoles = next.data['allowedRoles'];
        return this.isAuthenticated(allowedRoles);
    }

    canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
        const allowedRoles = route.data['allowedRoles'];
        return this.isAuthenticated(allowedRoles);
    }

    isAuthenticated(allowedRoles: string[]): Promise<boolean> {
        return new Promise((observer) => {
            this.auth.getAuthAuthorization().subscribe(item => {
                if (item) {
                    const userData: any = this.auth.getUserData();
                    let hasAccess = false;
                    if (userData) {
                        hasAccess = userData.id_roles.some((role) => allowedRoles.includes(role));
                    } else {
                        this.unauthorizedAction();
                    }
                    hasAccess ? observer(hasAccess) : this.unauthorizedAction();
                    if(userData.id_roles.includes("RUS") || userData.id_roles.includes("RJP"))
                    {
                        const course = localStorage.getItem("course");
                        if(!course)
                        {
                            this.noActiveCourseAction(userData);
                        }
                    }

                } else {
                    this.unauthorizedAction();
                }
            }, error => {
                console.error(error);
                this.unauthorizedAction();
            });
        });
    }

    unauthorizedAction(): void {
        this.router.navigate(['/login']);
    }

    noActiveCourseAction(token): void {
        const roleRoute = this.rutasSegunIdRole(token.id_roles);
        this.router.navigate(["/"+roleRoute+"/mis-cursos/selectCourse"]);
    }
}
