import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CursoRecursoCasoRoutingModule} from './curso-recurso-caso.routing.module';
import {ListCursoRecursoCasoComponent} from './list/list_curso-recurso-caso.component';
import {AddCursoRecursoCasoComponent} from './add/add_curso-recurso-caso.component';
import {FlexModule} from "@angular/flex-layout";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatIconModule} from "@angular/material/icon";
import {ModalModule} from '../../../components/modal/modal.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';

@NgModule({
    declarations: [
        ListCursoRecursoCasoComponent,
        AddCursoRecursoCasoComponent
    ],
    imports: [
        CommonModule,
        CursoRecursoCasoRoutingModule,
        FlexModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        MatIconModule,
        ModalModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule
    ]
})
export class CursoRecursoCasoModule { }
