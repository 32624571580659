import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-select-bar",
    templateUrl: "./select-bar.component.html",
})
export class SelectBarComponent {
    @Input() array: any = [];
    @Input() error: string;
    @Input() name = "id";
    @Input() value: string;
    @Input() header: string;
    @Input() selected: string;
    @Input() nombre = "nombre";
    @Input() nombreSeleccion: string = "";
    @Input() deshabilitarHeader: boolean = true;
    @Input() addOption: any[];

    @Output() setData: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    setDatas(event): void {
        this.setData.emit(event.target.value);
    }
}
