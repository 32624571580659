import { DragDropService } from '../dragdrop.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FilesContainerComponent } from '../filesContainer/files-container.component';

@Component({
    selector: 'app-drag-drop-files',
    templateUrl: './carga.component.html',
    styleUrls: ['../dragdrop.component.scss']
})
export class CargaComponent implements OnInit {
    estaSobreElemento: boolean;
    @Input() archivos: any[] = [];
    @Input() filesFromServer: string[] = [];
    @Input() excluidos = [];
    @Input() grupoExcluidos = [];
    @Input() titulo: string;
    @Input() showTitle: boolean;
    @Input() isArray: boolean;
    @Input() identificador: string = null;
    @Input() class: string = 'col-md-4';
    alerta = 'Arrastre aquí sus archivos';
    @Output() archivosEmmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() archivosEmmitFromServer: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('alertaElem', { static: true }) alertaElem: ElementRef;
    @ViewChild('explorer', { static: true }) explorer: ElementRef;
    @ViewChild(FilesContainerComponent) FilesContainerComponent: FilesContainerComponent;

    constructor (private dragService: DragDropService, private renderer: Renderer2) {
        this.estaSobreElemento = false;
        this.showTitle = true;
        this.titulo = 'Seleccionar archivos *';

        this.dragService.removeFile$.subscribe((name) => {
            if (this.identificador == null) { // esto es para que los viejos usen la parte del codigo de siempre
                this.archivos = this.archivos.filter((item) => item.nombreArchivo != name);
                this.archivosEmmit.emit(this.archivos);
            } else { // es para que las vistas que tengan dos componentes de este, no se les borren todos los archivos al borrar uno
                if (this.archivos && this.archivos.length) {
                    this.archivos = this.archivos.filter((item) => item.nombreArchivo != name);
                    this.archivosEmmit.emit(this.archivos);
                } else {
                    this.filesFromServer = this.filesFromServer.filter((item) => item != name);
                    this.archivosEmmitFromServer.emit(this.filesFromServer);
                }
            }
        });

        this.dragService.removeFileServer$.subscribe((name) => {
            this.filesFromServer = this.filesFromServer.filter((item) => item != name);
            this.archivosEmmitFromServer.emit(this.filesFromServer);
        });
    }

    ngOnInit (): void {
    }

    cargarImagenes (event): void {
        if (!this.isArray) {
            this.archivosEmmitFromServer.emit([]);
            this.archivos = event;
            this.archivosEmmit.emit(event);
        } else {
            this.archivos = event;
            this.archivosEmmit.emit(this.archivos);
        }
    }

    cargarImagenesFromClick (event): void {
        const file = event.target.files[0];

        if (this.groupFile(file) && this.extFile(file)) {
            const fileItem = {
                archivo: file,
                estaSubiendo: false,
                nombreArchivo: file.name,
                progreso: 0
            };

            if (!this.isArray) {
                this.archivos = [fileItem];
                this.filesFromServer = [];
                this.archivosEmmitFromServer.emit([]);
                this.archivosEmmit.emit(this.archivos);
            } else {
                this.archivos.push(fileItem);
                this.archivosEmmit.emit(this.archivos);
            }
        }
    }

    abrirExplorador (event): void {
        this.explorer.nativeElement.click();
    }

    clearAll (event) {
        this.archivosEmmitFromServer.emit([]);
        this.archivosEmmit.emit([]);
    }

    alert (event) {
        this.renderer.addClass(this.alertaElem.nativeElement, 'alerta');
        this.alerta = event;
        setTimeout(() => {
            this.renderer.removeClass(this.alertaElem.nativeElement, 'alerta');
            this.alerta = 'Arrastre aquí sus archivos';
        }, 2000);
    }

    clearAllElementsFromView (nombreFichero: string): void {
        this.FilesContainerComponent.limpiarArchivosSubidosSinAlert(nombreFichero);
    }

    private extFile (file: File) {
        const ext = file.name.split('.')[1];
        if (this.excluidos && this.excluidos.length > 0) {
            if (this.excluidos.includes(ext)) {
                this.alert(`El tipo de archivo ${ext} no está permitido`);
                return false;
            }
        }
        return true;
    }

    private groupFile (file: File): boolean {
        if (file) {
            const ext = file.type.split('/')[0];
            if (this.grupoExcluidos && this.grupoExcluidos.length > 0) {
                if (this.grupoExcluidos.includes(ext)) {
                    this.alert(`El tipo de archivo ${ext} no está permitido`);
                    return false;
                }
            }
            return true;
        }
        return false;
    }
}
