import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from "@angular/router";
import { AuthService } from "../auth.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TutorGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.isAuthenticated();
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return this.isAuthenticated();
  }

  isAuthenticated(): Promise<boolean> {
    return new Promise((observer) => {
      this.auth.getAuthAuthorization().subscribe(
        (item) => {
          if (item) {
            const token: any = this.auth.getUserData();
            let r = false;
            if (token) {
              if (token.id_roles.includes("RTR")) {
                r = true;
              } else {
                this.unauthorizedAction();
              }
            } else {
              this.unauthorizedAction();
            }
            observer(r);
          } else {
            this.unauthorizedAction();
          }
        },
        (error) => {
          this.unauthorizedAction();
        }
      );
    });
  }

  unauthorizedAction(): void {
    this.router.navigate(["/login"]);
  }
}
