export class ErrorsHandler {
    code: number;
    message: string;
    type?: string;
    object?: any = {
        title: '',
        text: '',
        icon: ''
    };

    data?: string;

    constructor (code?: number, message?: string, type?: string, data?: string) {
        this.code = code;
        this.message = message;
        this.type = type;
        this.data = data;
        this.object = this.getError(this.type);
    }

    setError (): any {
        if (this.code !== 200) {
            return this.object;
        } else {
            let texto = '';
            switch (this.type) {
            case 'delete':
                texto = `Se eliminó ${this.data}.`;
                break;
            case 'update':
                texto = `Se actualizó ${this.data}.`;
                break;
            case 'submit':
                texto = `Se creó ${this.data}.`;
                break;
            }

            this.object = {
                title: 'Perfecto',
                text: texto,
                icon: 'success'
            };

            return false;
        }
    }

    getError (type): object {
        switch (type) {
        case 'delete':
            return this.object = {
                title: 'Alerta',
                text: `No se pudo borrar ${this.data}, seguramente tenga una relacion con algun dato`,
                icon: 'warning'
            };

        case 'submit':
            return this.object = {
                title: 'Error',
                text: `Ha ocurrido un error al guardar ${this.data}.`,
                icon: 'error'
            };
        case 'get':
            return this.object = {
                title: 'Error',
                text: `No se pudo recuperar ${this.data}.`,
                icon: 'error'
            };
        case 'getAll':
            return this.object = {
                title: 'Error',
                text: `No se pudieron  recuperar ${this.data}.`,
                icon: 'error'
            };
        }
    }
}
