<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
  <div class="header accent p-24 h-60" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="center start">
      <div class="h1">{{ titulo }}</div>
    </div>
  </div>

  <div class="content p-24">
    <div class="mb-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-md="row">
      <form class="mat-elevation-z4" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <div class="h2 mb-24">{{ subtitulo }}</div>

        <div fxLayout="row" fxLayoutAlign="center space-around" fxFlex="1 1 auto" class="mb-24">
          <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label class="mb-24">Caso</mat-label>
            <select  matNativeControl formControlName="case_id" required [disabled]="casoRecursoId">
              <option value="" selected disabled>Selecciona caso</option>
              <option *ngFor="let caso of casos" [value]="caso.id">{{ caso.name }}</option>
            </select>
            <mat-error>El campo caso es obligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="center space-around" fxFlex="1 1 auto" class="mb-24">
          <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label class="mb-24">Dia inicio presentación</mat-label>
            <input matInput type="date" formControlName="presentation_start" required />
         
            <mat-error>El campo inicio presentación es obligatorio.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label class="mb-24">Hora inicio presentación</mat-label>
            <input matInput type="time" formControlName="hora_presentation_start" required />
          
            <mat-error>El campo inicio presentación es obligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="center space-around" fxFlex="1 1 auto" class="mb-24">
          <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label class="mb-24">Dia fin presentación</mat-label>
            <input matInput type="date" formControlName="presentation_end" required />
          
            <mat-error>El campo fin presentación es obligatorio.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label class="mb-24">Hora fin presentación</mat-label>
            <input matInput type="time" formControlName="hora_presentation_end" required />
           
            <mat-error>El campo inicio presentación es obligatorio.</mat-error>
          </mat-form-field>
        </div>



        <div fxLayout="row" fxLayoutAlign="center space-around" fxFlex="1 1 auto" class="mb-24">
          <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label class="mb-24">Dia inicio resolución</mat-label>
            <input matInput type="date" formControlName="resolution_start" required />
        
            <mat-error>El campo inicio resolución es obligatorio.</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label class="mb-24">Hora inicio resolución</mat-label>
            <input matInput type="time" formControlName="hora_resolution_start" required />
       
            <mat-error>El campo inicio resolución es obligatorio.</mat-error>
          </mat-form-field>
        </div>


        <div fxLayout="row" fxLayoutAlign="center space-around" fxFlex="1 1 auto" class="mb-24">
          <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label class="mb-24">Dia fin resolución</mat-label>
            <input matInput type="date" formControlName="resolution_end" required />
       
            <mat-error>El campo fin resolución es obligatorio.</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label class="mb-24">Hora fin resolución</mat-label>
            <input matInput type="time" formControlName="hora_resolution_end" required />
           
            <mat-error>El campo fin resolución es obligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div fxlayout="row" fxlayoutalign="right center" fxFlex="1 0 auto" class="form__btn">
          <button type="button" mat-button (click)="goBack()" class="mat-raised-button mat-button-base mat-accent btnJurispolCancelar mr-24">
            <span class="mat-button-wrapper"> Cancelar </span>
            <div class="mat-ripple mat-button-ripple"></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
          <button type="submit" color="accent" class="mat-raised-button mat-button-base mat-accent btnJurispol">
            <span class="mat-button-wrapper"> Enviar </span>
            <div class="mat-ripple mat-button-ripple"></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
