import { Directive, Input } from '@angular/core';
import {NG_VALIDATORS, Validator, FormControl, AbstractControl} from '@angular/forms';

@Directive({
  selector: '[phone][formControlName],[phone][formControl],[phone][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting:  PhoneMatchingDirective , multi: true}]
})
export class PhoneMatchingDirective implements Validator {
  @Input()
  phone: number;

  validate(c: FormControl): {[key: string]: any} {
    if(c.value) {
      let v = c.value;
      const num = ['9','6','7'];
      let char = 6;
      if (v !== null && v !== '') {
        v = v.length;
        char = c.value.charAt(0);
        return ( v < this.phone || v > this.phone || isNaN(c.value) || !num.includes(char+''))? {"phone": true} : null;
      }
    }

    return null;

 }
}


export   function phone (c: AbstractControl): { [key: string]: boolean } | null  {

  if(c.value) {
    let v = c.value;
    const num = ['9','6','7'];
    let char = 6;
    if (v !== null && v !== '') {
      v = v.length;
      char = c.value.charAt(0);
      return ( v.length === 9  ||  isNaN(c.value) || !num.includes(char+''))? {"phone": true} : null;
    }
  }

  return null;
}
