import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "typeFileActivity",
})
export class TypeFileActivity implements PipeTransform {
  transform(value: any): string {
    const obj = { do_test: "TEST", download_document: "DOCUMENTO", view_class_video: "VÍDEO", view_class_zoom: "CLASE" };

    return obj[value];
  }
}
