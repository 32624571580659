import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'ponerHtml'
})
export class PonerHtmlPipe implements PipeTransform {

    transform(value: any): string {


        value = value.substr(3, value.length);

        value = value.substr(0, value.cadena.length - 4 );

        return value;

    }
}