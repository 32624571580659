import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import Swal from 'sweetalert2';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation } from 'app/navigation/navigation';
import { AuthService } from '../../../main/auth/auth.service';
import { BaseService } from '../../../bases-services/base.service';
import { ErrorsHandler } from '../../../helpers/ErrorsHandler';
import { ToolbarService } from './toolbar.service';
import { Router } from '@angular/router';
import { MisCursosService } from '../../../main/alumno/components/mis-cursos/mis-cursos.service';
import { AnuncioService } from '../../../main/comunicacion/anuncios/anuncio.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {
  horizontalNavbar: boolean;
  navigation: any;
  data: any;
  isUser: boolean;
  errors: ErrorsHandler;
  isAdministrative: boolean;
  notifications: number;
  notificationsInterval: any;
  feedbackInterval: any;
  notificationsItems: any;

  constructor (private router: Router,
              private _fuseSidebarService: FuseSidebarService,
              public auth: AuthService,
              private base: BaseService,
              private toolbarService: ToolbarService,
              private anuncioService: AnuncioService,
              private misCursosService: MisCursosService
  ) {
      this.navigation = navigation;
  }

  ngOnInit (): void {
      const userData = this.auth.getUserData();

      this.toolbarService.$resetNotifications.subscribe(_ => {
          clearInterval(this.notificationsInterval);
          clearInterval(this.feedbackInterval);
      });

      if (userData) {
          this.data = this.auth.getDecodedAccessToken(localStorage.getItem('token'));
          this.isUser = userData.id_roles.some(role => ['RUS', 'RJP'].includes(role));
          if(!this.misCursosService.getDataLogo()){
              this.misCursosService.setDataStore(this.data);
          }

          this.auth.user$.subscribe((item) => {
              this.data = item;
              this.misCursosService.setDataStore(this.data);
          });

          this.isAdministrative = userData.id_roles.some((elem) => ['RSS', 'RSM', 'RSP'].includes(elem));
          if (!this.isAdministrative) {
              this.notifications = 0;
              this.updateNotifications();
          }
      }

      // Si se accede a comunicaciones/anuncios se marcan como leídas y se actualizan las Notificaciones
      this.anuncioService.getNotificationsStatus().subscribe((status) => {
          if (status) {
              this.newNotifications();
          } else {
              this.anuncioService.setAllNotificationsAsRead(false);
          }
      });
  }

  getDataImg(){
      return this.misCursosService.getDataLogo();
  }

  getRightNavbar () {
      return this.toolbarService.getRightNavbar();
  }

  getHiddenNavbar () {
      return this.toolbarService.getHiddenNavbar();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen (key): void {
      this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search (value): void {
      // Do your search here...
  }

  private newNotifications (): void {
      const course = localStorage.getItem('course');
      if (course) {
          this.base.add({ course: course }, 'anuncio/new').subscribe((item) => {
              this.errors = new ErrorsHandler(item.code, item.message, 'get', 'anuncios');
              if (!this.errors.setError()) {
                  this.toolbarService.sendNotifications(item.id);
                  this.notificationsItems = item.id.announcements;
                  this.notifications = item.id.new_announcements;
              } else {
                  Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
              }
          });
      }
  }

  public getTitleScope (): string {
      if (this.misCursosService && this.misCursosService.getSelectedCourse()) {
          return this.misCursosService.getSelectedCourse();
      }
      return '';
  }

  updateNotifications (): void {
      this.newNotifications();
      this.notificationsInterval = setInterval(() => {
          this.newNotifications();
      }, 600000); // Every 10 minutes
  }

  goToAnnouncements (): void {
      this.router.navigate(['comunicacion/anuncios/tablon']);
  }

  stripHtml (html:string) {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
  }
}
