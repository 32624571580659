import { Subject } from 'rxjs';
import { BaseService } from '../../../bases-services/base.service';
import { environment } from '../../../../environments/environment';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class DragDropService {
    public url = "";
    public history = [];
    events: any;
    alert: Subject<string> = new Subject();

    removeFileO: Subject<any> = new Subject();
    removeFile$ = this.removeFileO.asObservable();

    removeFileServerO: Subject<any> = new Subject();
    removeFileServer$ = this.removeFileServerO.asObservable();

    constructor(private http: HttpClient, private base: BaseService) { }

    private normalize(str: string): any {
        let from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
            to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
            mapping = {};
        for (let i = 0, j = from.length; i < j; i++) {
            mapping[from.charAt(i)] = to.charAt(i);
        }

        return function (str) {
            const ret = [];
            for (let i = 0, j = str.length; i < j; i++) {
                const c = str.charAt(i);
                if (mapping.hasOwnProperty(str.charAt(i))) {
                    ret.push(mapping[c]);
                } else {
                    ret.push(c);
                }
            }
            return ret.join("");
        }
    }

    public upload(item, files: File[], url?: string): Promise<any> {
        if (!url) {
            url = "tema/recurso";
        }
        const token = localStorage.getItem("token");
        return new Promise<any>((resolve) => {
            const form = new FormData();

            files.forEach((file) => {
                form.append("picture[]", file, this.normalize(file.name));
            });
            form.append("id", item.id);
            form.append('title', item.title);
            form.append('message', item.message);
            form.append('key_value_array', item.key_value_array)
            fetch(environment.url + url + "/image", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    Authorization: "Bearer " + token,
                },
                body: form,
            })
                .then((res) => {
                    resolve(true);
                })
                .catch((e) => console.log("something went wrong: " + e));
        });

    }

    removeFile(file: any): void {
        this.removeFileO.next(file);
    }

    removeServerFile(file: any): void {
        this.removeFileServerO.next(file);
    }
}
