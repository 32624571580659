import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'pipeSeparator'
})
export class PipeSeparatorPipe implements PipeTransform {

    constructor() {
    }

    transform(value: any[]): any {
        let html = '';
        let flag = '';
        const total = value.length;

        if (value) {
            value.forEach((item , index) => {
                const partido = item.split(' ');
                if (partido[1] !== flag) {
                    
                    const  pipe = ' | ';
                    html = html + partido[1] + pipe;
                    flag = partido[1];
                }
            });
        }
  
        html = html.substr(0, html.length - 2);
        return html;
    }

}
