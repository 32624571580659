import { dragdropPipe } from './dragdrop.pipe';
import { FilesContainerComponent } from './filesContainer/files-container.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CargaComponent } from './dragFile/carga.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { DragDropDirective } from './dragdrop.directive';



@NgModule({
    declarations: [
        CargaComponent,
        DragDropDirective,
        FilesContainerComponent,
        dragdropPipe
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
    ],
    exports: [
        CargaComponent,
        FilesContainerComponent,
        dragdropPipe
    ]
})
export class DragDropModule { }
