import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'marcarRespuestaCorrecta'
})
export class MarcarRespuestaCorrectaPipe implements PipeTransform {

    transform(value: string, correcta: string): boolean {
        if (value === correcta) {
            return true;
        } else {
            return false;
        }
    }

}
