import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BannerAlumnoDashboardListComponent} from './list/banner-alumno-dashboard-list.component';
import {BannerAlumnoDashboardAddComponent} from './add/banner-alumno-dashboard-add.component';

const routes: Routes = [
    {
        path: '',
        component: BannerAlumnoDashboardListComponent
    },
    {
        path: 'add',
        component: BannerAlumnoDashboardAddComponent
    },
    {
        path: 'edit/:id',
        component: BannerAlumnoDashboardAddComponent
    },
   ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BannerAlumnoDashboardRoutingModule {
}