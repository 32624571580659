import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TemasRoutingModule} from './temas.routing.module';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../../../pipes/pipes/pipes.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FlexModule} from '@angular/flex-layout';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatButtonModule} from '@angular/material/button';
import {AddTemaComponent} from './add/add_tema.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ListTemaComponent} from './list/list_tema.component';
import {HtmlComponentsModule} from "../../../components/HtmlComponents/html-components.module";
import {MatSelectModule} from "@angular/material/select";

@NgModule({
    declarations: [
        AddTemaComponent,
        ListTemaComponent
    ],
    exports: [],
    imports: [
        CommonModule,
        TemasRoutingModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        PipesModule,
        NgxSpinnerModule,
        FlexModule,
        NgxDatatableModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        HtmlComponentsModule,
        MatSelectModule,
    ]
})
export class TemasModule {
}
