<div class="row">
  <div class="{{ class }} mb-24">
    <ng-container *ngIf="showTitle">
      <mat-label class="mb-24">{{ titulo }}</mat-label>
    </ng-container>
    <div
      dragDropFiles
      [excluidos]="excluidos"
      [isArray]="isArray"
      [grupoExcluidos]="grupoExcluidos"
      [archivosFromServer]="filesFromServer"
      [archivos]="archivos"
      (emmitAlert)="alert($event)"
      (mouseSobre)="estaSobreElemento = $event"
      (cargarArchivos)="cargarImagenes($event)"
      [ngClass]="{ 'file-over': estaSobreElemento }"
      class="well drop-zone"
    >
      <h4 #alertaElem>{{ alerta }}</h4>
      <img src="assets/images/jurispol/drop-images.png" />
    </div>
  </div>

  <input type="file" hidden #explorer (change)="cargarImagenesFromClick($event)">

  <app-files-container (abrirExploradorEmitter)="abrirExplorador($event)" (clearAllEmitter)="clearAll($event)" [archivos]="archivos" [filesFromServer]="filesFromServer"></app-files-container>

</div>
