import {
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation,
} from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";

import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { AuthService } from "../auth.service";
import { Observable, Subject } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { FuseNavigationService } from "../../../../@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "../../../../@fuse/components/sidebar/sidebar.service";
import { imageTypes } from "../../../helpers/imageFilters";
import { BaseService } from "../../../bases-services/base.service";
import Swal from "sweetalert2";

@Component({
    selector: "profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ProfileComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    form: FormGroup;
    data: any;
    file: any;
    dataForm: any;

    @HostBinding("class.bar-closed")
    barClosed: boolean;

    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _formBuilder: FormBuilder,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,

        private auth: AuthService,
        private base: BaseService
    ) {
        // Set the defaults
        this.barClosed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        const observable = new Observable((subscriber) => {
            subscriber.next(
                this.auth.getDecodedAccessToken(localStorage.getItem("token"))
            );
        });

        this.auth.user$.subscribe((item) => {
            this.data = item;

            if (this.data) {
                this.form = this._formBuilder.group({
                    foto: new FormControl(this.data.foto),
                    username: new FormControl(
                        { value: "", disabled: true },
                        Validators.required,

                    ),
                    password: new FormControl({ value: "", disabled: true }, Validators.minLength(5),),
                    password_2: new FormControl({ value: "", disabled: true }, [
                        Validators.minLength(5),
                        Validators.required,
                    ]),
                });
            }
        });

        observable.subscribe((item) => {
            this.data = item;
            if (this.data) {
                this.form = this._formBuilder.group({
                    foto: new FormControl(this.data.foto),
                    username: new FormControl(
                        { value: "", disabled: true },
                        Validators.required,
                    ),
                    password: new FormControl({ value: "", disabled: true }, Validators.minLength(5),),
                    password_2: new FormControl({ value: "", disabled: true }, [
                        Validators.minLength(5),
                        Validators.required,
                    ]),
                });
            }
        });
    }

    setFile($event): void {
        if (imageTypes($event.target.files[0].type)) {
            const reader = new FileReader();
            this.file = $event.target.files[0];
            reader.readAsDataURL($event.target.files[0]);
            reader.onload = (evt) => {
                this.file = reader.result;
                this.form.patchValue({
                    foto: $event.target.files[0],
                });
            };
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._fuseNavigationService.removeNavigationItem("custom-function");
    }

    onSubmit(form: FormGroup): void {
        if (form.valid) {
            if (form.value["password_2"]) {
                this.dataForm = {
                    id: this.data.uuid,
                    password: form.value["password_2"],
                    username: "",
                    newPassword: "",
                };

                this.base
                    .add(this.dataForm, "validate/password")
                    .subscribe((item) => {
                        if (item.id) {
                            this.dataForm.username = form.value["username"];
                            this.dataForm.newPassword = form.value["password"];
                            this.dataForm.foto = form.value["foto"];

                            this.base
                                .add(this.dataForm, "update/credentials")
                                .subscribe((it) => {
                                    if (it) {
                                        this.auth.setImage(it.id);
                                        Swal.fire(
                                            "Actualizado",
                                            "Se actualizo con exito",
                                            "success"
                                        );
                                        this.toggleSidebarOpen("profile");
                                    } else {
                                        Swal.fire(
                                            "Error",
                                            "Ocurrio un error inesperado",
                                            "error"
                                        );
                                    }
                                });
                        } else {
                            Swal.fire(
                                "Atencion",
                                "El password no se reconoce",
                                "error"
                            );
                        }
                    });
            } else {
                Swal.fire(
                    "Atencion",
                    "Para actualizar debes de introducir tu password actual",
                    "warning"
                );
            }
        }
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
