<ng-container *ngFor="let t of titulo; let i = index">
  <div fxLayout="row" fxLayoutAlign="left center" fxFlex="1 1 auto">
    <div appearance="outline" fxFlex="100" class="pr-4 mb-24">
      <mat-label class="mr-4">{{ t }}</mat-label>
      <mat-checkbox *ngFor="let c of this.checkbox[i]; let in = index" #checkbox
                    [id]="position + '|' + c.id" [name]="c.nombre"
                    (change)="onChange($event, in, c.id)"
                    class="mr-4">
        {{ c.nombre }}
      </mat-checkbox>
    </div>
  </div>
</ng-container>
