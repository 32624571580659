import {Directive, forwardRef, Attribute} from '@angular/core';
import {NG_VALIDATORS, Validator, AbstractControl} from '@angular/forms';


@Directive({
    selector: '[appDniMatching],[formControlName],[validateEqual][formControl],[validateDni][ngModel]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => DniMatchingDirective), multi: true}
    ]
})
export class DniMatchingDirective implements Validator {

    constructor(@Attribute('validateDni') public validateDni: string) {
    }

    validate(c: AbstractControl): { [key: string]: any } {

        const v = c.value;
        if (v !== undefined && v !== null && v !== '') {
            const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
            const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
            const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
            const str = v.toString().toUpperCase();
            if (!nifRexp.test(str) && !nieRexp.test(str)) return {validateDni: false};
            const nie = str
                .replace(/^[X]/, '0')
                .replace(/^[Y]/, '1')
                .replace(/^[Z]/, '2');

            const letter = str.substr(-1);
            const charIndex = parseInt(nie.substr(0, 8)) % 23;

            if (validChars.charAt(charIndex) !== letter) {
                return {validateDni: false};
            }
        }
        return null;


    }


}


export async function validateDni(c: AbstractControl) {
    const v = c.value;
    if (v !== undefined && v !== null && v !== '') {
        const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
        const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
        const str = v.toString().toUpperCase();
        if (!nifRexp.test(str) && !nieRexp.test(str)) return {validateDni: false};
        const nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2');

        const letter = str.substr(-1);
        const charIndex = parseInt(nie.substr(0, 8)) % 23;

        if (validChars.charAt(charIndex) !== letter) {
            return {validateDni: false};
        }
    }
    return null;
}
