import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'marcarRespuestaMarcada'
})
export class MarcarRespuestaMarcadaPipe implements PipeTransform {

    transform(value: string, marcada: string): boolean {
        if (value === marcada) {
            return false;
        } else {
            return true;
        }
    }

}
