<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <div class="header accent h-60 p-24" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="h1">{{titulo}}</div>
        </div>
    </div>

    <div class="content p-24">
        <div class="h2 titleListado">{{subtitulo}}</div>

        <div class="filter-header">
            <div class="filter-header-input">
                <input type="text" placeholder="Buscar..." (keyup)="updateFilter($event)" />
            </div>

            <div class="filter-header-items">
                <button type="submit" color="accent"
                        class="mat-raised-button mat-button-base mat-accent btnJurispol"
                        (click)="gotoAdd()">
                    <span class="mat-button-wrapper"> Añadir sede </span>
                </button>
            </div>
        </div>

        <ngx-datatable
            class="material"
            [rows]="rows"
            [loadingIndicator]="loadingIndicator"
            [columnMode]="'force'"
            [headerHeight]="48"
            [footerHeight]="56"
            [rowHeight]="'auto'"
            [reorderable]="true"
            [limit]="10"
        >
            <ngx-datatable-column name="Sede" prop="nombre"></ngx-datatable-column>
            <ngx-datatable-column name="Acciones"
                                  [canAutoResize]="false"
                                  [sortable]="false"
                                  prop="id">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                    <div class="action">
                        <div class="action__container">
                            <ul class="action__list">
                                <li class="action__item">
                                    <span class="action_icon blue-A100-fg" (click)="update(value)">
                                        <mat-icon>create</mat-icon>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>

    </div>
</div>
