export const viewResolution = (width: number) => {
    const SWidth = screen.width;
    if (SWidth < width) {
        return true;
    } else {
        return false;
    }
};

export const roles = (role) => {
    const arrayRoutes = {
        ROLE_TUTOR: 'sede',
        ROLE_SECRE: 'admin',
        ROLE_ADMIN_CAMPUS: 'master',
        ROLE_USER: 'user'
    };
    return arrayRoutes[role];
};

export const rolesExtended = (role) => {
    const arrayRoutes =
        {
            ROLE_PROF: 'PROFESOR',
            ROLE_TUTOR: 'TUTOR',
            ROLE_SECRE: 'SECRETARIA SEDE',
            ROLE_SECRE_MASTER: 'SECRETEARIA MASTER',
            ROLE_USER: 'ALUMNO',
            ROLE_ADMIN_CAMPUS: 'ROOT'
        };
    return arrayRoutes[role];
};

export const rutasSegunIdRole = (roleArray) => {
    if (roleArray.includes('RSP')) {
        return 'master';
    }
    if (roleArray.includes('RSM')) {
        return 'admin';
    }
    if (roleArray.includes('RSS')) {
        return 'secresede';
    }
    if (roleArray.includes('RCR')) {
        return 'coord';
    }
    if (roleArray.includes('RTR')) {
        return 'tutor';
    }
    if (roleArray.includes('RPF')) {
        return 'prof';
    }
    if (roleArray.includes('RUS')) {
        return 'users';
    }
    if (roleArray.includes('RJP')) {
        return 'jurispolPro';
    }

    return 'disabled';
};

export const removeDuplicates = (originalArray, prop) => {
    const newArray = [];
    const lookupObject = {};

    for (var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    return newArray;
};

export const toFormData = (formValue: any, acceptAll = true) => {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
        const value = formValue[key];
        if (acceptAll || value) {
            formData.append(key, value);
        }
    }

    return formData;
};
