import {Component, Input, OnInit, ViewChildren, QueryList} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {SelectAreaTemaSubtemaServiceService} from '../selectAreaTemaSubtemaService.service';

@Component({
    selector: 'app-ats-checkbox',
    templateUrl: './ats-checkbox.component.html',
    styleUrls: [],
})
export class AtsCheckboxComponent implements OnInit {
    titulo: string[];
    checkbox: any[];
    response: any[];

    @Input() checkboxValues: any[];
    @Input() position: number;
    @ViewChildren('checkbox') checkboxList: QueryList<MatCheckbox>;

    /*
     * checkboxValues
     * {
     *   titulo: string,
     *   valores: [
     *       id: string
     *       nombre: string
     *   ]
     * }
     */

    constructor(private selectATSService: SelectAreaTemaSubtemaServiceService) {
        this.titulo = [];
        this.checkbox = [];
        this.response = [];
    }

    ngOnInit(): void {
    }

    initialLoad(disabled: boolean, valoresSeleccionados?: string[]): void {
        this.checkbox = [];
        this.titulo = [];
        this.response = [];

        this.checkboxValues.forEach((elem) => {
            const titulo = elem.titulo;
            if (titulo) {
                this.titulo.push(titulo);
            } else {
                this.titulo.push('Selecciona');
            }
            const index = this.checkbox.length;

            new Promise((res) => {
                this.checkbox[index] = [];
                elem.valores.forEach((v) => {
                    this.checkbox[index].push(v);
                });
                res();
            }).then(_ => {
                setTimeout(_ => {
                    this.checkboxList.toArray().forEach((check, ind) => {
                        if (valoresSeleccionados) {
                            const indice_valor = this.checkboxList.toArray()[ind]._elementRef.nativeElement.id.split('|');
                            const indice = indice_valor[0];
                            const valor = indice_valor[1];

                            if (valoresSeleccionados[indice].includes(valor)) {
                                if (! this.response.includes(valor)) { // Error: evitar valores repetidos
                                    this.response.push(valor);
                                }
                                this.checkboxList.toArray()[ind].checked = true;
                            }
                        } else {
                            this.checkboxList.toArray()[ind].checked = false;
                        }
                        this.checkboxList.toArray()[ind].setDisabledState(disabled);
                    });
                }, 100);
            });
            this.selectATSService.emitCheckboxChange(this.response, this.position);
        });
    }

    onChange(event: any, position: number, id: string): void {
        this.response = this.response.filter(item => item != id); // Delete previous value if exists
        if (event.checked) {
            this.response.push(id);
        }

        this.selectATSService.emitCheckboxChange(this.response, this.position);
    }
}
