import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'dificultad'
})
export class DificultadPipe implements PipeTransform {

    transform(value: number): string {
        switch (true) {
            case value < 4:
                return 'Alta';
                break;
            case (value > 3 && value < 8):
                return 'Media';
                break;
            case value > 7:
                return 'Baja';
                break;

        }
    }
}
