import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ErrorsHandler } from '../../../../../helpers/ErrorsHandler';
import { BaseService } from '../../../../../bases-services/base.service';
import { Aula } from '../../../../../models/aula.model';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-add-aula',
    templateUrl: './add_aula.component.html',
    styleUrls: ['../../../../scss/forms.scss'],
})
export class AddAulaComponent implements OnInit, OnDestroy {
    titulo: string;
    subtitulo: string;
    form: FormGroup;
    isEditing: boolean;
    tipos: string[];
    sedes: any[];
    errors = new ErrorsHandler();
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _formBuilder: FormBuilder,
        private base: BaseService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this._unsubscribeAll = new Subject();
        this.titulo = 'Crear un aula nueva';
        this.subtitulo = 'Aula';
        this.isEditing = false;
        this.tipos = ['virtual', 'fisica'];
    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            id: [''],
            nombre: ['', Validators.required],
            sede: [''],
            tipo: [],
        });

        this.route.paramMap.subscribe((params: ParamMap) => {
            const aulaId = params.get('id');
            if (aulaId) {
                this.titulo = 'Editar un aula';
                this.subtitulo = 'Aula #' + aulaId;
                this.isEditing = true;

                this.base.list(aulaId, 'aula').subscribe((item: any) => {
                    this.errors = new ErrorsHandler(item.code, item.message, 'get', 'aula');
                    if (!this.errors.setError()) {
                        this.form.patchValue({
                            id: item.result.id,
                            nombre: item.result.nombre,
                            sede: item.result.sede_id,
                            tipo: item.result.tipo,
                        });
                    } else {
                        Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                        this.goBack();
                    }
                });
            }
        });

        this.base.listAll('sede').subscribe((item) => {
            this.sedes = Object.values(item.result);
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    goBack(): void {
        const ruta = this.isEditing ? '../..' : '..';
        this.router.navigate([ruta], {relativeTo: this.route});
    }

    onSubmit(form: FormGroup): void {
        if (form.valid) {
            const action = this.isEditing ? 'update' : 'submit';
            const route = this.isEditing ? 'aula/edit/' + form.value.id : 'aula/add';

            this.base.add(form.value, route).subscribe((item) => {
                this.errors = new ErrorsHandler(item.code, item.message, action, 'aula');
                if (!this.errors.setError()) {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                    this.goBack();
                } else {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                }
            });
        }
    }
}
