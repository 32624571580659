import { Dictionary } from '../../../biblioteca/dictionary-domain';
import { Global } from '../../../global';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Auth } from '../../../models/auth.model';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BaseService } from '../../../bases-services/base.service';
import { ToolbarService } from '../../../layout/components/toolbar/toolbar.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  user: Auth;
  invalidCredentials: boolean;
  isUserDisabled: boolean;
  global = Global;
  dictionary = Dictionary[this.global.domain];
  loader: boolean;

  private role: Subject<string> = new Subject();

  constructor (
      private _fuseConfigService: FuseConfigService,
      private _formBuilder: FormBuilder,
      private _auth: AuthService,
      private base: BaseService,
      private toolbarService: ToolbarService,
      private router: Router
  ) {
      this.loader = false;
      this._fuseConfigService.config = {
          layout: {
              navbar: {
                  hidden: true
              },
              toolbar: {
                  hidden: true
              },
              footer: {
                  hidden: true
              },
              sidepanel: {
                  hidden: true
              }
          }
      };
      this.invalidCredentials = false;
      this.isUserDisabled = false;
  }

  ngOnInit (): void {
      this.sessionExist();

      this.loginForm = this._formBuilder.group({
          email: ['', [Validators.required, Validators.email]],
          password: ['', Validators.required]
      });
  }

  ngOnDestroy () {
  }

  private sessionExist (): void {
      const session = localStorage.getItem('token_session');

      if (session || session !== null) {
          this._auth.getAuthAuthorization().subscribe((isAuthorized) => {
              this.loader = true;
              if (isAuthorized) {
                  this.setDataForUser();
              } else {
                  this.loader = false;
                  this._auth.logout();
              }
          });
      }
  }

  login (loginForm): void {
      this.loader = true;
      this.invalidCredentials = false;
      this.isUserDisabled = false;
      if (loginForm.valid) {
          const user: Auth = {
              username: loginForm.value.email,
              password: loginForm.value.password
          };

          this._auth.login(user).subscribe(
              _ => {
                  this.setDataForUser();
              },
              (error) => {
                  this.loader = false;
                  if (error.error.code === 401) {
                      this.invalidCredentials = true;
                  } else if (error.error.code === 403) {
                      this.isUserDisabled = true;
                  }
              }
          );
      }
  }

  private userDisabled (credentials: any): string {
      if (credentials.id_roles.includes('RCR') && credentials.id_roles.includes('RPF')) {
          return 'RCR';
      }
      if (credentials.id_roles.includes('RTR') && credentials.id_roles.includes('RPF')) {
          return 'RTR';
      }

      return credentials.id_roles[0];
  }

  private setDataForUser (): void {
      const credentials = this._auth.getUserData();

      if (credentials) {
          let roleSection = this.returnSection(this.userDisabled(credentials));

          this.role.next(roleSection);

          roleSection += roleSection === 'users' || roleSection === 'jurispolPro' ? '/mis-cursos/selectCourse' : '';
          this.router.navigate(['/' + roleSection]);

          this.loader = false;
      } else {
          this._fuseConfigService.config = {
              layout: {
                  navbar: {
                      hidden: false
                  },
                  toolbar: {
                      hidden: false
                  },
                  footer: {
                      hidden: false
                  },
                  sidepanel: {
                      hidden: false
                  }
              }
          };
      }
  }

  private returnSection (role: any): string {
      const arrayRoutes = {
          RSP: 'master',
          RSM: 'admin',
          RSS: 'secresede',
          RCR: 'coord',
          RTR: 'tutor',
          RPF: 'prof',
          RUS: 'users',
          RJP: 'jurispolPro'
      };

      return arrayRoutes[role];
  }
}
