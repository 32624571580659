import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: "timeToISO",
})
export class TimeToISOPipe implements PipeTransform {
    transform(date): string {
        const d = moment(date);
        return d.toISOString();
    }
}
