import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mostrarRenovar",
})
export class MostrarRenovarPipe implements PipeTransform {
  transform(val: any, currentDate: any): boolean {
    if (!val.url_renovacion || val.url_renovacion == "") {
      return false;
    }

    const date = new Date(currentDate);
    const dia = date.getDate();
    const fechaFin = new Date(val.fin_acceso);
    const fechaFinIos = new Date(val.fin_acceso.replace(/-/g, '/'));

    if (fechaFin > date || fechaFinIos > date) {
      if (dia > 24 || dia < 15) {
        return true;
      }
    }

    return false;
  }
}
