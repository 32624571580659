import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';

@NgModule({
    declarations: [
        ModalComponent
    ],
    imports: [
        CommonModule

    ],
    exports: [ModalComponent],
    entryComponents: [ModalComponent]
})
export class ModalModule { }
