<mat-label>{{ header }}</mat-label>
<select (change)="setDatas($event)">
    <ng-container *ngIf="!deshabilitarHeader">
        <option [value]="''" selected >
            Selecciona {{ nombreSeleccion }}
        </option>
    </ng-container>
    <option *ngFor="let item of addOption" [value]="item.value">{{item.name}}</option>
    <option *ngFor="let item of array" [value]="item[name]" [selected]="selected == item[name]">
        {{ item[nombre] }}
    </option>
</select>
<mat-error>{{ error }}</mat-error>
