import { RefreshTokenService } from './refresh-token/refresh-token.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Auth } from '../../models/auth.model';
import * as jwtDecode from 'jwt-decode';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { EncrDecrService } from '../../bases-services/EncrDecrService';
import { ToolbarService } from '../../layout/components/toolbar/toolbar.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
  private privateKey = 'Auren Desarrollo';
  private user: Subject<any> = new Subject<any>();
  user$ = this.user.asObservable();

  constructor (
      private router: Router,
      private http: HttpClient,
      public EncrDecr: EncrDecrService,
      private refresh: RefreshTokenService,
      private toolbarService: ToolbarService
  ) {}

  login (user: Auth): Observable<any> {
      return this.http.post(environment.url + 'login_check', user).pipe(
          mergeMap((res: any) => this.getUser(res)),
          mergeMap((res: any) => this.setUserData(res)),
          mergeMap((res: any) => this.session(res)),
          mergeMap((res: any) => this.setToken(res.token))
      );
  }

  session (res): any {
      const token: any = this.getDecodedAccessToken(res.token);
      this.http
          .post(environment.urlImages + 'public/sesion', {
              userId: token.uuid
          })
          .subscribe((item: any) => {
              if (item.code === 200) {
                  localStorage.setItem('token_session', item.token_session);
              }
          });
      return of(res);
  }

  setImage (url: string, username?: string): void {
      let token = this.isToken();
      if (token) {
          token = this.getDecodedAccessToken(token);
          token.foto = url;
          if (username) {
              token.username = username;
          }
          this.user.next(token);
      }
  }

  getUser (res): any {
      if (res) {
          return new Promise((resolve, reject) => {
              const userInformation: any = this.getDecodedAccessToken(res.token);
              this.user.next(userInformation);
              if (userInformation.estado) {
                  // Comprobar si el usuario está habilitado
                  resolve(res);
              } else {
                  reject({ error: { code: 403, message: 'Disabled' } });
              }
          });
      }
  }

  setUserData (res): Promise<any> {
      const token: any = this.getDecodedAccessToken(res.token);
      return new Promise((resolve) => {
          this.http.get(environment.urlUnprotected + 'auth/tokenParams/' + token.uuid).subscribe((item: any) => {
              item.username = token.username;
              localStorage.setItem('datos', this.EncrDecr.set(this.privateKey, JSON.stringify(item)));
              localStorage.setItem('course', '');
              localStorage.setItem('scopeName', '');
              localStorage.setItem('auth_item', '');
              resolve(res);
          });
      });
  }

  getUserData (): any {
      if (localStorage.getItem('datos') && localStorage.getItem('datos') !== null) {
          return JSON.parse(this.EncrDecr.get(this.privateKey, localStorage.getItem('datos')));
      }
  }

  logout (): void {
      if (this.isToken()) {
          this.refresh.clearIntervalFunction();
          this.toolbarService.resetNotification();
          const token = this.getDecodedUser();
          localStorage.removeItem('token');
          localStorage.removeItem('course');
          localStorage.removeItem('scopeName');
          localStorage.removeItem('datos');
          localStorage.removeItem('token_session');
          localStorage.removeItem('auth_item');
          localStorage.removeItem('foto');

          this.http.delete(environment.urlImages + 'public/sesion/remove/' + token.uuid).subscribe(_ => {});
      }
      this.router.navigate(['/login']);
  }

  setToken (token): any {
      if (this.isToken()) {
          localStorage.removeItem('token');
          localStorage.setItem('token', token);
          this.user.next(this.getDecodedAccessToken(token));
      } else {
          localStorage.setItem('token', token);
      }

      return of(token);
  }

  isToken (): any {
      if (localStorage.getItem('token') && localStorage.getItem('token') !== null && localStorage.getItem('token') !== '') {
          return localStorage.getItem('token');
      }

      return false;
  }

  getDecodedAccessToken (token: string): void {
      try {
          return jwtDecode(token);
      } catch (Error) {
          return null;
      }
  }

  getDecodedUser (): any {
      try {
          return jwtDecode(this.isToken());
      } catch (Error) {
          return null;
      }
  }

  getAuthAuthorization (): Observable<any> {
      const tokenSession = localStorage.getItem('token_session');
      if (tokenSession) {
          return this.http.get(environment.url + 'auth' + '/' + tokenSession); ;
      } else {
          return of(true);
      }
  }

  getPrivateKey(){
      return this.privateKey;
  }
}
