export const environment = {
    production: false,
    hmr       : false,
    url: 'https://jurispol-backend-pre.servidortemporal.net/api/',
    urlImages: 'https://jurispol-backend-pre.servidortemporal.net/',
    urlUnprotected: 'https://jurispol-backend-pre.servidortemporal.net/',
    temariosUrl: 'https://webdevel.servidortemporal.net:8642'
};


