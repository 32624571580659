import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "quitarHtml",
})
export class QuitarHtmlPipe implements PipeTransform {
    transform(value: any, index: number): string {
        const abc = ["a", "b", "c", "d", "e"];

        const inicio = value.substr(0, 3);

        if (inicio != "<p>") {
            return "<p>" + abc[index] + ") " + value + "</p>";
        } else {
            let response = value.split("<p>");
            return "<p>" + abc[index] + ") " + response;
        }
    }
}
