import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'temaPadre'
})
export class TemaPadrePipe implements PipeTransform {


    transform(value: string, array: any[]): string {
        console.log(value);
        let result = 'PADRE';



        if (value) {
            result = 'hijo';
        } else {
            result = 'padre';
        }


        /*

          if (value) {
            if (array) {
                array.forEach(item => {
                    if (item.id == value) {
                        console.log('entro y el valor es : ' + item.nombre);
                        result = item.nombre;
                    }
                });
            }
        }
         */


        return result;
    }

}
