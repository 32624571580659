import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class ToolbarService {
    private announcement = new Subject<any>();
    private resetNotifications = new Subject<any>();
    $resetNotifications = this.resetNotifications.asObservable();
    public rightNavbar: boolean;
    public hiddenNavbar: boolean;

    constructor () {
        this.rightNavbar = false;
        this.hiddenNavbar = false;
    }

    getRightNavbar (): boolean {
        return this.rightNavbar;
    }

    getHiddenNavbar (): boolean {
        return this.hiddenNavbar;
    }

    sendNotifications (notification): void {
        this.announcement.next(notification);
    }

    getNotifications (): Observable<any> {
        return this.announcement.asObservable();
    }

    resetNotification (): void {
        this.resetNotifications.next();
    }
}
