import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {FuseSharedModule} from '@fuse/shared.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { HtmlComponentsModule } from 'app/main/components/HtmlComponents/html-components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerAlumnoDashboardRoutingModule } from './banner-alumno-dashboard.routing.module';
import { BannerAlumnoDashboardListComponent } from './list/banner-alumno-dashboard-list.component';
import { BannerAlumnoDashboardAddComponent } from './add/banner-alumno-dashboard-add.component';
import {PipesModule} from "../../../../pipes/pipes/pipes.module";
import {DragDropModule} from "../../../components/dragdrop/dragdrop.module";
import {MatTableModule} from "@angular/material/table";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSelectModule} from "@angular/material/select";

@NgModule({
  declarations: [
    BannerAlumnoDashboardListComponent,
    BannerAlumnoDashboardAddComponent
  ],
    imports: [
        CommonModule,
        BannerAlumnoDashboardRoutingModule,
        NgxDatatableModule,
        HtmlComponentsModule,
        MatIconModule,
        PipesModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatRadioModule,
        MatFormFieldModule,
        NgxSpinnerModule,
        MatButtonModule,
        MatInputModule,
        FuseSharedModule,
        DragDropModule,
        MatTableModule,
        MatExpansionModule,
        MatSelectModule
    ]
})
export class BannerAlumnoDashboardModule { }
