import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'checkedResponse'
})
export class CheckedResponsePipe implements PipeTransform {

    transform(value: number): boolean {
        if (value) {
            if (value <= 0) {
                return false;
            } else {
                return true;
            }
        }
    }
}
