import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { FuseSharedModule } from "@fuse/shared.module";
import { ProfileComponent } from "./profile/profile.component";
import { PipesModule } from "../../pipes/pipes/pipes.module";
import { ProfileSidebarComponent } from "./profile-sidebar/profile-sidebar.component";
import { NgxSpinnerModule } from "ngx-spinner";
import {HelpVideoModule} from "../components/help-video/help-video.module";

const routes: Routes = [
    {
        path: "",
        component: LoginComponent,
    },
    {
        path: "profile",
        component: ProfileComponent,
    },
    {
        path: "edit",
        component: ProfileSidebarComponent,
    },
];

@NgModule({
    declarations: [LoginComponent, ProfileComponent, ProfileSidebarComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseSharedModule,
        PipesModule,
        NgxSpinnerModule,
        HelpVideoModule
    ],
    exports: [LoginComponent, ProfileComponent],
})
export class AuthModule {}
