import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../../../../bases-services/base.service';
import {ErrorsHandler} from '../../../../../helpers/ErrorsHandler';
import Swal from 'sweetalert2';
import {DatatableComponent} from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-list-sede',
    templateUrl: './list_sede.component.html',
    styleUrls: ['../../../../scss/tables.scss']
})
export class ListSedeComponent implements OnInit, OnDestroy {

    titulo = 'Listado de sedes';
    subtitulo = 'Consulta el listado';
    loadingIndicator: boolean;
    rows: any[];
    temp: any[];
    columnsWithSearch: string[];
    @ViewChild(DatatableComponent) table: DatatableComponent;
    errors: ErrorsHandler;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private base: BaseService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.loadingIndicator = true;
        this.temp = [];
        this.columnsWithSearch = [];
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.base.listAll('sede').subscribe(item => {
            this.errors = new ErrorsHandler(item.code, item.message, 'get', 'sedes');
            if (!this.errors.setError()) {
                this.rows = item.result;
                this.temp = [...this.rows];
                this.columnsWithSearch = Object.keys(this.rows[0]);
                this.loadingIndicator = false;
            } else {
                Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    gotoAdd(): void {
        this.router.navigate(['add'], {relativeTo: this.route});
    }

    update(id: string): void {
        if (id) {
            this.router.navigate(['edit/' + id], {relativeTo: this.route});
        }
    }

    updateFilter(event, flag?: boolean): void {
        let filter = '';
        if (flag) {
            filter = event.toLowerCase();
        } else {
            filter = event.target.value.toLowerCase();
        }

        this.rows = this.temp.filter(item => {
            for (let i = 0; i < this.columnsWithSearch.length; i++) {
                const colValue = item[this.columnsWithSearch[i]];
                if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
                    return true;
                }
            }
        });

        this.table.offset = 0;
    }
}
