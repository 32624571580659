import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Sede} from '../../../../../models/sede.model';
import {ErrorsHandler} from '../../../../../helpers/ErrorsHandler';
import {Location} from '@angular/common';
import {BaseService} from '../../../../../bases-services/base.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-add-sede',
    templateUrl: './add_sede.component.html',
    styleUrls: ['../../../../scss/forms.scss']
})
export class AddSedeComponent implements OnInit, OnDestroy {
    titulo = 'Crear un sede nueva';
    subtitulo = 'Sede';
    form: FormGroup;
    isEditing: boolean;
    errors = new ErrorsHandler();
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _formBuilder: FormBuilder,
        private base: BaseService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this._unsubscribeAll = new Subject();
        this.isEditing = false;
    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            id: [''],
            nombre: ['', [Validators.required, Validators.maxLength(3), Validators.minLength(3), Validators.pattern('^[a-zA-Z ]*$')]]
        });

        this.route.paramMap.subscribe((params: ParamMap) => {
            const sedeId = params.get('id');
            if (sedeId) {
                this.isEditing = true;
                this.base.list(sedeId, 'sede').subscribe((item: any) => {
                    this.errors = new ErrorsHandler(item.code, item.message, 'get', 'sede');
                    if (!this.errors.setError()) {
                        this.form.patchValue({
                            id: item.result.id,
                            nombre: item.result.nombre
                        });
                    } else {
                        Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    goBack(): void {
        this.location.back();
    }

    onSubmit(form: FormGroup): void {
        if (form.valid) {
            const action = this.isEditing ? 'update' : 'submit';
            const route = this.isEditing ? 'sede/edit/' + form.value.id : 'sede/add';

            this.base.add(form.value, route).subscribe(item => {
                this.errors = new ErrorsHandler(item.code, item.message, action, 'sede');
                if (!this.errors.setError()) {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                    this.goBack();
                } else {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                }
            });
        }
    }

}
