<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
    <div
        class="header accent p-24 h-60"
        fxLayout="row"
        fxLayoutAlign="start center"
    >
        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="h1">{{ titulo }}</div>
        </div>
    </div>

    <div class="content p-24">
        <div class="h2 titleListado">{{ subtitulo }}</div>

        <div class="filter-header">
            <div class="filter-header-input">
                <input
                    type="text"
                    placeholder="Buscar..."
                    (keyup)="updateFilter($event)"
                />
            </div>
            <div class="filter-header-items" *ngIf="isAdministrative">
                <button
                    type="button"
                    color="accent"
                    class="mat-raised-button mat-button-base mat-accent btnJurispol mr-20"
                    (click)="goToAdd()"
                >
                    <span class="mat-button-wrapper">Añadir Tema</span>
                </button>

                <button
                    class="mat-raised-button mat-button-base mat-accent btnJurispol"
                    (click)="changeEnabled()"
                >
                    <span *ngIf="areEnabled">Deshabilitados</span>
                    <span *ngIf="! areEnabled">Habilitados</span>
                </button>
            </div>
        </div>

        <ngx-datatable
            class="material"
            [rows]="rows"
            [loadingIndicator]="loadingIndicator"
            [columnMode]="'force'"
            [headerHeight]="48"
            [footerHeight]="56"
            [rowHeight]="'auto'"
            [reorderable]="true"
            [limit]="10"
            
        >
            <ngx-datatable-column
                name="Área"
                prop="area"
                [width]="200"
                [canAutoResize]="false"
            ></ngx-datatable-column>
            <ngx-datatable-column
                name="Tema"
                prop="tema"
            ></ngx-datatable-column>
            <ngx-datatable-column
                name="Subtema"
                prop="subtema"
            ></ngx-datatable-column>
            <ngx-datatable-column *ngIf="isAdministrative"
                name="Acciones"
                prop="id"
                [width]="120"
                [canAutoResize]="false"
                [sortable]="false"
            >
                <ng-template
                    ngx-datatable-cell-template
                    let-value="value"
                    let-row="row"
                >
                    <div class="action">
                        <div class="action__container">
                            <ul class="action__list">
                                <li class="action__item">
                                    <span
                                        class="action_icon blue-A100-fg"
                                        (click)="goToEdit(value)"
                                    >
                                        <mat-icon>edit</mat-icon>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
