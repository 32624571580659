import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'urlPath'
})
export class UrlPathPipe implements PipeTransform {
    constructor (private sanitizer: DomSanitizer) {}

    transform (value: unknown, path?: string): unknown {
        if (value && path) {
            if (value !== './assets/images/upload-files.png') {
                return this.sanitizer.bypassSecurityTrustResourceUrl(
                    environment.urlImages + 'uploads/' + path + value
                );
            }
        }

        return value;
    }
}
