import { JupolGuard } from './main/auth/guards/jupol.guard';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthModule } from './main/auth/auth.module';
import { MasterModule } from './main/master/master.module';
import { SuperAdminGuard } from './main/auth/guards/superadmin.guard';
import { AuthInterceptor } from './main/auth/interceptor/auth.interceptor';
import { AdminGuard } from './main/auth/guards/admin.guard';
import localeEs from '@angular/common/locales/es';
import { DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { ProfGuard } from './main/auth/guards/prof.guard';
import { TutorGuard } from './main/auth/guards/tutor.guard';
import { SecreSedeGuard } from './main/auth/guards/secre-sede.guard';
import { DirectivesModule } from './directives/directives.module';
import { EncrDecrService } from './bases-services/EncrDecrService';
import { RoleGuard } from './main/auth/guards/role.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getEsPaginatorIntl } from './providers/es-paginator-intl';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';

registerLocaleData(localeEs, 'es');

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./main/main.module').then((m) => m.MainModule)
    },
    {
        path: 'admin',
        canActivate: [AdminGuard],
        canLoad: [AdminGuard],
        loadChildren: () => import('./main/admin/admin.module').then((m) => m.AdminModule)
    },
    {
        path: 'master',
        canActivate: [SuperAdminGuard],
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('./main/master/master.module').then((m) => m.MasterModule)
    },
    {
        path: 'secresede',
        canActivate: [SecreSedeGuard],
        canLoad: [SecreSedeGuard],
        loadChildren: () => import('./main/secre-sede/secre_sede.module').then((m) => m.SecreSedeModule)
    },
    {
        path: 'coord',
        canActivate: [RoleGuard],
        canLoad: [RoleGuard],
        data: { allowedRoles: ['RCR'] },
        loadChildren: () => import('./main/coordinador/coordiandor.module').then((m) => m.CoordiandorModule)
    },
    {
        path: 'tutor',
        canActivate: [TutorGuard],
        canLoad: [TutorGuard],
        loadChildren: () => import('./main/tutor/tutor.module').then((m) => m.TutorModule)
    },
    {
        path: 'prof',
        canActivate: [ProfGuard],
        canLoad: [ProfGuard],
        loadChildren: () => import('./main/profesor/profesor.module').then((m) => m.ProfesorModule)
    },
    {
        path: 'users',
        loadChildren: () => import('./main/alumno/alumno.module').then((m) => m.AlumnoModule)
    },
    {
        path: 'jurispolPro',
        canActivate: [JupolGuard],
        canLoad: [JupolGuard],
        loadChildren: () => import('./main/jupol/jupol.module').then((m) => m.JupolModule)
    },
    {
        path: 'disabled',
        loadChildren: () => import('./main/disabled/disabled.module').then((m) => m.DisabledModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./main/auth/auth.module').then((m) => m.AuthModule)
    }
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            anchorScrolling: 'enabled'
        }),
        FormsModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatTableModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AuthModule,
        MasterModule,
        DirectivesModule,
        ReactiveFormsModule,
        FlexLayoutModule
    ],
    bootstrap: [AppComponent],
    providers: [
        SuperAdminGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        EncrDecrService,
        DatePipe,
        { provide: MatPaginatorIntl, useValue: getEsPaginatorIntl() }
    ]
})
export class AppModule {}
