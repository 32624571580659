import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {


        const val = value.split(':');

        if (val[0].length === 1) {
            val[0] = '0' + val[0];
        }

        if (val[1].length === 1) {
            val[1] = val[1] + '0';
        }


        return val[0] + ':' + val[1];

    }

}
