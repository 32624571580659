<div id="login" class="ps-5 pe-5 pt-5 pb-5 align-self-center">
    <div id="login-form-wrapper" class="">
        <div
            id="login-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
            class="row">
            <div class="col-lg-6 col-12">
                <div class="row">
                    <div class="offset-lg-3 col-lg-7 offset-sm-0 col-sm-12">
                        <div class="logo mt-40 mb-60 d-none d-lg-block">
                            <img [src]="dictionary.iconoLogin" />
                        </div>
                        <div class="logo mt-40 mb-60 d-block d-lg-none">
                            <img [src]="dictionary.iconoLoginMobile" />
                        </div>

                        <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="login(loginForm)" novalidate class="loginForm">

                            <mat-form-field class="w-100" appearance="none" floatLabel="always">
                                <mat-label class="">Correo electrónico</mat-label>
                                <input matInput class="form-control loginCustomInput" formControlName="email" />
                                <mat-error *ngIf="loginForm.get('email').hasError('required')">
                                    El campo Email es obligatorio.
                                </mat-error>
                                <mat-error  *ngIf="
                            !loginForm.get('email').hasError('required') &&
                            loginForm.get('email').hasError('email')">
                                    Por favor, introduce una dirección de email válida.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field  class="w-100 mt-3" appearance="none" floatLabel="always">
                                <mat-label class="">Contraseña</mat-label>
                                <input matInput type="password" formControlName="password" class="form-control loginCustomInput" />
                                <mat-error>
                                    Por favor, introduce una contraseña.
                                </mat-error>
                            </mat-form-field>

                            <div *ngIf="invalidCredentials" class="invalidCredentials">
                                Email o contraseña incorrectos.
                            </div>
                            <div *ngIf="isUserDisabled" class="invalidCredentials">
                                El usuario no está habilitado.
                            </div>


                            <div class="mt-3 pe-0 ps-0">
                                <a class="forgot-password primary-color-blue" [href]="dictionary.recuperaPasswLinkLogin">
                                    {{ dictionary.recuperaPasswTextLogin }}
                                </a>
                            </div>

                            <button mat-raised-button color="accent" aria-label="LOG IN" [disabled]="loginForm.invalid"
                                    class="mat-raised-button btnJurispol submit-button mt-40 w-100
                                    primary-bg-color h-50px fs-2 text-capitalize">
                                <span class="fs-2">Acceder</span>
                            </button>
                        </form>
                    </div>
                </div>

            </div>
            <div class="col-6 mt-60 align-self-center d-none d-lg-block">
                <div class="row">
                    <div class="offset-2 col-8 text-center fs-1">
                        <p>Soñemos juntos</p>
                        <p>Trabajemos juntos</p>
                        <p>Consigámoslo juntos</p>

                        <p class="fw-bold mt-20">
                            <span class="tag-decoration primary-color-blue">#SOMOSJURISPOL</span>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div *ngIf="loader" class="login-loader-container">
    <div class="row">
        <div class="col-3 vh-100"></div>
        <div class="col-6 text-center align-self-center">
            <div class="spinner-border text-info w-100px h-100px" role="status"></div>
        </div>
    </div>
</div>
