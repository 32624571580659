import { Injectable } from '@angular/core';
import { BaseService } from '../../../../bases-services/base.service';
import { FuseNavigationService } from '../../../../../@fuse/components/navigation/navigation.service';
import { AuthService } from '../../../auth/auth.service';

@Injectable({ providedIn: 'root' })

export class MisCursosService {
    // eslint-disable-next-line no-useless-constructor
    data;
    constructor (
        private base: BaseService,
        private auth: AuthService,
        private _fuseNavigationService: FuseNavigationService
    ) {}

    setSelectedCourse (courses: string[], scopeName: string): void {
        const coursesString = courses.toString();
        const userData = this.auth.getUserData();

        localStorage.setItem('scopeName', scopeName);
        localStorage.setItem('course', coursesString);

        this._fuseNavigationService.generateDynamicMenu(userData.id_roles);
    }

    setTelegramLink (telegramLink: string): void {
        localStorage.setItem('telegramLink', telegramLink);
    }

    getTelegramLink (): string {
        return localStorage.getItem('telegramLink');
    }

    getSelectedCourse (): string {
        return localStorage.getItem('scopeName');
    }

    getSelectedCourseId (): string {
        return localStorage.getItem('course');
    }

    setDataStore (data) {
        this.data = data;
        localStorage.setItem('foto', this.data.foto);
    }

    getDataLogo () {
        return localStorage.getItem('foto');
    }
}
