export const imageTypes = (type: string) => {
    const fileTypes = ['image/jpg', 'image/png', 'image/jpeg', 'image/gif', 'image/tiff'];
    return fileTypes.includes(type);
};

export const imageAndDocumentType = (type: string) => {
    const fileTypes = ['image/jpg', 'image/png', 'image/jpeg', 'image/gif', 'image/tiff', 'application/pdf', 'application/docx', 'text/plain'];
    return fileTypes.includes(type);
};

export const allFileTypes = (type: string) => {
    const fileTypes = ['image/jpg', 'image/png', 'image/jpeg', 'image/gif', 'image/tiff', 'application/pdf', 'application/docx', 'text/plain',
        'video/mp4', 'video/mov', 'video/wav', 'video/avi', 'audio/mp3', 'audio/mpeg'];
    return fileTypes.includes(type);
};

export const returnType = (type: string) => {
    const t = type.split('/');
    return t[0];
};
