import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RefreshTokenService {
  clear: any;

  getToken (): void {
      if (localStorage.getItem('token') && localStorage.getItem('token') !== null) {
          this.diffDateTime();
      }
  }

  diffDateTime (): void {
      const token = localStorage.getItem('token');
      fetch(environment.url + 'refresh/token', {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
              Authorization: 'Bearer ' + token
          }
      })
          .then((resp) => resp.json())
          .then((resp) => {
              this.setNewToken(resp);
          });
  }

  setNewToken (token): void {
      localStorage.removeItem('token');
      localStorage.setItem('token', token.token);
  }

  dateInterval (interval: number): void {
      this.clear = setInterval(() => {
          this.getToken();
      }, interval);
  }

  clearIntervalFunction (): void {
      clearInterval(this.clear);
  }
}
