<div id="forms" class="container-fluid ps-5 pe-5">

    <div class="row mt-60 mb-20">
        <div class="col-12" >
            <h2 class="fw-bold fs-1">
                {{titulo}}
            </h2>
        </div>
    </div>

    <div class="row mt-20 mb-20">
        <div class="col-12" >
            <h3 class="fs-2 ps-lg-4 text-secondary">
                {{subtitulo}}
            </h3>
            <hr />
        </div>
    </div>

    <div class="content">
        <!--  Desktop version  -->
        <div class="row mt-20 mb-20 text-center w-100">
            <div class="col-12 overflow-auto" >
                <div class="row">
                    <div class="col-12 mb-20 text-md-end text-center">
                        <button type="submit" color="accent" class="mat-raised-button mat-button-base mat-accent btnJurispol"
                                (click)="goToAdd()">
                            <span class="mat-button-wrapper"> Añadir Publicidad </span>
                        </button>
                    </div>
                </div>
                <table mat-table
                       [dataSource]="rows" multiTemplateDataRows
                       class="mat-elevation-z8 text-center w-100" >

                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef class="text-center fw-bold fs-4 text-dark box-shadow-theme col-4 min-w-150px">
                            Imagen
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-left text-dark fw-bold fs-5 col-4 min-w-150px">
                            <div class="ps-5 text-center"><img [src]="imageFullUrl + element.image" class="h-150px" /> </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="start_date">
                        <th mat-header-cell *matHeaderCellDef class="text-center fw-bold fs-4 text-dark box-shadow-theme col-2 min-w-150px">
                            Fecha inicial
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-left text-dark fw-bold fs-5 col-2 min-w-150px">
                            <div class="text-center ">{{element.start_date | date: 'dd-MM-yyyy HH:mm:ss'}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="end_date">
                        <th mat-header-cell *matHeaderCellDef class="text-center fw-bold fs-4 text-dark box-shadow-theme col-2 min-w-150px">
                            Fecha fin
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-left text-dark fw-bold fs-5 col-2 min-w-150px">
                            <div class="text-center">{{element.end_date | date: 'dd-MM-yyyy HH:mm:ss'}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="url">
                        <th mat-header-cell *matHeaderCellDef class="text-center fw-bold fs-4 text-dark box-shadow-theme col-2 min-w-150px">
                            Url
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-left text-dark fw-bold fs-5 col-2 min-w-150px">
                            <div class="ps-5">{{element.url}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="text-center fw-bold fs-4 text-dark box-shadow-theme col-2 min-w-150px">

                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center text-dark fw-bold fs-5 col-2 min-w-150px">
                            <div class="d-inline-block pointer text-info" (click)="editPublicidad(element.id)" >
                                <mat-icon>edit</mat-icon>
                            </div>
                            <div class="d-inline-block pointer text-danger" (click)="deletePublicidad(element.id)">
                                <mat-icon>delete</mat-icon>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-light-table text-center"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="border-start text-dark" ></tr>
                </table>
            </div>
        </div>
    </div>
</div>
