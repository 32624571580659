import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../main/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    public url = '';
    public history = [];
    events: any;
    values: Subject<any> = new Subject<any>();
    values$ = this.values.asObservable();

    private subject: Subject<any> = new Subject<any>();
    subject$ = this.subject.asObservable();

    // Hay que instanciar HttpClient en el constructor
    constructor (
        private http: HttpClient,
        private router: Router,
        private auth: AuthService
    ) { }

    getDocument (url): Observable<any> {
        return this.http
            .get(`${environment.url}${url}`, {
                responseType: 'blob',
                observe: 'response'
            });
    }

    getZip (url): Observable<any> {
        return this.http
            .get(`${environment.url}${url}`, {
                responseType: 'arraybuffer'
            });
    }

    download (data, tipo): Observable<any> {
        return this.http
            .post(`${environment.url}download/${tipo}`, data, {
                responseType: 'blob',
                observe: 'response'
            });
    }
    downloadUrl (data, url): Observable<any> {
        return this.http
            .post(`${environment.url}download/`+ url, data, {
                responseType: 'blob',
                observe: 'response'
            });
    }

    /**
     * Lista todas los valores paginados
     */
    listPagination (url?: string, page?: any, tema?: string): Observable<any> {
        const p = new HttpParams();
        const params = p
            .set('orderColumn', `${page.orderBy}`)
            .set('orderDir', `${page.orderDir}`)
            .set('pageNumber', `${page.offset}`)
            .set('pageSize', `${page.limit}`)
            .set('tema', tema);

        return this.http.get(environment.url + this.isUrl(url), { params });
    }

    /**
     * @param id
     * Trae un valor , recibe como parametro
     * la id del valor
     */
    listSubject (id: string, url?: string): void {
        this.http
            .get(environment.url + this.isUrl(url) + '/' + id)
            .subscribe((item) => {
                this.values.next(item);
            });
    }

    /**
     *  GET con subscription
     */
    listSubscription (id: string, url?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(environment.url + this.isUrl(url) + '/' + id)
                .subscribe((response: any) => {
                    this.events = response.result;
                    this.subject.next(this.events);
                    resolve(this.events);
                }, reject);
        });
    }

    addArrayFiles (
        val: any,
        file: File,
        url?: string,
        nameFile?: string
    ): Observable<any> {
        return this.http
            .post(environment.url + this.isUrl(url), val)
            .pipe(
                mergeMap((res: any) =>
                    this.updateImage(res, file, this.isUrl(url))
                )
            );
    }

    /**
     * @param sede
     * Añadir una entidad, recibe un porametro tipo entidad
     */
    add (val: any, url?: string, nameFile?: any): Observable<any> {
        return this.http.post(environment.url + this.isUrl(url), val).pipe(
            mergeMap((res: any) =>
                this.updateImage(
                    res,
                    val[this.isNameFile(nameFile)],
                    this.isUrl(url)
                )
            ),
            tap((res) => { })
        );
    }

    /**
     * POST form with files
     */
    formWithFiles (val: any, url?: string): any {
        const token = localStorage.getItem('token');
        return fetch(environment.url + url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: val
        }).then((resp) => resp.json());
    }

    /**
     * PATCH form with files
     */
    formUpdateWithFiles (val: any, url?: string): any {
        const token = localStorage.getItem('token');
        return fetch(environment.url + url, {
            method: 'PATCH',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: val
        }).then((resp) => resp.json());
    }

    /**
     * @param sede
     * Actualizar una entidad , recibe un parametro tipo entidad
     */
    update (val: any, url?: string, nameFile?: string): Observable<any> {
        return this.http
            .put(environment.url + this.isUrl(url), val)
            .pipe(
                mergeMap((res: any) =>
                    this.updateImage(
                        res,
                        val[this.isNameFile(nameFile)],
                        this.isUrl(url)
                    )
                )
            );
    }

    /**
     * Lista todas los valores
     */
    listAll (url?: string): Observable<any> {
        return this.http.get(environment.url + this.isUrl(url));
    }

    /**
     * @param id
     * Trae un valor , recibe como parametro
     * la id del valor
     */
    list (id: string, url?: string, header?: any): Observable<any> {
        return this.http.get(environment.url + this.isUrl(url) + '/' + id, { headers: header });
    }

    /**
     *
     * @param id
     * Borra un valor , recibe como parametro
     * la id del valor
     */
    remove (id: string, url?: string): Observable<any> {
        return this.http.delete(environment.url + this.isUrl(url) + '/' + id);
    }

    /**
     *
     * @param url
     * Comprobamos si la url opcional existe
     */
    isUrl (url?: string): string {
        return url || this.url;
    }

    isNameFile (file): any {
        return file || 'foto';
    }

    public updateImage (form: any, file: File, url: string): any {
        if (!file || typeof file === 'string') {
            let result;
            if (form.id) {
                result = form.id;
            } else {
                result = form.result;
            }
            return of({ code: form.code, message: form.messsage, id: result });
        }

        if (form.id) {
            if (form.id.length > 500) {
                // tslint:disable-next-line:no-shadowed-variable
                this.auth.setToken(form.id); // Actualizar el token diretamente
                const token: any = this.auth.getDecodedAccessToken(form.id);
                form.id = token.uuid;
            }
        }

        const formData = new FormData();
        formData.append('picture', file, file.name);
        formData.append('id', form.id);
        const token = localStorage.getItem('token');
        return fetch(environment.url + url + '/image', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: formData
        })
            .then((resp) => resp.json())

            .catch((e) => console.log('something went wrong: ' + e));
    }

    consume (reader) {
        let total = 0;
        return new Promise((resolve, reject) => {
            function pump () {
                reader
                    .read()
                    .then(({ done, value }) => {
                        if (done) {
                            resolve(true);
                            return;
                        }
                        total += value.byteLength;
                        pump();
                    })
                    .catch(reject);
            }
            pump();
        });
    }

    post (url: string, data?: any, type: object = null): Observable<any> {
        if (type) {
            return this.http.post(environment.url + this.isUrl(url), data, type);
        }
        return this.http.post(environment.url + this.isUrl(url), data);
    }

    patch (url: string, data?: any, options: object = null): Observable<any> {
        if (options) {
            return this.http.patch(environment.url + this.isUrl(url), data, options);
        }
        return this.http.patch(environment.url + this.isUrl(url), data);
    }

    delete (url: string, options: object = null): Observable<any> {
        if (options) {
            return this.http.delete(environment.url + this.isUrl(url), options);
        }
        return this.http.delete(environment.url + this.isUrl(url));
    }

    get (url?: string, options: object = null): Observable<any> {
        if (options) {
            return this.http.get(environment.url + this.isUrl(url), options);
        }
        return this.http.get(environment.url + this.isUrl(url));
    }

    urlAccess (urlIds){
        let menu:any = localStorage.getItem('menu');
        if(menu){
            menu = JSON.parse(menu);
            if(menu.length){
                let urlAcces = false;

                for (const item of menu[0].children){
                    if(urlIds.includes(item.id)){
                        urlAcces = true;
                    }
                    if(item.hasOwnProperty('children')){
                        for (const subItem of item.children){
                            if(urlIds.includes(subItem.id)){
                                urlAcces = true;
                            }
                        }
                    }
                }
                if(!urlAcces){
                    window.location.href="/";
                }
            }else{
                window.location.href="/";
            }
        }
        else{
            window.location.href="/";
        }
    }
}
