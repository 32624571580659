import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../environments/environment';

@Pipe({
    name: 'sanitizeUrl'
})
export class SanitizeUrlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: string, status: boolean, path?): unknown {
        if (value) {
            if (!status) {
                if (!path) {
                    path = '';
                }
                return this.sanitizer.bypassSecurityTrustResourceUrl(environment.urlImages + 'uploads/' + path + value);
            } else {
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            }
        }

    }

}
