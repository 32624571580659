import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'segundosMinutos'
})
export class SegundosMinutoPipe implements PipeTransform {

    transform(value: any, id: number): any {

        const metricas: any = {1: 0, 2: 0, 3: 60, 4: 0, 5: 60, 6: 0, 7: 60};
        const valor = metricas[id];


        if (valor > 0) {

            const minutos = Math.floor(value / valor);
            const segundos = value % valor;

            return minutos + '.' + segundos + ' minutos';

        } else {

            return value + ' segundos';
        }

    }
}
