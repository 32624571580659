import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class AnuncioService {
    private subject = new Subject<any>();

    setAllNotificationsAsRead(status): void {
        this.subject.next(status);
    }

    getNotificationsStatus(): Observable<any> {
        return this.subject.asObservable();
    }
}
