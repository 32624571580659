import {Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'internationalDate'
})
export class InternationalDatePipe extends DatePipe implements PipeTransform {

    transform(value: string, args?: any): string {
        const date = value.replace(/ /g, 'T'); // yyyy-MM-dd HH:mm:ss  => yyyy-MM-ddTHH:mm:ss
        const newDate = super.transform(date, 'dd/MM/yyyy HH:mm:ss');
        return newDate;
    }

}
