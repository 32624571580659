import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {fuseAnimations} from '../../../../@fuse/animations';
import {ErrorsHandler} from '../../../helpers/ErrorsHandler';
import Swal from 'sweetalert2';
import {BaseService} from '../../../bases-services/base.service';
import {AtsCheckboxComponent} from './ats-checkbox/ats-checkbox.component';

@Component({
    selector: 'app-multi-select',
    templateUrl: './selectAreaTemaSubtema.component.html',
    styleUrls: ['../../scss/forms.scss'],
    animations: fuseAnimations,
})
export class SelectAreaTemaSubtemaComponent implements OnInit, OnDestroy {
    areas: any[] = [];
    temas: any[] = [];
    subtemas: any[] = [];
    areasSeleccionadasId: string[] = [];
    temasSeleccionadosId: string[] = [];

    errors = new ErrorsHandler();
    contador: number;
    loop: number[];
    valoresToServer: any[] = [];
    deleteBtn: boolean;
    position: number;
    checkboxInitialValues: any[];

    @ViewChildren('matSelectArea') matSelectArea: QueryList<ElementRef>;
    @ViewChildren('matSelectTema') matSelectTema: QueryList<ElementRef>;
    @ViewChildren('matSelectSubtema') matSelectSubtema: QueryList<ElementRef>;
    @ViewChildren('atsCheckboxComponent') atsCheckboxComponent: QueryList<AtsCheckboxComponent>;
    @Output() valuesEmmiter: EventEmitter<any> = new EventEmitter<any>();
    @Output() positionEmmiter: EventEmitter<any> = new EventEmitter<any>();
    @Output() removeEmmiter: EventEmitter<any> = new EventEmitter<any>();
    @Output() removeCheckbox: EventEmitter<number> = new EventEmitter<number>();

    @Input() repetirAreas: boolean;
    @Input() showAllAreas: boolean;
    @Input() addDeleteOption: boolean;
    @Input() cursoId: number;
    @Input() checkboxValues: any[];
    @Input() blocked: boolean = false;

    constructor(private base: BaseService) {
        this.areas = [];
        this.temas = [];
        this.subtemas = [];
        this.areasSeleccionadasId = [];
        this.temasSeleccionadosId = [];
        this.repetirAreas = false;
        this.showAllAreas = false;
        this.addDeleteOption = true;
        this.checkboxValues = [];
        this.checkboxInitialValues = [];
    }

    ngOnInit(): void {
        this.position = 0;
        this.contador = 1;
        this.loop = [0];
        this.valoresToServer = [''];
        this.deleteBtn = false;
        this.temas[0] = [];
        this.subtemas[0] = [];

        this.getAreas();
    }

    ngOnDestroy(): void {
    }

    executeInit(ats?: string[], checkboxInitialValues?: string[]): void {
        if (checkboxInitialValues) {
            this.checkboxInitialValues = checkboxInitialValues;
        }
        this.areasSeleccionadasId = [];
        this.temasSeleccionadosId = [];
        this.valoresToServer = [];
        this.temas = [];
        this.subtemas = [];

        this.temas[0] = [];
        this.subtemas[0] = [];
        this.getAreas().then(() => {
            if (ats) {
                if (ats.length > 0) {
                    this.loop = [];
                    for (let i = 0; i < ats.length; i++) {
                        this.addOption();
                        this.extractAts(ats[i], i);
                    }
                } else {
                    this.loop = [];
                    this.addOption();
                }
            }
        });
    }

    addValues(ats?: string[]): void {
        const previousLength = this.loop.length;
        const newLength = previousLength + ats.length;
        for (let i = previousLength; i < newLength; i++) {
            this.checkboxInitialValues[i] = [];
            const atsValue = ats[i - previousLength];
            this.addOption();
            this.extractAts(atsValue, i);
        }
    }

    private getAreas(): Promise<any> {
        return new Promise<any>((resolve) => {

            const ruta = this.showAllAreas ? 'area/all' : 'area';

            this.base.listAll(ruta).subscribe((item) => {
                this.errors = new ErrorsHandler(item.code, item.message, 'get', 'áreas');
                if (!this.errors.setError()) {
                    this.areas = [...item.result];
                } else {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                }
                resolve(true);
            });
        });
    }

    getTema(event: any, index: number): void {
        if (this.checkboxValues.length) {
            this.atsCheckboxComponent.toArray()[index].initialLoad(true);
        }

        this.position = index;
        const areaId = event.target.value;

        // Al cambiar de área, reinicio los valores a enviar
        this.setValores('', index);

        // Selecciona los temas asociados
        if (this.repetirAreas) {
            this.getTemaSubtemaList(areaId, index, false);
        } else {
            if (!this.areasSeleccionadasId.includes(areaId)) {
                this.areasSeleccionadasId[index] = areaId;
                this.getTemaSubtemaList(areaId, index, false);
            } else {
                Swal.fire('Atención', 'No puedes seleccionar el mismo área más de una vez.', 'warning');
            }
        }
    }

    getSubtema(event: any, index: number): void {
        this.position = index;
        const temaId = event.target.value ? event.target.value : '';

        this.temasSeleccionadosId[index] = temaId;

        if (this.checkboxValues.length) {
            const disableCheckboxes = temaId === '' || temaId === '-1';
            this.atsCheckboxComponent.toArray()[index].initialLoad(disableCheckboxes);
        }

        if (temaId === '') {
            this.subtemas[index] = [];
        } else {
            this.getTemaSubtemaList(temaId, index, true);
        }

        this.setValores(temaId, index);
    }

    setValorSubtema(event: any, index: number): void {
        const subtemaId = event.target.value;
        if (subtemaId) {
            this.setValores(subtemaId, index);
        } else {
            // Se deselecciona, hay que elegir el tema
            this.setValores(this.temasSeleccionadosId[index], index);
        }
    }

    private setPosition(): void {
        this.positionEmmiter.emit(this.position);
    }

    private setRemove(): void {
        this.removeEmmiter.emit(true);
    }

    private setValores(valor: string, index: number): void {
        this.valoresToServer[index] = valor;
        this.setValuesEmmiter();
        this.setPosition();
    }

    private setValuesEmmiter(): void {
        this.valuesEmmiter.emit(this.valoresToServer.filter((item) => item !== '' && item !== '-1'));
    }

    addOption(): void {
        this.contador = this.loop.length;

        this.temas[this.contador] = [];
        this.subtemas[this.contador] = [];
        this.loop.push(this.contador);

        this.valoresToServer.push('');
        this.deleteBtn = this.contador + 1 > 1;

        setTimeout(() => {
            if (this.checkboxValues.length) {
                this.atsCheckboxComponent.toArray()[this.contador].initialLoad(true);
            }
        }, 300);
    }

    removeLastOption(): void {
        if (this.deleteBtn) {
            this.loop.pop();
            this.valoresToServer.pop();
            this.setValuesEmmiter();
            this.setRemove();

            this.contador = this.loop.length;
            this.deleteBtn = this.contador > 1;

            this.temas.pop();
            this.subtemas.pop();
            this.areasSeleccionadasId.pop();
            this.temasSeleccionadosId.pop();
        }
    }

    deleteOption(index: number): void {
        this.classInUse(this.valoresToServer[index]).then((val) => {
            if (val) {
                this.loop.splice(index, 1);
                this.valoresToServer.splice(index, 1);
                this.setValuesEmmiter();
                this.setRemove();
                this.removeCheckbox.emit(index);
                this.contador = this.loop.length;
                this.deleteBtn = this.contador > 1;
                this.temas.splice(index, 1);
                this.subtemas.splice(index, 1);
                this.areasSeleccionadasId.splice(index, 1);
                this.temasSeleccionadosId.splice(index, 1);
            } else {
                Swal.fire('Atencion', 'Este tema es usado en una clase de este curso, elimine la clase para poder borrar', 'warning');
            }
        });
    }

    private classInUse(id): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.cursoId) {
                const url = this.cursoId + '/' + id;
                this.base.list(url, 'cursos/class/use').subscribe((item) => {
                    if (item) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }

    private extractAts(value: string, index: number): void {
        const areaTemaSubtema = value.split('|');
        this.areasSeleccionadasId[index] = areaTemaSubtema[0];
        this.temasSeleccionadosId[index] = areaTemaSubtema[1];

        // Cargo todos los temas asociados a un área
        this.getTemaSubtemaList(areaTemaSubtema[0], index, false).then(() => {
            this.valoresToServer[index] = areaTemaSubtema[1];
            this.matSelectArea.toArray()[index].nativeElement.value = areaTemaSubtema[0];
            setTimeout(() => {
                this.matSelectTema.toArray()[index].nativeElement.value = areaTemaSubtema[1];
                if (this.checkboxInitialValues.length) {
                    this.atsCheckboxComponent.toArray()[index].initialLoad(false, this.checkboxInitialValues);
                }
            }, 200);

            // Cargo todos los subtemas de este tema
            this.getTemaSubtemaList(areaTemaSubtema[1], index, true).then(() => {
                // Si estaba seleccionado un subtema
                if (areaTemaSubtema[2]) {
                    this.valoresToServer[index] = areaTemaSubtema[2];
                    setTimeout(() => {
                        this.matSelectSubtema.toArray()[index].nativeElement.value = areaTemaSubtema[2];
                    }, 200);
                }
            });
        });
    }

    private getTemaSubtemaList(id: string, index: number, esSubtema: boolean): Promise<any> {
        return new Promise<any>((resolve) => {
            this.base.list(id, 'subunit/father').subscribe((item) => {
                this.errors = new ErrorsHandler(item.code, item.message, 'get', esSubtema ? 'subtema' : 'tema');
                if (!this.errors.setError()) {
                    if (esSubtema) {
                        this.subtemas[index] = [...item.result];
                    } else {
                        this.temas[index] = [...item.result];
                        this.subtemas[index] = [];
                    }
                } else {
                    if (!esSubtema) {
                        this.temas[index] = [];
                    }
                    this.subtemas[index] = [];
                }
                resolve(true);
            });
        });
    }
}
