import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SuperAdminGuard } from '../auth/guards/superadmin.guard';
import { RoleGuard } from '../auth/guards/role.guard';

const routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'sede',
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('./components/sedes/sedes.module').then((m) => m.SedesModule)
    },
    {
        path: 'area',
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('./components/areas/areas.module').then((m) => m.AreaModule)
    },
    {
        path: 'aula',
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('./components/aulas/aulas.module').then((m) => m.AulasModule)
    },
    {
        path: 'tema',
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('./components/temas/temas.module').then((m) => m.TemasModule)
    },
    {
        path: 'tema-psicos',
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('./components/temas-psicos/temas-psicos.module').then((m) => m.TemasPsicosModule)
    },
    {
        path: 'recurso',
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('./components/recurso/recurso.module').then((m) => m.RecursoModule)
    },
    {
        path: 'curso',
        canActivate: [RoleGuard],
        canLoad: [RoleGuard],
        data: { allowedRoles: ['RSP', 'RSM', 'RSS', 'RCR'] },
        loadChildren: () => import('./components/curso/curso.module').then((m) => m.CursoModule)
    },
    {
        path: 'home',
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('./components/home/home.module').then((m) => m.HomeModule)
    },
    {
        path: 'programacion',
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('./components/programacion/programacion.module').then((m) => m.ProgramacionModule)
    },
    {
        path: 'curso-recurso-caso',
        canActivate: [RoleGuard],
        canLoad: [RoleGuard],
        data: { allowedRoles: ['RSP', 'RSM', 'RSS'] },
        loadChildren: () => import('./components/curso-recurso-caso/curso-recurso-caso.module').then((m) => m.CursoRecursoCasoModule)
    },
    {
        path: 'deberes',
        canActivate: [SuperAdminGuard],
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('../components/deberes/deberes.module').then((m) => m.DeberesModule)
    },
    {
        path: 'profile',
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('../auth/auth.module').then((m) => m.AuthModule)
    },
    {
        path: 'convocatoria',
        canLoad: [SuperAdminGuard],
        loadChildren: () => import('./components/gestor-convocatorias/gestor-convocatorias.module').then((m) => m.GestorConvocatoriasModule)
    },
    {
        path: 'calendario',
        canActivate: [SuperAdminGuard],
        loadChildren: () => import('../components/calendario/calendar.module').then((m) => m.CalendarModule)
    },
    {
        path: 'actividad',
        canActivate: [SuperAdminGuard],
        loadChildren: () => import('./components/actividad-alumnos/actividad-alumno.module').then((m) => m.ActividadAlumnoModule)
    },
    {
        path: 'controlHoras',
        canActivate: [SuperAdminGuard],
        loadChildren: () => import('../components/control-horas/control-horas.module').then((m) => m.TeacherHoursCounterModule)
    },
    {
        path: 'test-stats',
        canActivate: [SuperAdminGuard],
        loadChildren: () => import('../components/stats-test/stats-test.module').then((m) => m.StatsTestModule)
    },
    {
        path: 'login-cursos-admin',
        canActivate: [SuperAdminGuard],
        loadChildren: () => import('./components/login-cursos-admin/login-cursos-admin.module').then((m) => m.LoginCursosAdminModule)
    },
    {
        path: 'banner-alumno-dashboard',
        canActivate: [SuperAdminGuard],
        loadChildren: () => import('./components/banner-alumno-dashboard/banner-alumno-dashboard.module').then((m) => m.BannerAlumnoDashboardModule)
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MasterRoutingModule { }
