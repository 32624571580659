import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ErrorsHandler} from '../../../../../helpers/ErrorsHandler';
import {Subject} from 'rxjs';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {BaseService} from '../../../../../bases-services/base.service';
import {Location} from '@angular/common';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-add-area',
    templateUrl: './add_area.component.html',
    styleUrls: ['../../../../scss/forms.scss']
})
export class AddAreaComponent implements OnInit, OnDestroy {

    titulo = 'Crear un área nueva';
    subtitulo = 'Área';
    form: FormGroup;
    tipos: string[];
    isEditing: boolean;
    errors = new ErrorsHandler();
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _formBuilder: FormBuilder,
        private base: BaseService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this._unsubscribeAll = new Subject();
        this.isEditing = false;

        this.tipos = ['Inglés', 'Ortografía', 'Psicotécnico', 'Teoría'];
    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            id: [''],
            nombre: ['', Validators.required],
            respuestas: ['', [Validators.required, Validators.min(0)]],
            tipo: ['', Validators.required],
            principal: [false]
        });

        this.route.paramMap.subscribe((params: ParamMap) => {
            const areaId = params.get('id');
            if (areaId) {
                this.isEditing = true;
                this.base.list(areaId, 'area').subscribe((item: any) => {
                    this.errors = new ErrorsHandler(item.code, item.message, 'get', 'area');
                    if (!this.errors.setError()) {
                        this.form.patchValue({
                            id: item.result.id,
                            nombre: item.result.nombre,
                            respuestas: item.result.n_respuestas,
                            tipo: item.result.tipo,
                            principal: item.result.principal === '1'
                        });
                    } else {
                        Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    goBack(): void {
        const ruta = this.isEditing ? '../..' : '..';
        this.router.navigate([ruta], {relativeTo: this.route});
    }

    onSubmit(form: FormGroup): void {
        if (form.valid) {
            const action = this.isEditing ? 'update' : 'submit';
            const route = this.isEditing ? 'area/edit/' + form.value.id : 'area/add';

            this.base.add(form.value, route).subscribe(item => {
                this.errors = new ErrorsHandler(item.code, item.message, action, 'área');
                if (!this.errors.setError()) {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                    this.goBack();
                } else {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                }
            });
        }
    }
}
