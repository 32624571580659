import { DragDropService } from '../dragdrop.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-files-container',
    templateUrl: './files-container.component.html',
    styleUrls: ['../dragdrop.component.scss']
})
export class FilesContainerComponent {
  @Input() archivos: any[] = [];
  @Input() filesFromServer: string[] = [];
  @Output() abrirExploradorEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clearAllEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor (private dragService: DragDropService) { }

  limpiarArchivos (): void {
      Swal.fire({
          title: '¡Atención!',
          text: '¿Está seguro de querer borrarlo?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, borrar',
          cancelButtonText: 'Cancelar'
      }).then((result) => {
          if (result.value) {
              this.clearAllEmitter.emit(true);
          }
      });
  }

  limpiarArchivosSubidosSinAlert (name: string): void {
      this.dragService.removeFile(name);
  }

  abrirExplorador (): void {
      this.abrirExploradorEmitter.emit(true);
  }

  clearFile (name: string, fromServer: boolean): void {
      if (name) {
          Swal.fire({
              title: '¡Atención!',
              text: '¿Está seguro de querer borrarlo?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sí, borrar',
              cancelButtonText: 'Cancelar'
          }).then((result) => {
              if (result.value) {
                  if (fromServer) {
                      this.dragService.removeServerFile(name);
                  } else {
                      this.dragService.removeFile(name);
                  }
              }
          });
      }
  }

  typeString (val: any): boolean {
      return typeof val !== 'string';
  }
}
