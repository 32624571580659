import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {LoginCursosAdminListComponent} from './list/login-cursos-admin-list.component';
import {loginCursosAdminAddComponent} from './add/login-cursos-admin-add.component';

const routes: Routes = [
    {
        path: '',
        component: LoginCursosAdminListComponent
    },
    {
        path: 'add',
        component: loginCursosAdminAddComponent
    },
    {
        path: 'edit/:id',
        component: loginCursosAdminAddComponent
    },
   ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginCursosAdminRoutingModule {
}