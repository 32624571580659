import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { NavigationStart, Router } from "@angular/router";
import { NgModel } from "@angular/forms";
import { BaseService } from "../../../app/bases-services/base.service";

@Component({
    selector: "fuse-search-bar",
    templateUrl: "./search-bar.component.html",
    styleUrls: ["./search-bar.component.scss"],
})
export class FuseSearchBarComponent
    implements OnInit, OnDestroy, AfterViewInit {
    collapsed: boolean;
    fuseConfig: any;
    url: string;
    enabled: boolean;
    text: "";
    @ViewChild("searchBox", { static: false }) searchBox: NgModel;
    values: Subject<any> = new Subject<any>();
    public values$ = this.values.asObservable();
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private base: BaseService
    ) {
        router.events.subscribe((e: NavigationStart) => {
            if (e instanceof NavigationStart) {
                if (e.url === "/master/user/list-old") {
                    this.enabled = true;
                    this.url = "user/query";
                } else if (e.url === "/master/pregunta/list-old") {
                    this.enabled = true;
                    this.url = "pregunta/find/nopage";
                } else if (e.url === "/master/pregunta/actualizar") {
                    this.enabled = true;
                    this.url = "pregunta/respuestas/find";
                } else if (e.url === "/master/tema/list-old") {
                    this.url = "tema/find";
                    this.enabled = true;
                } else {
                    this.enabled = false;
                }
            }
        });

        this.collapsed = true;
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit(): void {
        this.searchBox.valueChanges
            .pipe(debounceTime(300))
            .subscribe((item) => {
                if (this.enabled) {
                    if (item === "") {
                        item = null;
                    }
                    this.base.listSubject(item, this.url);
                }
            });
    }

    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    collapse(): void {
        this.collapsed = true;
        this.base.listSubject(null, this.url);
    }

    /**
     * Expand
     */
    expand(): void {
        this.collapsed = false;
    }
}
