import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'abc',
})
export class ABCPipe implements PipeTransform {
    transform(value: any, index: number): string {
        const abc = ['a', 'b', 'c', 'd', 'e'];
        const htmlValue = value.startsWith('<span>') ? value : ('<span>' + value + '</span>');
        return '<span class="me-1">' + abc[index] + ')</span>' + htmlValue;
    }
}
