import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadCrums } from './../../../../../helpers/breadcrums';
import { BaseService } from '../../../../../bases-services/base.service';
import { ErrorsHandler } from '../../../../../helpers/ErrorsHandler';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { Global } from '../../../../../global';

@Component({
    selector: 'banner-alumno-dashboard',
    templateUrl: './banner-alumno-dashboard-list.component.html',
    styleUrls: ['./banner-alumno-dashboard-list.component.scss']
})
export class BannerAlumnoDashboardListComponent implements OnInit, OnDestroy {
    titulo = 'Banners en dashboard de alumnos';
    subtitulo = 'Listado de banners en dashboard de alumnos';
    breadcrums: BreadCrums;
    errors;
    rows;
    temp;
    columnsWithSearch;
    displayedColumns = ['image', 'start_date', 'end_date', 'url', 'option_type', 'option_value', 'actions'];
    imageFullUrl = environment.urlImages + 'uploads/info_alumno/';
    global = Global;

    constructor (private base: BaseService,
                private router: Router,
                private route: ActivatedRoute) {
        this.breadcrums = new BreadCrums(router);
        this.breadcrums.setLocalRoutes();
    }

    ngOnInit (): void {
        const site = this.global.domain === 'jurispol_pro' ? 'jurispol_pro' : 'jurispol';
        this.base.listAll('publicidad/alumno/list/' + site).subscribe(item => {
            this.errors = new ErrorsHandler(item.code, item.message, 'get', 'publicidad');
            if (!this.errors.setError()) {
                this.rows = item.result;
                this.temp = [...this.rows];
                if (this.rows.length > 0) {
                    this.columnsWithSearch = Object.keys(this.rows[0]);
                }
            } else {
                Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
            }
        });
    }

    ngOnDestroy (): void {
    }

    goToAdd (): void {
        let url = '../add';
        if (this.breadcrums.isRouterActive(this.router)) {
            url = '../' + url;
        }
        this.router.navigate([url], { relativeTo: this.route });
    }

    editPublicidad (id) {
        let url = '../edit';
        if (this.breadcrums.isRouterActive(this.router)) {
            url = '../' + url;
        }
        this.router.navigate([url, id], { relativeTo: this.route });
    }

    deletePublicidad (val) {
        if (val) {
            Swal.fire({
                title: 'Atención',
                text: '¿Está seguro de querer borrarlo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, borrar'
            }).then((result) => {
                if (result.value) {
                    this.base.remove(val, 'publicidad/alumno').subscribe(item => {
                        this.errors = new ErrorsHandler(item.code, item.message, 'delete', 'publicidad');
                        if (!this.errors.setError()) {
                            this.temp = this.temp.filter((i: any) => i.id !== val);
                            this.rows = this.rows.filter((i: any) => i.id !== val);
                        } else {
                            Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                        }
                    });
                }
            });
        }
    }
}
