<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>



        <div fxLayout="row" fxLayoutAlign="end center" fxHide fxShow.gt-xs>
            <a mat-button>Jurispol</a>
        </div>

    </div>
</mat-toolbar>
