import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ErrorsHandler } from '../../../../../helpers/ErrorsHandler';
import { BaseService } from '../../../../../bases-services/base.service';
import { Area } from '../../../../../models/area.model';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-add-tema',
    templateUrl: './add_tema.component.html',
    styleUrls: ['../../../../scss/forms.scss']
})
export class AddTemaComponent implements OnInit {
    titulo = 'Añadir tema';
    subtitulo = 'Tema';
    form: FormGroup;
    errors: ErrorsHandler;
    unitId: string;
    areas: Area[];
    temas: any[];
    saving = false;

    categorias: any = [
        { id: 'ninguno', name: 'Ninguno' },
        { id: 'juridico', name: 'Juridico' },
        { id: 'social', name: 'Social' },
        { id: 'tecnico', name: 'Técnico' },
        { id: 'casos', name: 'Casos Prácticos' },
        { id: 'varios', name: 'Varios' }
    ]

    constructor (
        private _formBuilder: FormBuilder,
        private base: BaseService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.areas = [];
        this.temas = [];

        this.form = this._formBuilder.group({
            area: ['', Validators.required],
            tema: [''],
            category: [''],
            nombre: ['', Validators.required],
            orden: [1, [Validators.required, Validators.min(0)]],
            estado: [false],
            documento: [false]
        });
    }

    ngOnInit (): void {
        this.base.listAll('area').subscribe((item) => {
            this.areas = item.result;
        });

        this.route.paramMap.subscribe((params: ParamMap) => {
            this.unitId = params.get('id');
            if (this.unitId) {
                this.subtitulo = 'Editar tema';
                this.startEdition();
            }
        });
    }

    private startEdition (): void {
        if (this.unitId) {
            this.base.list(this.unitId, 'units/details').subscribe((item) => {
                this.errors = new ErrorsHandler(
                    item.code,
                    item.message,
                    'get',
                    'detalles tema'
                );
                if (!this.errors.setError()) {
                    const unit = item.result;

                    this.getTemas({ value: unit.area });
                    this.form.patchValue({
                        area: unit.area,
                        tema: unit.tema,
                        category: unit.category,
                        nombre: unit.nombre,
                        orden: parseInt(unit.orden),
                        estado: unit.estado === '1',
                        documento: unit.documento === '1'
                    });
                } else {
                    Swal.fire(
                        this.errors.object.title,
                        this.errors.object.text,
                        this.errors.object.icon
                    );
                    this.goBack();
                }
            });
        } else {
            Swal.fire('Error', 'Se ha accedido a una ruta errónea.', 'error');
            this.goBack();
        }
    }

    onSubmit (form: FormGroup): void {
        if (this.saving) {
            return;
        }
        if (form.valid) {
            this.saving = true;
            const action = this.unitId != null ? 'update' : 'submit';
            const ruta =
                this.unitId != null ? 'units/edit/' + this.unitId : 'units/add';
            // Enviar formulario
            this.base.add(form.value, ruta).subscribe((item) => {
                this.errors = new ErrorsHandler(
                    item.code,
                    item.message,
                    action,
                    'tema'
                );
                if (!this.errors.setError()) {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                    this.goBack();
                } else {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                }
                this.saving = false;
            });
        }
    }

    getTemas (event: any): void {
        this.base.list(event.value, 'subunit/father').subscribe((item) => {
            this.errors = new ErrorsHandler(item.code, item.message, 'getAll', 'temas');
            if (!this.errors.setError()) {
                this.temas = item.result;
            } else {
                Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
            }
        });
    }

    goBack (): void {
        const ruta = this.unitId == null ? '..' : '../..';
        this.router.navigate([ruta], { relativeTo: this.route });
    }
}
