import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'respuestaCorrecta'
})
export class RespuestaCorrectaPipe implements PipeTransform {

    transform(marcada: string, correcta: string): string {
        let respuesta = ' Fallada';
        if(marcada === correcta) {
            respuesta = ' Acertada';
        }
        return respuesta;
    }
}
