<div *ngIf="url" class="d-flex align-items-center">
    <button class="btn-jurispol min-w-150px d-flex justify-content-center p-2" type="button" (click)="openModal()">
        <mat-icon>ondemand_video</mat-icon> <span class="ps-2 pt-2">Visita guiada</span>
    </button>
</div>

<jw-modal [id]="modalId" (click)="closeModal()">
    <div class="d-flex flex-column w-100 h-85vh">
        <iframe *ngIf="modalUrl" [src]="modalUrl" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        <div class="d-flex justify-content-center">
            <button (click)="closeModal()" class="mat-raised-button mat-button-base mat-accent btnJurispolCancelar mt-3">Cerrar</button>
        </div>
    </div>
</jw-modal>
