import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ListCursoRecursoCasoComponent} from './list/list_curso-recurso-caso.component';
import {AddCursoRecursoCasoComponent} from './add/add_curso-recurso-caso.component';

const routes: Routes = [
    {
        path: 'list/:cursoId',
        component: ListCursoRecursoCasoComponent
    },
    {
        path: 'list/:cursoId/add',
        component: AddCursoRecursoCasoComponent
    },
    {
        path: 'list/:cursoId/edit/:id',
        component: AddCursoRecursoCasoComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CursoRecursoCasoRoutingModule {}
