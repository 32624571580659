<div class="multi__container" style="display: flex; flex-direction: column;">

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 1 auto" >
        <button *ngIf="addDeleteOption && deleteBtn && !blocked"
                (click)="removeLastOption()"
                aria-label="Eliminar elemento"
                [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                type="button">
            <mat-icon>remove</mat-icon>
        </button>

        <button class="add-line-button" *ngIf="addDeleteOption && !blocked"
                (click)="addOption()"
                aria-label="Añadir elemento"
                [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                type="button">
            <mat-icon>add</mat-icon>
        </button>

        <mat-label>Añadir área / tema / subtema</mat-label>
    </div>

    <ng-container *ngFor="let _ of loop; let i = index">
        <div style="width: 100%" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxFlex="1 1 auto">

            <div appearance="outline" fxFlex="33" class="pr-4">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                    <select matNativeControl placeholder="Selecciona área" #matSelectArea [disabled]="blocked"
                                (change)="getTema($event, i)">
                        <option [value]='-1'>Selecciona área</option>
                        <option *ngFor="let area of areas" [value]="area.id">{{area.nombre}}</option>
                    </select>
                </mat-form-field>
            </div>

            <div appearance="outline" fxFlex="33" class="pr-4">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4" 
                *ngIf="temas[i]">
                    <select matNativeControl placeholder="Selecciona tema" #matSelectTema
                                [disabled]="temas[i].length == 0 || blocked"
                                (change)="getSubtema($event, i)">
                        <option [value]='-1'>Selecciona tema</option>
                        <option *ngFor="let tema of temas[i]" [value]="tema.id">{{tema.nombre}}</option>
                    </select>
                </mat-form-field>
            </div>

            <div appearance="outline" fxFlex="33" class="pr-4">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                 *ngIf="subtemas[i]">
                    <select matNativeControl placeholder="Selecciona subtema" #matSelectSubtema
                                [disabled]="subtemas[i].length == 0 || blocked"
                                (change)="setValorSubtema($event, i)">
                        <option [value]='-1'>Selecciona subtema</option>
                        <option *ngFor="let subtema of subtemas[i]" [value]="subtema.id">{{subtema.nombre}}</option>
                    </select>
                </mat-form-field>
            </div>

            <button *ngIf="(addDeleteOption && deleteBtn) && !blocked"
                    (click)="deleteOption(i)"
                    aria-label="Eliminar elemento"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                    type="button">
                <mat-icon>remove</mat-icon>
            </button>

        </div>

        <app-ats-checkbox *ngIf="checkboxValues.length" [checkboxValues]="checkboxValues" [position]="i" #atsCheckboxComponent></app-ats-checkbox>

    </ng-container>
</div>
