import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MultiCheckboxComponent } from '../multi-checkbox/multi-checkbox.component';
import { BaseService } from '../../../../bases-services/base.service';

@Component({
    selector: 'app-multicheck',
    templateUrl: './multicheck.component.html',
    styleUrls: ['./multicheck.component.scss']
})
/*
this component provides checks for the menu, and you can apply it on forms
Includes upload component for diplomas pdf
 */
export class MultiCheckComponent implements OnInit, OnDestroy {
    private _curso:any;
    private _forceLoad:number = 0;
    public dateDiploma;
    public diplomaFilesToServer;
    public diplomaFilesFromServer = [];
    public documentationFilesToServer;
    public documentationFilesFromServer = [];
    public errors;
    public convocatoriaId;
    public checkDiplomaValue;
    public selectedTest;
    public menu;

    @Input() allowAllChecks = true;
    @Input() hasConvocatoria: boolean = false;
    @Input() allowSelect: boolean = true;
    @Input() cursoId: string;
    @Input() form: any;
    @Input() selectTest = [];
    @Input() blocked = false;

    // when curso is update on parent component
    // save the variable and execute init
    @Input()
    public set curso (value: any) {
        this._curso = value;
        if (value !== null) {
            this.init();
        }
    }

    // this is for force the init ,
    // sometimes angular doesnt detect the changes and we need to force it
    @Input()
    public set forceLoad (value: any) {
        this._forceLoad = value;
        if (value !== null) {
            this.init();
        }
    }

    getCurso (): any {
        return this._curso;
    }

    @Output() valueChange = new EventEmitter<any>();
    @Output() diplomaFilesServerTo = new EventEmitter<any>();
    @Output() diplomaFilesServerFrom = new EventEmitter<any>();
    @Output() documentationFilesServerTo = new EventEmitter<any>();
    @Output() documentationFilesServerFrom = new EventEmitter<any>();
    @Output() changeDiplomaDate = new EventEmitter<any>();
    @Output() selectTestChange = new EventEmitter<any>();

    // this loads child componeds for checks they have their own functions
    @ViewChild('menuMultiCheck') menuMultiCheck: MultiCheckboxComponent;
    @ViewChild('testMultiCheck') testMultiCheck: MultiCheckboxComponent;
    @ViewChild('programacionMultiCheck') materialApoyoMultiCheck: MultiCheckboxComponent;
    @ViewChild('claseMultiCheck') claseMultiCheck: MultiCheckboxComponent;
    @ViewChild('documentationMultiCheck') documentationMultiCheck: MultiCheckboxComponent;
    @ViewChild('checkDiploma') checkDiploma: MultiCheckboxComponent;

    constructor (
        private router: Router,
        private base: BaseService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit (): void {
    }

    /* this functions loads all the variables and the form ,
       is the core of component, every change happen by the user can execute this */
    init (): void {
        const curso = this.getCurso();
        if (!curso) {
            return;
        }
        this.menu = curso.menu;
        const test = curso.test_opcion;
        const materialApoyo = curso.material_apoyo;
        const clase = curso.clase;
        this.checkDiplomaValue = curso.checkDiploma;
        this.convocatoriaId = curso.convocatoria;
        if (this.allowAllChecks) {
            this.hasConvocatoria = this.convocatoriaId != null;
        }

        if (curso.diplomaDate) {
            this.dateDiploma = curso.diplomaDate;
        }

        if (curso.documentation) {
            this.documentationFilesFromServer = curso.documentation;
            this.documentationFilesServerFrom.emit(this.documentationFilesFromServer);
        }

        if (curso.urlPDF) {
            this.diplomaFilesFromServer = [curso.urlPDF];
            this.diplomaFilesServerFrom.emit(this.diplomaFilesFromServer);
        }

        if (curso.testId) {
            this.selectedTest = curso.testId;
            this.form.patchValue({
                testId: this.selectedTest
            });
        }

        this.menuMultiCheck.valoresIniciales(this.menu, this.blocked?this.blocked:this.hasConvocatoria, this.blocked?this.blocked:this.allowAllChecks);

        if (this.allowAllChecks) {
            if (this.menu) {
                this.menuMultiCheck.valoresIniciales(this.menu,this.blocked,this.blocked);
                this.form.patchValue({
                    menu: this.menu
                });
            }
            if (test) {
                this.testMultiCheck.valoresIniciales(test,this.blocked,this.blocked);
                this.form.patchValue({
                    testOpcion: test
                });
            }

            if (materialApoyo) {
                this.materialApoyoMultiCheck.valoresIniciales(materialApoyo,this.blocked,this.blocked);
                this.form.patchValue({
                    materialApoyo: materialApoyo
                });
            }
            if (clase) {
                this.claseMultiCheck.valoresIniciales(clase,this.blocked,this.blocked);
                this.form.patchValue({
                    clase: clase
                });
            }

            if (this.hasConvocatoria) {
                this.checkDiploma.valoresIniciales([], true,this.blocked);
            } else {
                this.checkDiplomaValue = this.checkDiplomaValue ?? [];
                this.checkDiploma.valoresIniciales(this.checkDiplomaValue,this.blocked,this.blocked);
                this.form.patchValue({
                    checkDiploma: this.checkDiplomaValue
                });
            }
        }

        this.form.patchValue({
            menu: this.menu,
            diplomaDate: this.dateDiploma
        });

        this.changeDiplomaDate.emit(this.dateDiploma);
    }

    ngOnDestroy (): void {
    }

    menuFill(event){
            this.menuMultiCheck.valoresIniciales(this.menu, this.blocked?this.blocked:this.hasConvocatoria, this.blocked?this.blocked:this.allowAllChecks);
    }

    // this functions get the values of check child components
    getFromMulticheck (event: any[]): void {
        const valores = event[0];
        const tipo = event[1];

        if (tipo) {
            switch (tipo) {
            case 'menu':
                this.form.patchValue({
                    menu: valores
                });
                break;
            case 'testOpcion':
                this.form.patchValue({
                    testOpcion: valores
                });
                break;
            case 'materialApoyo':
                this.form.patchValue({
                    materialApoyo: valores
                });
                break;
            case 'clase':
                this.form.patchValue({
                    clase: valores
                });
                break;
            case 'checkDiploma':
                this.form.patchValue({
                    checkDiploma: valores
                });
                break;
            }
        }
    }

    // this events functions return values to parent component
    setFileFromComputer (type: string, event): void {
        switch (type) {
        case 'documentation':
            this.documentationFilesToServer = event;
            this.documentationFilesServerTo.emit(this.documentationFilesToServer);
            break;
        case 'diploma':
            this.diplomaFilesToServer = event;
            this.diplomaFilesServerTo.emit(this.diplomaFilesToServer);
            break;
        }
    }

    setFileFromServer (type: string, event): void {
        switch (type) {
        case 'documentation':
            this.documentationFilesFromServer = event;
            this.documentationFilesServerFrom.emit(this.documentationFilesFromServer);
            break;
        case 'diploma':
            this.diplomaFilesFromServer = event;
            this.diplomaFilesServerFrom.emit(this.diplomaFilesFromServer);
            break;
        }
    }

    saveDiplomaDate () {
        this.form.patchValue({
            diplomaDate: this.dateDiploma
        });
        this.changeDiplomaDate.emit(this.dateDiploma);
    }

    updateValue (value): void {
        this.form = value;
        this.valueChange.emit(value);
    }

    changeTestId () {
        this.form.patchValue({
            testId: this.selectedTest
        });
        this.selectTestChange.emit(this.selectedTest);
    }
}
