import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { BaseService } from "../../../../../bases-services/base.service";
import Swal from "sweetalert2";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ErrorsHandler } from "../../../../../helpers/ErrorsHandler";
import { Subject } from "rxjs";
import { ModalService } from "../../../../components/modal/modal.service";
import { AddCursoRecursoCasoComponent } from "../add/add_curso-recurso-caso.component";

@Component({
  selector: "list-curso-recurso-caso",
  templateUrl: "./list_curso-recurso-caso.component.html",
  styleUrls: ["../../../../scss/forms.scss"],
})
export class ListCursoRecursoCasoComponent implements OnInit, OnDestroy {
  titulo = "Asignar casos (recursos) a curso";
  subtitulo = "Casos asignados al curso";
  loadingIndicator: boolean;
  rows: any[];
  temp: any[];
  columnsWithSearch: string[];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild(AddCursoRecursoCasoComponent) addCursoRecursoComponent: AddCursoRecursoCasoComponent;
  modalId: string;
  errors: ErrorsHandler;

  private _unsubscribeAll: Subject<any>;

  constructor(private base: BaseService, private route: ActivatedRoute, private modalService: ModalService) {
    this.loadingIndicator = true;
    this._unsubscribeAll = new Subject();

    this.modalId = "modal-curso-recurso-caso";
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const cursoId = params.get("cursoId");
      if (cursoId) {
        this.getCasos(cursoId);
      }
    });
  }

  getCasos(cursoId): void {
    this.base.list(cursoId, "recursos/caso/list").subscribe((item) => {
      this.errors = new ErrorsHandler(item.code, item.message, "get", "casos del curso");
      if (!this.errors.setError()) {
        this.rows = item.result;
        this.temp = [...this.rows];
        if (this.rows.length > 0) {
          this.columnsWithSearch = Object.keys(this.rows[0]);
        }
        this.loadingIndicator = false;
      } else {
        Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  goToAdd(): void {
    this.modalService.open(this.modalId);
    setTimeout(() => {
      this.addCursoRecursoComponent.setEditModalInformation(this.modalId);
    },200)

  
    
  }

  goToEdit(id: string, row: any): void {
    const rowIndex = this.rows.indexOf(row);
    this.addCursoRecursoComponent.setEditModalInformation(this.modalId, id, rowIndex);
    this.modalService.open(this.modalId);
  }

  actualizarFilaCaso(casoToCurso: any): void {
    this.rows[casoToCurso.rowIndex].presentation_start = casoToCurso.presentation_start;
    this.rows[casoToCurso.rowIndex].presentation_end = casoToCurso.presentation_end;
    this.rows[casoToCurso.rowIndex].resolution_start = casoToCurso.resolution_start;
    this.rows[casoToCurso.rowIndex].resolution_end = casoToCurso.resolution_end;

    this.rows = [...this.rows]; // Detectar cambios para ngx-datatable
  }

  delete(id: string): void {
    if (id) {
      Swal.fire({
        title: "Atención!",
        text: "¿Está seguro de querer borrarlo?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, borrar.",
      }).then((result) => {
        if (result.value) {
          this.base.remove(id, "recurso/caso/delete").subscribe((item) => {
            this.errors = new ErrorsHandler(item.code, item.message, "delete", "caso del curso");
            if (!this.errors.setError()) {
              this.temp = this.temp.filter((i: any) => i.id !== id);
              this.rows = this.rows.filter((i: any) => i.id !== id);
            } else {
              Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
            }
          });
        }
      });
    }
  }

  updateFilter(event, flag?: boolean): void {
    let filter = "";
    if (flag) {
      filter = event.toLowerCase();
    } else {
      filter = event.target.value.toLowerCase();
    }

    this.rows = this.temp.filter((item) => {
      for (let i = 0; i < this.columnsWithSearch.length; i++) {
        const colValue = item[this.columnsWithSearch[i]];
        if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
          return true;
        }
      }
    });

    this.table.offset = 0;
  }
}
