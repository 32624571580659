import { ListSedeComponent } from './components/sedes/list/list_sede.component';
import { TemasModule } from './components/temas/temas.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddSedeComponent } from './components/sedes/add/add_sede.component';
import { AddAreaComponent } from './components/areas/add/add_area.component';
import { ListAreaComponent } from './components/areas/list/list_area.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PipesModule } from '../../pipes/pipes/pipes.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { AddAulaComponent } from './components/aulas/add/add_aula.component';
import { ListAulaComponent } from './components/aulas/list/list_aula.component';
import { MasterRoutingModule } from './master.routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CursoRecursoCasoModule } from './components/curso-recurso-caso/curso-recurso-caso.module';
import { LoginCursosAdminModule } from './components/login-cursos-admin/login-cursos-admin.module';
import { BannerAlumnoDashboardModule } from './components/banner-alumno-dashboard/banner-alumno-dashboard.module';

@NgModule({
    declarations: [
        AddSedeComponent,
        ListSedeComponent,
        AddAreaComponent,
        ListAreaComponent,
        AddAulaComponent,
        ListAulaComponent
    ],
    imports: [
        CommonModule,
        MasterRoutingModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        ReactiveFormsModule,
        FuseSharedModule,
        NgxDatatableModule,
        MatCheckboxModule,
        MatDatepickerModule,
        PipesModule,
        MatToolbarModule,
        MatExpansionModule,
        MatListModule,
        FormsModule,
        MatCardModule,
        NgxSpinnerModule,
        TemasModule,
        CursoRecursoCasoModule,
        LoginCursosAdminModule,
        BannerAlumnoDashboardModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: []
})
export class MasterModule {}
