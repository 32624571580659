import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SelectAreaTemaSubtemaServiceService {
    checkbox: Subject<any> = new Subject<any>();
    checkbox$ = this.checkbox.asObservable();

    constructor() {
    }

    emitCheckboxChange(val: any, position: number): void {
        this.checkbox.next({ val, position });
    }

}
