
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DniMatchingDirective} from './dni-matching.directive';
import {PasswordMatchingDirective} from './password-matching.directive';
import {PhoneMatchingDirective} from './phone-matching.directive';
import {NoRightClickDirective} from "./no-right-click.directive";







@NgModule({
    declarations: [
        DniMatchingDirective,
        PasswordMatchingDirective,
        PhoneMatchingDirective,
        NoRightClickDirective
     

    ],
    imports: [
        CommonModule
    ],
    exports:
        [
            DniMatchingDirective,
            PasswordMatchingDirective,
            PhoneMatchingDirective,
            NoRightClickDirective,
        

        ]
})
export class DirectivesModule {
}
