import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseSidebarModule} from '@fuse/components';
import {PipesModule} from '../../../pipes/pipes/pipes.module';
import {MultiSelectComponent} from './multi-select/multi-select.component';
import {SelectBarComponent} from './selectBar/select-bar.component';
import {MultiCheckboxComponent} from './multi-checkbox/multi-checkbox.component';
import {MultiCheckComponent} from './multicheck/multicheck.component';
import {SelectAreaTemaSubtemaModule} from "../selectAreaTemaSubtema/selectAreaTemaSubtema.module";
import {DragDropModule} from "../dragdrop/dragdrop.module";
@NgModule({
    declarations: [
        MultiSelectComponent,
        SelectBarComponent,
        MultiCheckboxComponent,
        MultiCheckComponent
    ],
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSelectModule,
        MatToolbarModule,
        TranslateModule,
        FuseSharedModule,
        FuseSidebarModule,
        PipesModule,
        SelectAreaTemaSubtemaModule,
        DragDropModule
    ],
    exports: [
        MultiSelectComponent,
        SelectBarComponent,
        MultiCheckboxComponent,
        MultiCheckComponent
    ],

    entryComponents: []
})
export class HtmlComponentsModule {
}
