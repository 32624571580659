<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
  <div class="header accent p-24 h-60" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="center start">
      <div class="h1">{{ titulo }}</div>
    </div>
  </div>

  <div class="p-24">
    <div class="mb-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-md="row">
      <form class="mat-elevation-z4" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <div class="h2 mb-24">{{ subtitulo }}</div>
        <div class="ps-4 pe-4">
          <div class="row">
            <div class="col-sm-4">
              <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                <mat-label class="mb-24">Añadir URL</mat-label>
                <input matInput type="text" formControlName="url" required />
                <mat-icon matSuffix class="secondary-text">link</mat-icon>
                <mat-error>El url es un campo obligatorio.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-4">
              <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label class="mb-24">Fecha Inicio</mat-label>
                <input matInput type="date" formControlName="start_date" required/>
                <mat-error>La fecha es un campo obligatorio.</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label class="mb-24">Hora inicio</mat-label>
                <input matInput type="time" formControlName="start_time" required/>
                <mat-error>El campo inicio presentación es obligatorio.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-4">
              <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label class="mb-24">Fecha Fin</mat-label>
                <input matInput type="date" formControlName="end_date" required />
                <mat-error>La fecha es un campo obligatorio.</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label class="mb-24">Hora fin</mat-label>
                <input matInput type="time" formControlName="end_time" required />
                <mat-error>El campo inicio presentación es obligatorio.</mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-12">
                <div fxLayout="column" fxFlex="100" class="pr-4 p-0">
                  <app-drag-drop-files (archivosEmmit)="setArchivoResolucion($event)"
                                       (archivosEmmitFromServer)=" setArchivoFromServer($event)"
                                       [filesFromServer]="filesFromServer"
                  ></app-drag-drop-files>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-md-end text-center">
                <button type="button" mat-button (click)="goBack()" class="mat-raised-button mat-button-base mat-accent btnJurispolCancelar w-200px me-md-5 mb-5 mb-md-0">
                  <span class="mat-button-wrapper"> Cancelar </span>
                  <div class="mat-ripple mat-button-ripple"></div>
                  <div class="mat-button-focus-overlay"></div>
                </button>
                <button type="submit" color="accent" class="mat-raised-button mat-button-base mat-accent btnJurispol w-200px">
                  <span class="mat-button-wrapper"> Guardar </span>
                  <div class="mat-ripple mat-button-ripple"></div>
                  <div class="mat-button-focus-overlay"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
