<div class="theme-options-panel" fusePerfectScrollbar>
    <div class="header">
        <span class="title">Personal</span>
        <button
            mat-icon-button
            class="close-button"
            (click)="toggleSidebarOpen('profile')"
        >
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div id="forms" class="page-layout simple fullwidth" fxLayout="column">
        <div class="content p-24">
            <div
                class="mb-24"
                fxLayout="column"
                fxLayoutAlign="center center"
                fxLayout.gt-md="row"
            >
                <form
                    class="mat-elevation-z4 p-24 mr-24"
                    fxLayout="column"
                    fxLayoutAlign="start"
                    *ngIf="data"
                    fxFlex="1 0 auto"
                    name="form"
                    [formGroup]="form"
                    (ngSubmit)="onSubmit(form)"
                >
                    <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        fxFlex="1 0 auto"
                    >
                        <input
                            hidden
                            type="file"
                            #fileInput
                            (change)="setFile($event)"
                        />
                        <img
                            class="avatar big"
                            [src]="data.foto | urlPath: 'usuarios/'"
                            (click)="fileInput.click()"
                            *ngIf="!file"
                        />
                        <img
                            class="avatar big"
                            [src]="file"
                            alt="user image"
                            *ngIf="file"
                            (click)="fileInput.click()"
                        />
                    </div>

                    <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        fxFlex="1 0 auto"
                    >
                        <mat-form-field
                            appearance="outline"
                            fxFlex="100"
                            class="pr-4"
                        >
                            <mat-label>Email</mat-label>
                            <input
                                matInput
                                formControlName="username"
                                required
                                disabled
                                readonly
                            />
                            <mat-icon matSuffix class="secondary-text"
                                >mail</mat-icon
                            >
                            <mat-error>El email es requerido!</mat-error>
                        </mat-form-field>
                    </div>

                    <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        fxFlex="1 0 auto"
                    >
                        <mat-form-field
                            appearance="outline"
                            fxFlex="100"
                            class="pr-4"
                        >
                            <mat-label>Password</mat-label>
                            <input
                                matInput
                                formControlName="password"
                                type="password"
                                disabled
                                readonly
                            />
                            <mat-icon matSuffix class="secondary-text"
                                >password</mat-icon
                            >
                            <mat-error
                                >El password debe contener al menos 5
                                caracteres!</mat-error
                            >
                        </mat-form-field>
                    </div>

                    <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        fxFlex="1 0 auto"
                    >
                        <mat-form-field
                            appearance="outline"
                            fxFlex="100"
                            class="pr-4"
                        >
                            <mat-label>Password actual</mat-label>
                            <input
                                matInput
                                formControlName="password_2"
                                type="password"
                                disabled
                                readonly
                            />
                            <mat-icon matSuffix class="secondary-text"
                                >password</mat-icon
                            >
                            <mat-error
                                >El password debe contener al menos 5
                                caracteres!</mat-error
                            >
                        </mat-form-field>
                    </div>


                   

                    <div
                        fxlayout="row"
                        fxlayoutalign="center center"
                        fxFlex="1 0 auto"
                        class="form__btn"
                    >
                   
                        <button
                            type="submit"
                            color="accent"
                            class="mat-raised-button mat-button-base mat-accent btnJurispol"
                        >
                            <span class="mat-button-wrapper"> Guardar </span>
                            <div class="mat-ripple mat-button-ripple"></div>
                            <div class="mat-button-focus-overlay"></div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
