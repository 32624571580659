import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FuseSharedModule} from '@fuse/shared.module';
import {SelectAreaTemaSubtemaComponent} from './selectAreaTemaSubtema.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {AtsCheckboxComponent} from './ats-checkbox/ats-checkbox.component';

@NgModule({
    declarations: [
        SelectAreaTemaSubtemaComponent,
        SelectAreaTemaSubtemaComponent,
        AtsCheckboxComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatCheckboxModule,
        MatRadioModule,
        MatFormFieldModule,
        NgxSpinnerModule,
        NgxDatatableModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
    ],
    exports: [SelectAreaTemaSubtemaComponent, SelectAreaTemaSubtemaComponent]
})
export class SelectAreaTemaSubtemaModule { }
