import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class MultiFileUploadService {
  public url = "";
  public history = [];
  events: any;

  // Hay que instanciar HttpClient en el constructor
  constructor(private http: HttpClient) { }

  normalize = (function () {
    let from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
      to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
      mapping = {};

    for (let i = 0, j = from.length; i < j; i++) {
      mapping[from.charAt(i)] = to.charAt(i);
    }

    return function (str) {
      const ret = [];
      for (let i = 0, j = str.length; i < j; i++) {
        const c = str.charAt(i);
        if (mapping.hasOwnProperty(str.charAt(i))) {
          ret.push(mapping[c]);
        } else {
          ret.push(c);
        }
      }
      return ret.join("");
    };
  })();

  // @ts-ignore
  public uploadMulti(id, files: any[], url?: string, customValueKey?: string): Promise<any> {
    if (!customValueKey) {
      customValueKey = "tipo";
    }

    const token = localStorage.getItem("token");

    return new Promise<any>((resolve) => {
      const form = new FormData();
      files.forEach((file) => {
        form.append("picture", file, this.normalize(file.name));
        form.append("id", id);
        form.append("key", customValueKey);

        fetch(environment.url + url + "/image", {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: form,
        })
          .then((res) => {
            this.consume(res.body.getReader());
            resolve(true);
          })
          .catch((e) => console.log("something went wrong: " + e));
      });
    });
  }

  public uploadMulti2(item, files: any[], url?: string): Promise<any> {
    const token = localStorage.getItem("token");

    return new Promise<any>((resolve) => {
      const form = new FormData();

      files.forEach((file) => {
        form.append("picture[]", file.archivo, this.normalize(file.nombreArchivo));
      });
      if (item.id) {
        form.append("id", item.id);
      }
      if (item.title) {
        form.append("title", item.title);
      }
      if (item.message) {
        form.append("message", item.message);
      }
      if (item.key_value_array) {
        form.append("key_value_array", item.key_value_array);
      }
      fetch(environment.url + url + "/image", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: form,
      })
        .then((res) => {
          resolve(res.json());
        })
        .catch((e) => console.log("something went wrong: " + e));
    });
  }

  consume(reader) {
    var total = 0;
    return new Promise((resolve, reject) => {
      function pump() {
        reader
          .read()
          .then(({ done, value }) => {
            if (done) {
              resolve(true);
              return;
            }
            total += value.byteLength;
            pump();
          })
          .catch(reject);
      }
      pump();
    });
  }

  // @ts-ignore
  public uploadMultiEdit(id, files: any[], url?: string, customValueKey?: any ): Promise<any> {
    if (!customValueKey) {
      customValueKey = "tipo";
    }

    const token = localStorage.getItem("token");

    return new Promise<any>((resolve) => {
      const form = new FormData();
      files.forEach((file) => {
        form.append("picture[]", file.archivo, this.normalize(file.nombreArchivo));
      });
      form.append("id", id);

      if (typeof customValueKey === 'object')
      {
        form.append('key-object', JSON.stringify(customValueKey));
      }
      else
      {
        form.append('key', customValueKey);
      }


      fetch(environment.url + url + "/image", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: form,
      })
        .then((res) => {
          this.consume(res.body.getReader());
          resolve(true);
        })
        .catch((e) => console.log("something went wrong: " + e));

    });
  }
}
