import {
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation,
} from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";

import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { AuthService } from "../auth.service";
import { Observable, Subject } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { FuseNavigationService } from "../../../../@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "../../../../@fuse/components/sidebar/sidebar.service";
import { imageTypes } from "../../../helpers/imageFilters";
import { BaseService } from "../../../bases-services/base.service";
import Swal from "sweetalert2";
import {toFormData} from "../../../helpers/Helpers";
import {EncrDecrService} from "../../../bases-services/EncrDecrService";
import {MisCursosService} from "../../alumno/components/mis-cursos/mis-cursos.service";

@Component({
    selector: "profile",
    templateUrl: "./profile-sidebar.component.html",
    styleUrls: ["./profile-sidebar.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ProfileSidebarComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    form: FormGroup;
    data: any;
    file: any;
    dataForm: any;
    image: any;

    @HostBinding("class.bar-closed")
    barClosed: boolean;

    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _formBuilder: FormBuilder,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _renderer: Renderer2,
        private auth: AuthService,
        private base: BaseService,
        public EncrDecr: EncrDecrService,
        private misCursosService: MisCursosService
    ) {
        // Set the defaults
        this.barClosed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        const observable = new Observable((subscriber) => {
            subscriber.next(
                this.auth.getDecodedAccessToken(localStorage.getItem("token"))
            );
        });

        this.auth.user$.subscribe((item) => {
            this.data = item;
            if (this.data) {
                this.form = this._formBuilder.group({
                    id : new FormControl(this.data.uuid),
                    foto: new FormControl(this.data.foto),
                    username: new FormControl(
                        { value: this.data.username, disabled: true }
                    )
                });
            }
        });

        observable.subscribe((item) => {
            this.data = item;
            if (this.data) {
                this.form = this._formBuilder.group({
                    id : new FormControl(this.data.uuid),
                    foto: new FormControl(this.data.foto),
                    username: new FormControl(
                        { value: this.data.username, disabled: true }
                    )
                });
            }
        });
    }

    setFile($event): void {
        if (imageTypes($event.target.files[0].type)) {
            this.image = $event;
            const reader = new FileReader();
            this.file = $event.target.files[0];
            reader.readAsDataURL($event.target.files[0]);
            reader.onload = (evt) => {
                this.file = reader.result;
                this.form.patchValue({
                    foto: $event.target.files[0],
                });
            };
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._fuseNavigationService.removeNavigationItem("custom-function");
    }

    getDataFoto(){
        return this.misCursosService.getDataLogo();
    }

    onSubmit(): void {
        if (this.form.valid) {
            const formData = toFormData(this.form.value);
            if (this.image.target.files[0] instanceof File) {
                formData.append('image', this.image.target.files[0]);
            }
            this.base.formWithFiles(formData, "update/credentials/profile/image").then((item) => {
                if (item) {
                    let datos = JSON.parse(this.EncrDecr.get(this.auth.getPrivateKey(), localStorage.getItem('datos')));
                    datos.foto = item.id;
                    localStorage.setItem('datos', this.EncrDecr.set(this.auth.getPrivateKey(), JSON.stringify(datos)));
                    this.misCursosService.setDataStore(datos);
                } else {
                    Swal.fire('Error', 'Error al guardar', 'error');
                }
            });
        }
    }
}
