import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'filenameByUrl'
})
export class FilenameByUrlPipe implements PipeTransform {

    transform(value: string): string {
        return  value.substring(value.lastIndexOf('/') + 1).substring(0, value.lastIndexOf('-'));
    }
}
