import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { BaseService } from '../../../bases-services/base.service';
import { Global } from '../../../global';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../modal/modal.service';

@Component({
    selector: 'app-video-help',
    templateUrl: 'help-video.component.html',
    styleUrls: ['help-video.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HelpVideoComponent implements OnInit, OnDestroy {
    url: string;
    modalUrl: any;
    global = Global;
    isJupol: boolean;
    modalId: string

    constructor (
        private base: BaseService,
        private activedRouter: ActivatedRoute,
        private modalService: ModalService,
        private router: Router,
        private sanitizer: DomSanitizer

    ) {
        this.isJupol = this.global.domain === 'jurispol_pro';
        this.modalId = 'modal-help-video';
        this.modalUrl = null;
    }

    ngOnInit (): void {
        const url = this.router.url.substring(1);
        const data = {
            isJupol: this.isJupol,
            webUrl: url
        };

        this.modalService.add(this);

        this.base.post('video-ayuda', data).subscribe(item => {
            if (item.length) {
                this.url = item;
            }
        });
    }

    openModal (): void {
        this.modalUrl = this.url ? this.sanitizer.bypassSecurityTrustResourceUrl(this.url) : null;
        this.modalService.open(this.modalId);
    }

    closeModal (): void {
        this.modalUrl = null;
        this.modalService.close(this.modalId);
    }

    ngOnDestroy (): void {
        this.modalService.remove(this.url);
    }
}
