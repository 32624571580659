import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import Swal from "sweetalert2";
import { BaseService } from "../../../../../bases-services/base.service";
import { ErrorsHandler } from "../../../../../helpers/ErrorsHandler";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalService } from "../../../../components/modal/modal.service";
import { AuthService } from "../../../../auth/auth.service";

@Component({
  selector: "add-curso-recurso-caso",
  templateUrl: "./add_curso-recurso-caso.component.html",
  styleUrls: ["../../../../scss/forms.scss"],
})
export class AddCursoRecursoCasoComponent implements OnInit {
  titulo = "Añadir caso";
  subtitulo = "Caso";
  cursoId: string;
  curso: any[];
  casos: any[];
  form;
  errors: ErrorsHandler;
  modalId: string;
  casoRecursoId: string;

  listRowIndex: number;
  @Output() emmitCasoCursoActualizado: EventEmitter<any> = new EventEmitter<any>();
  @Output() emmitGetCasos: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _formBuilder: FormBuilder, private base: BaseService, private router: Router, private route: ActivatedRoute, private auth: AuthService, private modalService: ModalService) {
    this.curso = [];
    this.casos = [];

    this.form = this._formBuilder.group({
      course_id: ["", Validators.required],
      case_id: ["", Validators.required],
      presentation_start: ["", Validators.required],
      presentation_end: ["", Validators.required],
      resolution_start: ["", Validators.required],
      resolution_end: ["", Validators.required],
      hora_presentation_start: ["", Validators.required],
      hora_presentation_end: ["", Validators.required],
      hora_resolution_start: ["", Validators.required],
      hora_resolution_end: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.cursoId = params.get("cursoId");
      if (this.cursoId) {
        this.casoRecursoId = params.get("id");

        if (this.casoRecursoId) {
          this.startEdition();
        } else {
          this.startNew();
        }
      }
     
    });
  }

  private getCasoList(): void {
    this.base.listAll("recurso/caso/list").subscribe((item) => {
      this.errors = new ErrorsHandler(item.code, item.message, "get", "casos");
      if (!this.errors.setError()) {
        this.casos = item.result;
      } else {
        Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
      }
    });
  }

  private getCurso(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.base.list(this.cursoId, "curso/details").subscribe((item) => {
        this.errors = new ErrorsHandler(item.code, item.message, "get", "caso del curso");
        if (!this.errors.setError()) {
          this.curso = item.result;
          this.subtitulo = "Caso de " + this.curso["nombre"];
          resolve(true);
        } else {
          Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
          this.goBack();
        }
      });
    });
  }

  private startNew(): void {
    this.getCurso().then(() => {
      this.casoRecursoId = null;
    
      this.form.patchValue({
        course_id: this.curso["id"],
        case_id: "",
        presentation_start: this.curso["start_date"].split(' ')[0],
        presentation_end: this.curso["end_date"].split(' ')[0],
        resolution_start: this.curso["start_date"].split(' ')[0],
        resolution_end: this.curso["end_date"].split(' ')[0],
        hora_presentation_start: this.curso["start_date"].split(' ')[1],
        hora_presentation_end: this.curso["end_date"].split(' ')[1],
        hora_resolution_start: this.curso["start_date"].split(' ')[1],
        hora_resolution_end: this.curso["end_date"].split(' ')[1],
      });
    }).then(() => {
      this.getCasoList();
    });
  }

  private startEdition(): void {
    this.getCurso();
    
    this.titulo = "Editar caso";
    if (this.casoRecursoId) {
      this.base.list(this.casoRecursoId, "recurso/caso").subscribe((item) => {
        this.errors = new ErrorsHandler(item.code, item.message, "get", "caso del curso");
        if (!this.errors.setError()) {
          const details = item.result;
          this.form.patchValue({
            course_id: details.curso_id,
            case_id: details.caso_id,
            presentation_start: details.presentation_start.split(' ')[0],
            presentation_end: details.presentation_end.split(' ')[0],
            resolution_start: details.resolution_start.split(' ')[0],
            resolution_end: details.resolution_end.split(' ')[0],
            hora_presentation_start: details.presentation_start.split(' ')[1],
            hora_presentation_end: details.presentation_end.split(' ')[1],
            hora_resolution_start:  details.resolution_start.split(' ')[1],
            hora_resolution_end: details.resolution_end.split(' ')[1]
          });
        } else {
          Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
          this.goBack();
        }
      });
    } else {
      Swal.fire("Error", "Se ha accedido a una ruta errónea.", "error");
      this.goBack();
    }
  }

  setEditModalInformation(modalId: string, casoRecursoId?: string, listRowIndex?: number): void {
    this.casoRecursoId = casoRecursoId ? casoRecursoId : null;
    this.modalId = modalId;

    if (this.casoRecursoId) {
      this.listRowIndex = listRowIndex;
      this.startEdition();
    } else {
      this.startNew();
    }
  }

  onSubmit(form: FormGroup): void {
    if (form.valid) {
      const action = this.casoRecursoId != null ? "update" : "submit";
      const ruta = this.casoRecursoId != null ? "recurso/caso/edit/" + this.casoRecursoId : "recurso/caso/add";
      // Enviar formulario
      this.base.add(form.value, ruta).subscribe((item) => {
       
        this.errors = new ErrorsHandler(item.code, item.message, action, "caso del curso");
        if (!this.errors.setError()) {

          this.emmitGetCasos.emit(this.cursoId);
          Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
          if (this.casoRecursoId != null) {
            const casoToCurso = form.value;
            casoToCurso["rowIndex"] = this.listRowIndex;
            this.emmitCasoCursoActualizado.emit(casoToCurso); // Devuelve a la lista
          }
          this.goBack();
        } else {
          Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
        }
      });
    }
  }

  goBack(): void {
    if (this.modalId !== undefined) {
      this.modalService.close(this.modalId);
    } else {
      const route = this.casoRecursoId ? "../.." : "..";
      this.router.navigate([route], { relativeTo: this.route });
    }
  }
}
