import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'toOrSend'
})
export class ToOrSendPipe implements PipeTransform {

    transform(value: any, user: any): string {
        if (value.idEmisor === user.uuid) {
            return  'Tu' ;
        } else {
            return value.aEmisor  + ' ' + value.nEmisor;

        }
    }

}
