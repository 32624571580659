import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'dateIndex'
})
export class DateIndexPipe implements PipeTransform {

    transform(value: string, args: unknown[]): number {
        if (value === args[0]) {
            return 4;
        }


    }
}
