<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <div class="header accent p-24 h-60" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="h1">{{titulo}}</div>
        </div>
    </div>

    <div class="content p-24">
        <div class="mb-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-md="row">

            <form class="mat-elevation-z4" fxLayout="column" fxLayoutAlign="start"
                  fxFlex="1 0 auto" name="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">

                <div class="h2 mb-24">{{subtitulo}}</div>

                <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-6">
                        <mat-label class="mb-24">Área *</mat-label>
                        <mat-select formControlName="area" (selectionChange)="getTemas($event)">
                            <mat-option [value]="''" selected disabled>Selecciona Área</mat-option>
                            <mat-option *ngFor="let area of areas" [value]="area.id">{{area.nombre}}</mat-option>
                        </mat-select>
                        <mat-error>El área es un campo obligatorio.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-6">
                        <mat-label class="mb-24">Tema *</mat-label>
                        <mat-select formControlName="tema">
                            <mat-option [value]="''" selected>Selecciona Tema</mat-option>
                            <mat-option *ngFor="let tema of temas" [value]="tema.id">{{tema.nombre}}</mat-option>
                        </mat-select>
                        <mat-error>El área es un campo obligatorio.</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-6">
                        <mat-label class="mb-24">Nombre</mat-label>
                        <input matInput formControlName="nombre" required>
                        <mat-error>El nombre es un campo obligatorio.</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="30" class="pr-6">
                        <mat-label class="mb-24">Categoria</mat-label>
                        <mat-select formControlName="category" required>
                            <mat-option *ngFor="let cate of categorias" [value]="cate.id">
                                {{cate.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>La categoria es un campo obligatorio.</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="20" class="pr-3">
                        <mat-label class="mb-24">Orden</mat-label>
                        <input matInput formControlName="orden" type="number" required>
                        <mat-error>El orden es un campo obligatorio, numérico y positivo.</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="center between" fxFlex="1 0 auto">
                    <div appearance="outline" fxFlex="50" class="pr-6">
                        <mat-checkbox formControlName="estado">Estado</mat-checkbox>
                    </div>

                    <div appearance="outline" fxFlex="50" class="pr-6">
                        <mat-checkbox formControlName="documento">Documento</mat-checkbox>
                    </div>


                </div>



                <div fxlayout="row" fxlayoutalign="right center" fxFlex="1 0 auto" class="form__btn">
                    <button type="button" mat-button (click)="goBack()"
                            class="mat-raised-button mat-button-base mat-accent btnJurispolCancelar mr-24">
                        <span class="mat-button-wrapper"> Cancelar </span>
                        <div class="mat-ripple mat-button-ripple"></div>
                        <div class="mat-button-focus-overlay"></div>
                    </button>
                    <button type="submit" color="accent"
                            class="mat-raised-button mat-button-base mat-accent btnJurispol">
                        <span class="mat-button-wrapper"> Enviar </span>
                        <div class="mat-ripple mat-button-ripple"></div>
                        <div class="mat-button-focus-overlay"></div>
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>
