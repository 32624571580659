<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
  <div class="header accent p-24 h-60" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="center start">
      <div class="h1">{{ titulo }}</div>
    </div>
  </div>

  <div class="p-24">
    <div class="mb-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-md="row">
      <form class="mat-elevation-z4" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <div class="h2 mb-24">{{ subtitulo }}</div>
        <div class="ps-4 pe-4">
          <div class="row">
            <div class="col-sm-4">
              <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                <mat-label class="mb-24">Añadir URL</mat-label>
                <input matInput type="text" formControlName="url" required />
                <mat-icon matSuffix class="secondary-text">link</mat-icon>
                <mat-error>El url es un campo obligatorio.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-4">
              <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label class="mb-24">Fecha Inicio</mat-label>
                <input matInput type="date" formControlName="start_date" required/>
                <mat-error>La fecha es un campo obligatorio.</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label class="mb-24">Hora inicio</mat-label>
                <input matInput type="time" formControlName="start_time" required/>
                <mat-error>El campo inicio presentación es obligatorio.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-4">
              <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label class="mb-24">Fecha Fin</mat-label>
                <input matInput type="date" formControlName="end_date" required />
                <mat-error>La fecha es un campo obligatorio.</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label class="mb-24">Hora fin</mat-label>
                <input matInput type="time" formControlName="end_time" required />
                <mat-error>El campo inicio presentación es obligatorio.</mat-error>
              </mat-form-field>
            </div>

            <div class="row row-cols-auto justify-content-start">
              <div class="col-sm-6 col-12">
                <mat-form-field class="w-100" appearance="none" floatLabel="always">
                  <mat-select class="w-100 customInputJurispol h-40px" placeholder="Selecciona Áreas o Superpromociones" formControlName="option_type" required
                              (selectionChange)="
                                this.selectedAreaPromoValue = $event.value;
                                this.extraValues = [];
                                getOptionList()">
                    <mat-option class="mat-option-custom" *ngFor="let filter of optionTypeFilter" [value]="filter.value">
                      {{filter.name | titlecase}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Esta selección es un campo obligatorio.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <ng-container *ngIf="form.value.option_type">
              <div class="row">
                <div class="col-3 col-sm-1 mb-20 text-center d-flex justify-content-center align-items-center">
                  <mat-icon class="pointer" (click)="addEntity()">add_circle_outline</mat-icon>
                  <mat-icon class="pointer" *ngIf="extraValues.length > 1" (click)="deleteItem(extraValues.length - 1)" >remove_circle_outline</mat-icon>
                </div>
                <div class="col-9 col-sm-7 mb-20 text-center d-flex justify-content-start align-items-center">
                  Selecciona Áreas o Promociones
                </div>
              </div>
              <div class="row">
                <ng-container *ngFor="let extra of extraValues; let i = index">
                  <div class="col-12 col-sm-11 mb-20 text-center" *ngIf="extra">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-label>Seleccione entidad</mat-label>
                      <mat-select matNativeControl [(ngModel)]="extra.entity_value" [ngModelOptions]="{standalone: true}" *ngIf="selectedAreaPromoValue === 'area'">
                        <ng-container *ngFor="let selectvalue of selectvalues">
                          <mat-optgroup label="{{selectvalue.title}}" >
                            <ng-container *ngFor="let value of selectvalue.values">
                              <mat-option value="{{value.value}}" [disabled]="disbleOptions(value.value)">{{value.name}}</mat-option>
                            </ng-container>
                          </mat-optgroup>
                        </ng-container>
                      </mat-select>
                      <mat-select matNativeControl [(ngModel)]="extra.entity_value" [ngModelOptions]="{standalone: true}" *ngIf="selectedAreaPromoValue === 'superpromociones'">
                        <mat-optgroup label="{{'Super promociones'}}" >
                          <ng-container *ngFor="let selectvalue of selectvalues">
                            <mat-option value="{{selectvalue.id}}" [disabled]="disbleOptions(selectvalue.id)">{{selectvalue.name}}</mat-option>
                          </ng-container>
                        </mat-optgroup>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-sm-1 mb-20 text-center d-flex justify-content-center align-items-center" *ngIf="extra && extraValues.length > 1">
                    <mat-icon class="pointer" (click)="deleteItem(i)" >remove_circle_outline</mat-icon>
                  </div>
                </ng-container>

              </div>
            </ng-container>
            <div class="row">
              <div class="col-12">
                <div fxLayout="column" fxFlex="100" class="pr-4 p-0">
                  <app-drag-drop-files (archivosEmmit)="setArchivoResolucion($event)"
                                       (archivosEmmitFromServer)=" setArchivoFromServer($event)"
                                       [filesFromServer]="filesFromServer"
                  ></app-drag-drop-files>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-md-end text-center">
                <button type="button" mat-button (click)="goBack()" class="mat-raised-button mat-button-base mat-accent btnJurispolCancelar w-200px me-md-5 mb-5 mb-md-0">
                  <span class="mat-button-wrapper"> Cancelar </span>
                  <div class="mat-ripple mat-button-ripple"></div>
                  <div class="mat-button-focus-overlay"></div>
                </button>
                <button type="submit" color="accent" class="mat-raised-button mat-button-base mat-accent btnJurispol w-200px">
                  <span class="mat-button-wrapper"> Guardar </span>
                  <div class="mat-ripple mat-button-ripple"></div>
                  <div class="mat-button-focus-overlay"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
