import { parseTemplate } from "@angular/compiler";
import { parseSelectorToR3Selector } from "@angular/compiler/src/core";

export class BreadCrums {
    public routes: string;

    constructor(router) {
        this.routes = this.checkOrigin(router.url);

    }


    private checkOrigin(router) {

        const parts = router.split("/");
        const param = parts[parts.length - 1];

        if (param == 'charge') {
            let url = "";

            parts.forEach(item => {

                if (item != param && item !== '') {
                    url = url + '/' + item;
                }
            });
            return url;
        } else {
            return router;
        }
    }

    isRouterActive(router) {
        const parts = router.url.split("/");
        const param = parts[parts.length - 1];
        if (param == 'charge') {
            return true
        } else {
            return false;
        }
    }


    public cleanRoute() {
        return this.routes;
    }



    public setLocalRoutes() {
        if (localStorage.getItem("breadcrums")) {
            const data = JSON.parse(localStorage.getItem("breadcrums"));
            let idx = this.idx();

            if (idx >= 0) {
                const key = Object.keys(data[idx]);

                if (key[0] === this.routes) {
                    return data[idx][this.routes];
                }
            } else {
                data.push({ [this.routes]: [] });
                localStorage.setItem("breadcrums", JSON.stringify(data));
                return data[this.routes];
            }
        } else {
            localStorage.setItem("breadcrums", JSON.stringify([{ [this.routes]: [] }]));
            return [];
        }
    }

    public setParams(param, type, position): void {
        const idx = this.idx();
        if (idx >= 0) {
            const data = JSON.parse(localStorage.getItem("breadcrums"));
            data[idx][this.routes][position] = { [type]: param };
            localStorage.setItem("breadcrums", JSON.stringify(data));
        }
    }

    public getLocalRoutes() {
        if (localStorage.getItem("breadcrums")) {
            return JSON.parse(localStorage.getItem("breadcrums"));
        } else {
            false;
        }
    }

    public getParams(position) {
        if (this.getLocalRoutes()) {
            const idx = this.idx();

            if (idx != -1) {
                const data = this.getLocalRoutes()[idx][this.routes];

                return data;
            } else {
                false;
            }
        } else {
            return false;
        }
    }

    public idx(): number {
        const data = JSON.parse(localStorage.getItem("breadcrums"));
        let idx = -1;
        data.forEach((item, index) => {
            const key = Object.keys(item);
            if (key[0] == this.routes) {
                idx = index;
            }
        });

        return idx;
    }

    public removeParams(): void {
        const data = JSON.parse(localStorage.getItem("breadcrums"))[this.idx()];
    }
}
