<input type="text" [value]="form" (input)="updateValue($event.target.value)" style="display: none">
<div class="d-block">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left center" fxFlex="100" class="pl-24 mb-12">
        <mat-label>Contenidos Menú *</mat-label>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxFlex="100" class="pl-24 mb-24">
        <app-multi-checkbox #menuMultiCheck [tipo]="'menu'" [hasConvocatoria]="hasConvocatoria"
                            (valuesEmmit)="getFromMulticheck($event)" (finishedCall)="menuFill($event)"></app-multi-checkbox>
    </div>
    <ng-container *ngIf="allowAllChecks">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left center" fxFlex="100" class="pl-24 mb-12">
            <mat-label>Test</mat-label>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxFlex="100" class="pl-24 mb-24">
            <app-multi-checkbox #testMultiCheck [tipo]="'testOpcion'"
                                (valuesEmmit)="getFromMulticheck($event)" (finishedCall)="menuFill($event)"></app-multi-checkbox>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left center" fxFlex="100" class="pl-24 mb-12">
            <mat-label>Material de Apoyo</mat-label>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxFlex="100" class="pl-24 mb-24">
            <app-multi-checkbox #programacionMultiCheck [tipo]="'materialApoyo'"
                                (valuesEmmit)="getFromMulticheck($event)" (finishedCall)="menuFill($event)"></app-multi-checkbox>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left center" fxFlex="100" class="pl-24 mb-12">
            <mat-label>Programación</mat-label>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxFlex="100" class="pl-24 mb-24">
            <app-multi-checkbox #claseMultiCheck [tipo]="'clase'"
                                (valuesEmmit)="getFromMulticheck($event)" (finishedCall)="menuFill($event)"></app-multi-checkbox>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left center" fxFlex="100" class="pl-24 mb-12" *ngIf="!hasConvocatoria">
            <mat-label>Diploma</mat-label>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxFlex="100" class="pl-24 mb-24">
            <app-multi-checkbox #checkDiploma [tipo]="'checkDiploma'" [hasConvocatoria]="hasConvocatoria"
                                (valuesEmmit)="getFromMulticheck($event)"></app-multi-checkbox>
        </div>


        <div class="{{blocked?'d-none row pl-24 pr-4':'row pl-24 pr-4'}}">
            <div class="col-12">
                <mat-form-field appearance="outline" fxFlex="25" class="pr-4 pl-24" *ngIf="form.value.checkDiploma.length">
                    <mat-label class="mb-24">Fecha de publicación</mat-label>
                    <input matInput type="date" [(ngModel)]="dateDiploma" (change)="saveDiplomaDate()" [required]="form.value.checkDiploma.length ? null : true" />
                    <mat-icon matPrefix class="secondary-text">access_time</mat-icon>
                    <mat-error>Indica una fecha de publicación</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="25" fxFlex.xs="25" class="pr-4" *ngIf="form.value.checkDiploma.length">
                    <mat-label>Introduce test ID</mat-label>
                    <input matInput [(ngModel)]="selectedTest" (change)="changeTestId()">
                    <mat-error>El test ID es un campo requerido</mat-error>
                </mat-form-field>
            </div>

            <div class="col-6" *ngIf="form.value.checkDiploma.length" >
                <app-drag-drop-files (archivosEmmit)="setFileFromComputer('diploma', $event)"
                                     (archivosEmmitFromServer)="setFileFromServer('diploma', $event)"
                                     [filesFromServer]="diplomaFilesFromServer"
                ></app-drag-drop-files>
            </div>

            <div class="col-6" >
                <mat-label >Documentación</mat-label>

                <app-drag-drop-files (archivosEmmit)="setFileFromComputer('documentation', $event)"
                                     (archivosEmmitFromServer)="setFileFromServer('documentation', $event)"
                                     [filesFromServer]="documentationFilesFromServer" [showTitle]="false"
                ></app-drag-drop-files>
            </div>
        </div>


    </ng-container>

</div>
