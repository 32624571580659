import {Pipe, PipeTransform} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser'


@Pipe({
    name: 'marcarRole'
})
export class MarcarRolePipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: string): any {

        switch (value) {
            case 'RUS':
                return  this.sanitizer.bypassSecurityTrustHtml(`<span style="background-color: #00c853; font-size: 8px; padding: 3px; color: white;
                        margin-left: 10px; border-right: 2px;">Alumno</span>`);

            case 'RSS':
                return  this.sanitizer.bypassSecurityTrustHtml(`<span style="background-color: #00e5ff; font-size: 8px; padding: 3px; color: white;
                        margin-left: 10px; border-right: 2px">Secretaria</span>`);
            case 'RAS':
                return  this.sanitizer.bypassSecurityTrustHtml(`<span style="background-color: #cddc39; font-size: 8px; padding: 3px; color: white;
                        margin-left: 10px; border-right: 2px">Administrador</span>`);
            case 'RPF':
                return  this.sanitizer.bypassSecurityTrustHtml(`<span style="background-color: #2A2A2A; font-size: 8px; padding: 3px; color: white;
                        margin-left: 10px; border-right: 2px">Profesor</span>`);
            case 'RSP':
                return  this.sanitizer.bypassSecurityTrustHtml(`<span style="background-color: #2A2A2A; font-size: 8px; padding: 3px; color: white;
                        margin-left: 10px; border-right: 2px">MASTER</span>`);
            case 'RDM':
                return  this.sanitizer.bypassSecurityTrustHtml(`<span style="background-color: #e64a19; font-size: 8px; padding: 3px; color: white;
                        margin-left: 10px; border-right: 2px">Administracion</span>`);
        }




    }
}
