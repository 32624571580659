<div id="users-profile-edit" class="container-fluid ps-5 pe-5 bg-white">
    <!-- HEADER -->
    <div
        class="mb-20"
    >
        <div class="row align-items-center justify-content-between pt-60">
            <div class="col d-flex align-items-center fs-2 fw-bold">
                <mat-icon>perm_identity</mat-icon>Mis datos
            </div>
            <div class="col-auto">
                <app-video-help></app-video-help>
            </div>
        </div>

    </div>
    <!-- / HEADER -->
    <hr>
    <!-- CONTENT -->
    <div class="content row">
        <div
            class="mb-24 bodyMisDatos col-12 col-lg-6 col-md-8 m-auto"
            fxLayoutAlign="center center"
        >
            <!-- REACTIVE FORM EXAMPLE -->
            <form
                class="content"
                fxLayout="column"
                fxLayoutAlign="start"
                *ngIf="data"
                fxFlex="1 0 auto"
                name="form"
                [formGroup]="form"
                (ngSubmit)="onSubmit(form)"
            >
                <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxFlex="1 0 auto"
                >
                    <input
                        hidden
                        type="file"
                        #fileInput
                        (change)="setFile($event); onSubmit();"
                    />
                    <img
                        class="avatar big pointer"
                        [src]="getDataFoto() | urlPath: 'usuarios/'"
                        (click)="fileInput.click()"
                        *ngIf="!file"
                    />
                    <img
                        class="avatar big"
                        [src]="file"
                        alt="user image"
                        *ngIf="file"
                        (click)="fileInput.click()"
                    />
                </div>
                <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        class="pr-4"
                    >
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="username" required />
                        <mat-icon matSuffix class="secondary-text"
                            >mail</mat-icon
                        >
                        <mat-error>El email es requerido!</mat-error>
                    </mat-form-field>
                </div>

<!--
               
                <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        class="pr-4"
                    >
                        <mat-label>Password</mat-label>
                        <input
                            matInput
                            formControlName="password"
                            type="password"
                        />
                        <mat-icon matSuffix class="secondary-text"
                            >password</mat-icon
                        >
                        <mat-error
                            >El password debe contener al menos 5
                            caracteres!</mat-error
                        >
                    </mat-form-field>
                </div>

                <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxFlex="1 0 auto"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        class="pr-4"
                    >
                        <mat-label>Password actual</mat-label>
                        <input
                            matInput
                            formControlName="password_2"
                            type="password"
                        />
                        <mat-icon matSuffix class="secondary-text"
                            >password</mat-icon
                        >
                        <mat-error
                            >El password debe contener al menos 5
                            caracteres!</mat-error
                        >
                    </mat-form-field>

                     <div
     fxlayout="row"
     fxlayoutalign="center center"
     fxFlex="1 0 auto"
     class="form__btn"
 >
     <button
         type="submit"
         color="accent"
         class="mat-raised-button mat-button-base mat-accent btnJurispol"
     >
         <span class="mat-button-wrapper"> Guardar </span>
         <div class="mat-ripple mat-button-ripple"></div>
         <div class="mat-button-focus-overlay"></div>
     </button>
 </div>
                </div>
-->
            <div><a href="https://tienda.jurispol.com/online/customer/account/login/">Para actualizar credenciales pulsa el enlace</a></div>

               
               
               
               
               
               

               


               
               
               
               
               
               
               
               
               
               
               
            </form>
        </div>
    </div>
</div>
