// @ts-ignore
import {Pipe, PipeTransform} from '@angular/core';
import {imageTypes} from '../helpers/imageFilters';


@Pipe({
    name: 'fileSelector'
})
export class FileSelectorPipe implements PipeTransform {
    transform(value: string): boolean {
        if (imageTypes(value)) {

            return false;
        }
        return true;
    }
}
