<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <div class="header accent p-24 h-60" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="h1">{{titulo}}</div>
        </div>
    </div>

    <div class="content p-24">

        <div class="h2 titleListado">{{subtitulo}}</div>

        <div class="filter-header">
            <div class="filter-header-input">
                <input type="text" placeholder="Filtrar..." (keyup)="updateFilter($event)" />
            </div>
            <div class="filter-header-items">
                <button type="button" color="accent"
                        class="mat-raised-button mat-button-base mat-accent btnJurispol ml-20 mr-20"
                        (click)="goToAdd()">
                    <span class="mat-button-wrapper">Añadir caso</span>
                </button>
            </div>
        </div>

        <ngx-datatable
                class="material bancoPreguntas"
                [rows]="rows"
                [loadingIndicator]="loadingIndicator"
                [columnMode]="'force'"
                [headerHeight]="48"
                [footerHeight]="56"
                [rowHeight]="'auto'"
                [reorderable]="true"
                [scrollbarH]="true"
                [limit]="2000"
        >
            <ngx-datatable-column name="Caso" prop="name"  [minWidth]="130" [resizeable]="true"></ngx-datatable-column>
            <ngx-datatable-column name="F. Presentación" prop="presentation_start" [width]="130" [canAutoResize]="false" [sortable]="false"  [minWidth]="130" [resizeable]="true">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{value | date : 'dd/MM/yyyy'}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="F. Resolución" prop="resolution_start" [width]="130" [canAutoResize]="false" [sortable]="false" [minWidth]="130" [resizeable]="true">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{value | date : 'dd/MM/yyyy'}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Fin Presentación" prop="presentation_end" [width]="130" [canAutoResize]="false" [sortable]="false" [minWidth]="130" [resizeable]="true">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{value | date : 'dd/MM/yyyy'}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Fin Resolución" prop="resolution_end" [width]="130" [canAutoResize]="false" [sortable]="false" [minWidth]="130" [resizeable]="true">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{value | date : 'dd/MM/yyyy'}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Acciones" prop="id" [width]="130" [canAutoResize]="false" [sortable]="false" [minWidth]="130" [resizeable]="true">
                <ng-template  ngx-datatable-cell-template let-value="value" let-row="row">
                    <div class="action">
                        <div class="action__container">
                            <ul class="action__list">
                                <li class="action__item">
                                    <span class="action_icon warn-A100-fg" (click)="delete(value)">
                                        <mat-icon>delete_forever</mat-icon>
                                    </span>
                                </li>
                                <li class="action__item">
                                    <span class="action_icon blue-A100-fg" (click)="goToEdit(value, row)">
                                        <mat-icon>create</mat-icon>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>

    </div>
</div>

<jw-modal id="{{modalId}}" class="modalCursoRecursoCaso" >
    <add-curso-recurso-caso
    (emmitCasoCursoActualizado)="actualizarFilaCaso($event)"
    (emmitGetCasos)="getCasos($event)"></add-curso-recurso-caso>
</jw-modal>
