import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpVideoComponent } from './help-video.component';
import { MatIconModule } from '@angular/material/icon';
import {ModalModule} from '../modal/modal.module';

@NgModule({
    declarations: [
        HelpVideoComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        ModalModule

    ],
    exports: [HelpVideoComponent],
    entryComponents: [HelpVideoComponent]
})
export class HelpVideoModule { }
