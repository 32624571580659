import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'ifCheck'
})
export class IfCheckPipe implements PipeTransform {

    transform(value: string, args: string): boolean {
        if (value === args) {
           return true;
        }

        return false;


    }
}
