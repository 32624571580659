import { FileItem } from "./dragdrop.model";
import { Directive, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Directive({
  selector: "[dragDropFiles]",
})
export class DragDropDirective {
  @Input() archivos: FileItem[] = [];
  @Input() archivosFromServer: [] = [];
  @Input() excluidos = [];
  @Input() grupoExcluidos = [];
  @Input() isArray: boolean;
  @Output() mouseSobre: EventEmitter<boolean> = new EventEmitter();
  @Output() cargarArchivos: EventEmitter<any> = new EventEmitter<any>();
  @Output() emmitAlert: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  @HostListener("dragover", ["$event"])
  public onDragEnter(event: any): void {
    this.mouseSobre.emit(true);
    this._prevenirDetener(event);
  }

  @HostListener("dragleave", ["$event"])
  public onDragLeave(event: any): void {
    this.mouseSobre.emit(false);
  }

  @HostListener("drop", ["$event"])
  public onDrop(event: any): any {
    const transferencia: any = this._getTransferencia(event);

    if (!transferencia) {
      return;
    }

    this._extraerArchivos(transferencia.files);

    this._prevenirDetener(event);
    this.mouseSobre.emit(false);
  }

  private _getTransferencia(event: any): void {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }

  private _extraerArchivos(archivosLista: any): void {
    archivosLista = this.groupFile(archivosLista, this.grupoExcluidos);

    if (archivosLista.length > 0) {
      // tslint:disable-next-line:forin
      const files = this.extFile(archivosLista, this.excluidos);
      
      if (!this.isArray) {
        const archivoTemporal = files[files.length -1];
        if (!this._archivoYaFueDroppeado(archivoTemporal.name)) {
          const nuevoArchivo = new FileItem(archivoTemporal);
          this.archivos = [nuevoArchivo];
          this.cargarArchivos.emit(this.archivos);
        
        }

   } else {
        
        if (files.length > 0) {
          files.forEach((file) => {
            const archivoTemporal = file;
            if (!this._archivoYaFueDroppeado(archivoTemporal.name)) {
              const nuevoArchivo = new FileItem(archivoTemporal);
              
              this.archivos.push(nuevoArchivo);
              this.cargarArchivos.emit(this.archivos);
            }
          });
        }
      }
  }
  }

  private extFile(files: any, excludes?: any[]) {
    const ext = [];
    const response = [];

    if (excludes && excludes.length > 0) {
      for (var index = 0; index < files.length; ++index) {
        ext.push(files[index].name.split(".")[1]);
        if (excludes.includes(ext[index])) {
          this.emmitAlert.emit(`El archivo con extension ${ext[index]} no está permitido`);
        } else {
          response.push(files[index]);
        }
      }

      return response;
    }
    return Array.from(files);
  }

  private groupFile(files: any, excludesGroup?: any[]): Array<any> {
    const response = [];
    const ext = [];
    if (excludesGroup && excludesGroup.length > 0) {
      for (var index = 0; index < files.length; ++index) {
        ext.push(files[index].type.split("/")[0]);

        if (excludesGroup.includes(ext[index])) {
          this.emmitAlert.emit(`El tipo de archivo ${ext[index]} no está permitido`);
        } else {
          response.push(files[index]);
        }
      }

      return response;
    }

    return Array.from(files);
  }

  private _prevenirDetener(event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private _archivoYaFueDroppeado(nombreArchivo: string): boolean {
    for (const archivo of this.archivos) {
      if (archivo.nombreArchivo === nombreArchivo) {
        return true;
      }
    }
    return false;
  }
}
