import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../../../../bases-services/base.service';
import { ErrorsHandler } from '../../../../../helpers/ErrorsHandler';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MultiFileUploadService } from '../../../../../bases-services/multi-file-upload.service';
import Swal from 'sweetalert2';
import { Global } from '../../../../../global';

@Component({
    selector: 'banner-alumno-dashboard-add',
    templateUrl: './banner-alumno-dashboard-add.component.html',
    styleUrls: ['./banner-alumno-dashboard-add.scss']
})
export class BannerAlumnoDashboardAddComponent implements OnInit, OnDestroy {
    titulo = 'Añadir banners en dashboard de alumnos';
    subtitulo = 'Imagen de banners en dashboard de alumnos';
    form: FormGroup;
    filesFromServer;
    filesToServer;
    publicidadId;
    errors;
    global = Global;
    selectedAreaPromoValue: string;
    resourceModal = 'custom-modal-recurso';
    selectvalues: any[];
    extraValues: any[] = [];
    optionTypeFilter;
    tableData;
    pageSize: any;
    elementsLength: any;
    rows: any[];
    loading = false;

    recourceModalConfiguration = {
        hasData: false,
        openOnStart: true,
        canReOpen: true,
        showBackAndStartButtons: true,
        hasTime: false,
        modalOpen: false
    };
    filter = {
        page: 0,
        superpromo: null,
        search: null,
        excludeCourses: []
    };

  // eslint-disable-next-line no-useless-constructor
  constructor (
      private base: BaseService,
      private router: Router,
      private _formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private multiFileUploadService: MultiFileUploadService,
      public datepipe: DatePipe,
  ) {}

    ngOnInit (): void {
      this.form = this._formBuilder.group({
          site: [],
          image: [],
          url: ['', Validators.required],
          start_date: ['', Validators.required],
          start_time: ['', Validators.required],
          end_date: ['', Validators.required],
          end_time: ['', Validators.required],
          option_type: ['', Validators.required],
          option_value:  [[]],
      });

      this.route.paramMap.subscribe((params: ParamMap) => {
          this.publicidadId = params.get('id');
          if (this.publicidadId) {
              this.titulo = 'Editar selección de curso';
              this.subtitulo = 'Imagen de selección de curso #' + this.publicidadId;
              this.startEdition();
          }
      });

      this.base.listAll('psicotecnicos/admin/filters').subscribe(item => {
          this.optionTypeFilter = item.result.optionTypeFilter;
      });
  }

    ngOnDestroy (): void {
    }

    onSubmit (form: FormGroup): void {
      if (form.valid) {
          if (!this.publicidadId) {
              this.form.patchValue({
                  site: this.global.domain === 'jurispol_pro' ? 'jurispol_pro' : 'jurispol'
              });
          }

          if (this.extraValues.length === 0 || this.checkExtraValues()){
              Swal.fire('Atención', 'Por favor, seleccione un área o una super promoción.', 'warning');
              return;
          }

          this.form.patchValue({
              option_value: this.extraValues
          });

          const ruta = this.publicidadId ? 'publicidad/alumno/edit/' + this.publicidadId : 'publicidad/alumno/add';
          const action = this.publicidadId ? 'update' : 'submit';

          if (!this.comoprobarFechas()) {
              Swal.fire('Atención', 'La fecha de inicio del curso ha de anterior a la fecha fin. ' +
            'El fin de acceso ha de ser posterior a la fecha fin.', 'warning');
              return;
          }

          if ((!(this.filesFromServer) && !(this.filesToServer)) ||
          (this.filesToServer === undefined && this.filesFromServer.length === 0) ||
              (this.filesToServer !== undefined && this.filesToServer.length === 0 && this.filesFromServer.length === 0)) {
              Swal.fire('Atención', 'Por favor, seleccione una imagen', 'warning');
              return;
          }

          if (this.filesToServer &&
              this.filesToServer[0] &&
              !this.filesToServer[0].nombreArchivo.includes('.png') &&
              !this.filesToServer[0].nombreArchivo.includes('.jpeg') &&
              !this.filesToServer[0].nombreArchivo.includes('.jpg')) {
              Swal.fire('Atención', 'El formato del fichero debe ser PNG, JPEG o JPG', 'warning');
              return;
          }

          this.base.add(form.value, ruta).subscribe((item) => {
              this.errors = new ErrorsHandler(item.code, item.message, action, 'curso');
              if (!this.errors.setError()) {
                  Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                  if (this.filesToServer) {
                      this.multiFileUploadService.uploadMultiEdit(item.id, this.filesToServer, 'publicidad/alumno/add').then((res) => {
                          this.goBack();
                      });
                  } else {
                      this.goBack();
                  }
              } else {
                  Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
              }
          });
      }
    }

    setArchivoResolucion (event): void {
      this.filesToServer = event;
      this.form.patchValue({
          image: event.nombreArchivo
      });
    }

    setArchivoFromServer (event): void {
      this.filesFromServer = event;
      this.form.patchValue({
          image: event.nombreArchivo
      });
    }

    goBack (): void {
      const ruta = this.publicidadId ? '../..' : '..';
      this.router.navigate([ruta], { relativeTo: this.route });
    }

    getOptionList (): void {
        if (this.selectedAreaPromoValue === 'superpromociones') {
            this.base.listAll('superpromocion/select/list').subscribe(optionList => {
                this.errors = new ErrorsHandler(optionList.code, optionList.message, 'get', 'listado de opciones');
                if (!this.errors.setError()) {
                    this.selectvalues = optionList.result;
                } else {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                }
            });
        } else {
            this.base.list(this.selectedAreaPromoValue, 'material/list').subscribe(optionList => {
                this.errors = new ErrorsHandler(optionList.code, optionList.message, 'get', 'listado de opciones');
                if (!this.errors.setError()) {
                    this.selectvalues = optionList.result;
                } else {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                }
            });
        }
    }

    addEntity () {
        this.extraValues.push({
            entity_value: ''
        });
    }

    deleteItem (index: number) {
        this.extraValues.splice(index, 1);
    }

    disbleOptions (value) {
        const elementExists = this.extraValues.filter(elem => elem.entity_value === value);
        return elementExists.length > 0;
    }

    private startEdition (): void {
        if (this.publicidadId) {
            this.base.list(this.publicidadId, 'publicidad/alumno').subscribe((publicidad) => {
                this.errors = new ErrorsHandler(publicidad.code, publicidad.message, 'get', 'publicidad');
                if (!this.errors.setError()) {
                    const publicidadData = publicidad.result;
                    const endDate = this.datepipe.transform(publicidadData.end_date.split(' ')[0], 'yyyy-MM-dd');
                    const endTime = publicidadData.end_date.split(' ')[1];

                    const startDate = this.datepipe.transform(publicidadData.start_date.split(' ')[0], 'yyyy-MM-dd');
                    const startTime = publicidadData.start_date.split(' ')[1];

                    if (publicidadData.image) {
                        this.filesFromServer = [publicidadData.image];
                    }
                    this.extraValues =  JSON.parse(publicidadData.option_value);
                    this.selectedAreaPromoValue = publicidadData.option_type;
                    this.getOptionList();

                    this.form.patchValue({
                        end_date: endDate,
                        end_time: endTime,
                        start_time: startTime,
                        image: publicidadData.image,
                        url: publicidadData.url,
                        start_date: startDate,
                        option_type: publicidadData.option_type,
                    });
                } else {
                    Swal.fire(this.errors.object.title, this.errors.object.text, this.errors.object.icon);
                }
            });
        }
    }

    private comoprobarFechas (): boolean {
        const startDate = new Date(this.form.value.start_date + ' ' + this.form.value.start_time);
        const endDate = new Date(this.form.value.end_date + ' ' + this.form.value.end_time);

        return startDate < endDate;
    }

    private checkExtraValues() {
      let isEmpty: boolean = false;

      this.extraValues.forEach(function (item) {
            if (!item.entity_value){
                isEmpty = true;
            }
        });

        return isEmpty;
    }
}
